<template>
  <div :class="classes">
    <date-picker
      v-model="modelValue"
      color="gray"
      :masks="masks"
      :attributes="attributes"
      :popover="popoverProps"
      :model-config="modelConfig"
      :disabled-dates="disabledDates"
      :min-date="minDate"
      :max-date="maxDate"
      @input="onInput"
      @popoverDidShow="handleOpen"
      @popoverDidHide="handleClose"
    >
      <template v-slot="{ inputValue, inputEvents, showPopover }">
        <svg
          :class="imageClasses"
          :data-cy="kebabCase(label + ' image')"
          @click="(e) => showCalendarFromIconClick && showPopover()"
          @keydown="(e) => showCalendarFromIconClick && showPopover()"
          v-html="require('@/assets/icons/calendar.svg')"
        />
        <input
          ref="input"
          autocomplete="off"
          type="text"
          :name="name"
          :value="inputValue"
          :data-cy="inputValue"
          :placeholder="placeholder"
          :label="label"
          :aria-label="label"
          :required="required"
          v-on="inputEvents"
          @blur="onBlur"
          @focus="onFocus"
        />
      </template>
    </date-picker>
  </div>
</template>

<script>
import { DatePicker } from 'v-calendar';
import moment from 'moment';
import kebabCase from '@/utils/kebabcase';

import 'v-calendar/dist/style.css';

export default {
  emits: [
    'focus',
    'input',
    'blur',
    'calendarOpened',
    'calendarClosed',
    'update:modelValue',
  ],
  components: { DatePicker },
  props: {
    name: { type: String, required: true },
    applyOldDesign: { type: Boolean, required: false, default: false },
    placeholder: String,
    label: { type: String, default: 'calendar' },
    required: Boolean,
    disabled: Boolean,
    modelValue: [String, Date],
    highlightToday: Boolean,
    isActive: Boolean,
    minDate: String,
    maxDate: String,
    disabledDates: Object,
    showCalendarFromIconClick: Boolean,
  },
  data() {
    return {
      date: new Date(),
      currentValue: null,
      modelConfig: {
        type: 'date',
        mask: 'YYYY-MM-DD',
      },
    };
  },
  computed: {
    attributes() {
      if (this.highlightToday) {
        return [
          {
            key: 'today',
            highlight: { color: 'gray', fillMode: 'light' },
            dates: new Date(),
          },
        ];
      }
      return [];
    },
    computedValue() {
      return this.inputValue ?? this.modelValue;
    },
    classes() {
      return {
        [this.$style.container]: true,
        [this.$style['design-input']]: this.applyOldDesign,
      };
    },
    imageClasses() {
      return {
        [this.$style.img]: true,
        [this.$style.active]: this.isActive,
        [this.$style.clickable]: this.showCalendarFromIconClick,
      };
    },
    masks() {
      return {
        input: 'YYYY-MM-DD',
      };
    },
    popoverProps() {
      const popoverProps = {};
      if (this.showCalendarFromIconClick) {
        popoverProps.visibility = 'focus';
      }
      return popoverProps;
    },
  },
  watch: {
    modelValue(newVal) {
      this.$emit('update:modelValue', newVal);
    },
  },
  methods: {
    onInput(e) {
      this.currentValue = e ? moment(e).format('YYYY-MM-DD') : '';
      this.$emit('input', { target: { value: this.currentValue } });
    },
    onFocus() {
      this.$emit('focus');
    },
    onBlur() {
      this.$emit('blur', {
        target: { value: this.computedValue, name: this.name },
      });
    },
    handleOpen() {
      this.$emit('calendarOpened');
    },
    handleClose() {
      this.$emit('calendarClosed', this.computedValue);
    },
    kebabCase,
  },
};
</script>
<style lang="less" module>
.container {
  width: 80%;
  &.design-input {
    width: 100%;
    .img {
      top: 12px;
      height: 20px;
    }
  }
  [class^='vc-pane-container'] {
    font-family: 'Montserrat';
  }
  [class^='vc-title'] {
    font-size: 16px;
    color: @cc-light-brown;
    font-weight: 400;
  }
  [class^='vc-weekday'] {
    font-size: 12px;
    font-weight: 400;
    color: @cc-light-brown;
  }
  [class^='vc-day-content'] {
    font-size: 16px;
    font-weight: 400 !important;
  }
  .img {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 8%;
    height: 50%;
    &.active {
      path {
        stroke: @color-black-new;
      }
    }
  }
  .clickable {
    cursor: pointer;
  }
}
</style>

// AMAZON
import i18n from '@/plugins/i18n';
import { SALES_PLATFORMS } from '@/data/platforms';
import { SETUP_STATE_STATUSES } from '@/data/fivetran';
import { CONNECTION_STATUS } from '@/data/connection-types';

const AmazonCountryMapping = {
  NA: {
    label: () => i18n.t('utils.local.northAmerica'),
    developerID: '8356-1075-2313',
    domain: 'com',
  },
  EU: {
    label: () => i18n.t('utils.local.europe'),
    developerID: '5178-4636-8324',
    domain: 'co.uk',
  },
  AU: {
    label: () => i18n.t('utils.local.australia'),
    developerID: '2094-6380-4066',
    domain: 'com.au',
  },
};

// may need to add more endpoints as we expand into new markets
// though i believe this is not likely necessary
const AmazonRegionMapping = {
  NA: 'https://sellercentral.amazon.com',
  EU: 'https://sellercentral-europe.amazon.com',
  AU: 'https://sellercentral.amazon.com.au',
};

const AmazonVendorRegionMapping = {
  NA: 'https://vendorcentral.amazon.com',
  EU: 'https://vendorcentral-europe.amazon.com',
  AU: 'https://vendorcentral.amazon.com.au',
};
/*
 * takes the region our customer is in (north america, europe, australia/asia)
 */
const getAmazonHostnameByMarketRegion = (market) => {
  const hostname = AmazonRegionMapping[market] || '';
  if (!hostname) {
    throw new Error('No hostname associated with Amazon market region', {
      market,
    });
  }

  return hostname;
};

/*
 * takes the region our customer is in (north america, europe, australia/asia)
 */
const getAmazonVendorHostnameByMarketRegion = (market) => {
  const hostname = AmazonVendorRegionMapping[market] || '';
  if (!hostname) {
    throw new Error('No hostname associated with Amazon market region', {
      market,
    });
  }

  return hostname;
};

const countryOptions = []; // for ui-dropdown
const countryLabels = {}; // for form-input dropdown
let key = 1;

for (const [countryCode, options] of Object.entries(AmazonCountryMapping)) {
  countryOptions.push({
    key: String(key),
    value: countryCode,
    name: options.label,
  });
  countryLabels[countryCode] = options.label;
  key++;
}

export const amazonCountryOptions = countryOptions;
export const amazonCountryFormOptions = countryLabels;

export function getAmazonCountryMapping(country) {
  return AmazonCountryMapping[country] || AmazonCountryMapping.NA;
}

export function isSalesAccountConnected(externalAccounts) {
  return externalAccounts.some(
    (ext) => ext.platformType === 'sales' && ext.state === 'connected',
  );
}

export function isMarketingAccountConnected(externalAccounts) {
  return externalAccounts.some(
    (ext) =>
      (ext.platformType === 'marketing' || ext.type === 'facebook') &&
      ext.state === 'connected',
  );
}

export function isAccountingAccountConnected(externalAccounts) {
  return externalAccounts.some(
    (ext) => ext.platformType === 'accounting' && ext.state === 'connected',
  );
}

// based on the amazon seller central region where our customer is registered,
// return the api hostname associated with that region
export function getAmazonSPAPIConsentUrl(market) {
  const hostname = getAmazonHostnameByMarketRegion(market);
  return `${hostname}/apps/authorize/consent?state=${market}`;
}

// based on the amazon vendor central region where our customer is registered,
// return the api hostname associated with that region
export function getAmazonSPAPIVendorConsentUrl(market) {
  const hostname = getAmazonVendorHostnameByMarketRegion(market);
  return `${hostname}/apps/authorize/consent?state=${market}&version=beta`;
}

export function getShopifyAccStatus(account) {
  if (
    account?.state === CONNECTION_STATUS.CONNECTED &&
    (account?.connectionDetails === null ||
      !Object.keys(account.connectionDetails ?? {}).length)
  ) {
    return CONNECTION_STATUS.WARNING;
  } else if (
    account.connectionDetails &&
    account.connectionDetails?.raw?.status?.setup_state ===
      SETUP_STATE_STATUSES.CONNECTED
  ) {
    return CONNECTION_STATUS.CONNECTED;
  } else if (
    account.connectionDetails &&
    account.connectionDetails?.raw?.status?.setup_state ===
      SETUP_STATE_STATUSES.INCOMPLETE
  ) {
    return CONNECTION_STATUS.PROCESSING;
  }

  return account && account?.state;
}

export function getExtAccountStatus(ext, showShopifyFivetranTile) {
  if (ext.platform === SALES_PLATFORMS.SHOPIFY && showShopifyFivetranTile) {
    return getShopifyAccStatus(ext);
  }
  return ext.state;
}

<template>
  <div class="oauth-signup">
    <oauth-connect
      ref="oauthConnect"
      :provider-config="oauthSignupProviderConfig"
    />
    <error-message :request-status="oauthConnectionRequest" />
    <error-message :message="error" />
  </div>
</template>

<script>
/*
SC-184519 DO NOT REMOVE - this is required to install the Shopify Native app and maintain data access

The flow is as follows
  - A User starts on the Shopify App page, and installs the Clearco app (see https://partners.shopify.com/483256/apps/1416066/overview)
  - The Clearco app redirects to `page-oauth-trigger` (`/oauth-trigger`) - authentication is not required
    - `page-oauth-trigger` redirects back to Shopify with the relevant Oauth config
  - User accepts the permissions and installs the app (if not already installed)
  - Shopify redirects back to Clearcom `page-oauth-signup` (`/oauth-signup`) with authorization code
    - `page-oauth-signup` stores the Oauth payload in local storage (`shopify-oauth-payload`)
    - `page-oauth-signup` requires the User to login/signup
  - User logs in / signs up on Auth0
    - User is redirected to the email verification page, verifies email
  - After email is verified, User is redirected back to `page-oauth-signup`
      - `POST /oauth/shopify` is called to connect the external account
      - User is redirected to the dashboard or user profile depending on profile completeness
*/

import { mapRequestStatuses } from '@/utils/vuex-api-utils';
import { OAUTH_PROVIDER_CONFIG } from '@/data/oauth-provider-config';
import { useMeta } from 'vue-meta';
import { LocalStorage, LocalStorageKeys } from '@/utils/local-storage';

const components = {
  'oauth-connect': require('@/components/OauthConnect').default,
};

export default {
  components,
  setup() {
    useMeta({ title: 'Oauth Connect | Clearco' });
  },
  data: () => ({
    error: null,
  }),
  computed: {
    oauthSignupProviderConfig: () => OAUTH_PROVIDER_CONFIG,
    ...mapRequestStatuses({ oauthConnectionRequest: 'OAUTH_CONNECT' }),
  },
  mounted() {
    // clear temporary localStorage items
    LocalStorage.removeItem(LocalStorageKeys.OAUTH_PROVIDER);
    LocalStorage.removeItem(LocalStorageKeys.OAUTH_REDIRECT_ROUTE);

    const oauthParams =
      LocalStorage.getItem(LocalStorageKeys.SHOPIFY_OAUTH_PAYLOAD) || {};

    if (oauthParams.error) {
      this.error = oauthParams.error;
    } else if (oauthParams.code) {
      this.oauthSuccess(oauthParams);
    }
  },
  methods: {
    async oauthSuccess(oauthParams) {
      await this.$store.dispatch('oauthConnect', oauthParams);
      LocalStorage.removeItem(LocalStorageKeys.SHOPIFY_OAUTH_PAYLOAD);

      if (this.oauthConnectionRequest.isError) return;

      this.$router.push({
        path: '/pay-vendors/business-profile/connected-accounts',
      });
    },
  },
};
</script>

<style lang="less">
.oauth-signup {
  padding: 10px;
  text-align: center;
}
</style>

<template>
  <Modal
    v-model:visible="visibility"
    modal
    header="Upload Document"
    data-cy="upload-modal"
    @hide="closeModal"
  >
    <div :class="$style['container']">
      <VeeForm
        ref="formUpload"
        class="c-form"
        :validation-schema="schema"
        @submit="submitForm"
      >
        <div class="c-group">
          <div class="c-form__row grid grid-cols-6">
            <DropdownGroup
              v-model="formFields.fileType"
              name="file-type"
              label="File Type"
              :options="allFileTypes"
              placeholder="File Type"
              option-label="label"
              option-value="value"
              data-cy="file-type-dropdown"
              @change="resetForm(['fileType'])"
            />
          </div>
          <template v-if="formFields.fileType">
            <!-- Bank Statements File Type -->
            <template
              v-if="
                formFields.fileType === BANK_ACCOUNT_DOC_TYPE.BANK_STATEMENTS
              "
            >
              <div
                v-if="businessOnPlaidBypass"
                class="c-form__row grid grid-cols-6"
              >
                <p :class="$style['modal-message']">
                  To upload bank statements head over to
                  <a @click="bankStatementsLink"
                    >the bank statements page here</a
                  >.
                </p>
              </div>
              <template v-else>
                <div class="c-form__row grid grid-cols-6">
                  <UploadSingleFile
                    ref="uploadSingleFileBankStatements"
                    :upload-filters="uploadFilters"
                    :file="fileUploaded"
                    :auto-upload="false"
                    @onUpload="updateFileUploaded"
                    @onRemove="removeFileUploaded"
                  />
                  <p
                    v-if="errorUploadingFile"
                    :class="[
                      $style['modal-message'],
                      $style['modal-message--error'],
                    ]"
                  >
                    {{ errorUploadingFile }}
                  </p>
                </div>
                <div class="c-form__row grid grid-cols-6">
                  <DropdownGroup
                    v-model="formFields.bankAccount"
                    label="Bank Account Number"
                    name="bank-account-number"
                    option-label="accountNumber"
                    option-value="id"
                    :options="bankAccounts"
                    placeholder="Select a Bank Account"
                    data-cy="bank-account-dropdown"
                  />
                </div>
                <div class="c-form__row grid grid-cols-6">
                  <CalendarGroup
                    v-model="formFields.startDate"
                    label="Start Date"
                    name="start-date"
                    :manual-input="false"
                    placeholder="yyyy-mm-dd"
                    show-icon
                  />
                </div>
                <div class="c-form__row grid grid-cols-6">
                  <CalendarGroup
                    v-model="formFields.endDate"
                    label="End Date"
                    name="end-date"
                    :manual-input="false"
                    placeholder="yyyy-mm-dd"
                    show-icon
                  />
                </div>
              </template>
            </template>

            <!-- Void Cheque File Type -->
            <template
              v-else-if="
                formFields.fileType === BANK_ACCOUNT_DOC_TYPE.VOID_CHEQUE ||
                formFields.fileType ===
                  BANK_ACCOUNT_DOC_TYPE.DEBIT_AUTHORIZATION
              "
            >
              <div class="c-form__row grid grid-cols-6">
                <UploadSingleFile
                  ref="uploadSingleFileVoidCheque"
                  :upload-filters="uploadFilters"
                  :file="fileUploaded"
                  :auto-upload="false"
                  @onUpload="updateFileUploaded"
                  @onRemove="removeFileUploaded"
                />
                <p
                  v-if="errorUploadingFile"
                  :class="[
                    $style['modal-message'],
                    $style['modal-message--error'],
                  ]"
                >
                  {{ errorUploadingFile }}
                </p>
              </div>
              <div class="c-form__row grid grid-cols-6">
                <DropdownGroup
                  v-model="formFields.bankAccount"
                  label="Bank Account Number"
                  name="bank-account-number"
                  option-label="accountNumber"
                  option-value="id"
                  :options="bankAccounts"
                  placeholder="Select a Bank Account"
                  data-cy="bank-account-dropdown"
                />
              </div>
            </template>

            <!-- Basic File Type -->
            <template v-else>
              <div class="c-form__row grid grid-cols-6">
                <UploadSingleFile
                  ref="uploadSingleFileBasic"
                  :upload-filters="uploadFilters"
                  :file="fileUploaded"
                  :auto-upload="false"
                  @onUpload="updateFileUploaded"
                  @onRemove="removeFileUploaded"
                />
                <p
                  v-if="errorUploadingFile"
                  :class="[
                    $style['modal-message'],
                    $style['modal-message--error'],
                  ]"
                >
                  {{ errorUploadingFile }}
                </p>
              </div>
            </template>
          </template>
        </div>
        <div class="c-form">
          <div class="c-form__controls justify-center">
            <DSButton
              class="p-button-outlined"
              :label="$t('common.buttons.cancel')"
              @click="$emit('close-modal')"
            />
            <DSButton
              data-cy="submit-bank-details"
              :loading="isSubmitting"
              :disabled="!fileUploaded"
              :label="$t('common.buttons.submit')"
              type="submit"
            />
          </div>
        </div>
      </VeeForm>
    </div>
  </Modal>
</template>

<script>
import { mapGetters } from 'vuex';
import { mapRequestStatuses } from '@/utils/vuex-api-utils';
import { object, string } from 'yup';
import { BANK_ACCOUNT_DOC_TYPE } from '@clearbanc/data-common-types';
import {
  DOCUMENT_CATEGORIES,
  FILE_TYPES_IN_CATEGORIES,
  FILE_TYPES_IN_ENTITIES,
} from '@/data/file-upload-types';
import { PAYMENTS_ROUTE_NAMES } from '@/data/payments';
import Modal from '@clearbanc/clear-components/dialog';
import DSButton from '@clearbanc/clear-components/button';
import { Form as VeeForm } from 'vee-validate';
import { UploadSingleFile } from '@/components';
import DropdownGroup from '@clearbanc/clear-components/dropdowngroup';
import CalendarGroup from '@clearbanc/clear-components/calendargroup';
import { SEGMENT_EVENTS } from '@/data/segment-events';
import analytics from '@/utils/analytics';

export default {
  name: 'UploadModal',
  components: {
    Modal,
    UploadSingleFile,
    VeeForm,
    DSButton,
    DropdownGroup,
    CalendarGroup,
  },
  data() {
    return {
      fileUploaded: null,
      showError: false,
      isSubmitting: false,
      selectedBankAccount: null,
      errorUploadingFile: null,
      BANK_ACCOUNT_DOC_TYPE,
      initalFormFields: {
        fileType: null,
        bankAccount: null,
        startDate: null,
        endDate: null,
      },
      formFields: {
        ...this.initalFormFields,
      },
    };
  },
  props: {
    visibility: {
      type: Boolean,
      required: true,
    },
    openOnFileType: {
      type: String,
    },
  },
  computed: {
    ...mapGetters([
      'currentBankAccountToBePrimary',
      'primaryBankAccount',
      'businessOnPlaidBypass',
      'bankAccounts',
    ]),
    ...mapRequestStatuses({
      addUploadRequest: 'ADD_USER_UPLOAD',
    }),
    allFileTypes() {
      const { CONTRACTS, BANK } = DOCUMENT_CATEGORIES;

      const filteredFileTypes = {
        ...JSON.parse(JSON.stringify(FILE_TYPES_IN_CATEGORIES)),
      };
      delete filteredFileTypes[CONTRACTS];

      if (!this.businessOnPlaidBypass) {
        const bankFileTypes = filteredFileTypes[BANK];
        const bankStatementsIndex = bankFileTypes.indexOf(
          BANK_ACCOUNT_DOC_TYPE.BANK_STATEMENTS,
        );
        if (bankStatementsIndex !== -1) {
          bankFileTypes.splice(bankStatementsIndex, 1);
        }
      }

      const allTypes = Object.values(filteredFileTypes).flat();
      const uniqueTypes = [...new Set(allTypes)];

      return uniqueTypes.map((value) => ({
        value,
        label: this.formatType(value),
      }));
    },
    selectedBankStatements() {
      return this.formFields.fileType === BANK_ACCOUNT_DOC_TYPE.BANK_STATEMENTS;
    },
    uploadFilters() {
      if (
        this.formFields.fileType === BANK_ACCOUNT_DOC_TYPE.VOID_CHEQUE ||
        this.formFields.fileType === BANK_ACCOUNT_DOC_TYPE.DEBIT_AUTHORIZATION
      ) {
        return {
          entity: this.getEntityBasedOnType(this.formFields.fileType),
          type: this.formFields.fileType,
          metaId: this.formFields.bankAccount,
        };
      }

      if (this.formFields.fileType === BANK_ACCOUNT_DOC_TYPE.BANK_STATEMENTS) {
        return {
          entity: this.getEntityBasedOnType(this.formFields.fileType),
          type: this.formFields.fileType,
          metaId: this.formFields.bankAccount,
          startDate: this.formFields.startDate,
          endDate: this.formFields.endDate,
        };
      }

      return {
        entity: this.getEntityBasedOnType(this.formFields.fileType),
        type: this.formFields.fileType,
      };
    },
    schema() {
      if (
        this.formFields.fileType === BANK_ACCOUNT_DOC_TYPE.VOID_CHEQUE ||
        this.formFields.fileType === BANK_ACCOUNT_DOC_TYPE.DEBIT_AUTHORIZATION
      ) {
        return object({
          'file-type': string().required('Option is required').nullable(),
          'bank-account-number': string()
            .required('Option is required')
            .nullable(),
        });
      }

      if (this.formFields.fileType === BANK_ACCOUNT_DOC_TYPE.BANK_STATEMENTS) {
        return object({
          'file-type': string().required('Option is required').nullable(),
          'bank-account-number': string()
            .required('Option is required')
            .nullable(),
          'start-date': string().required('Option is required').nullable(),
          'end-date': string().required('Option is required').nullable(),
        });
      }

      return object({
        'file-type': string().required('Option is required').nullable(),
      });
    },
  },
  methods: {
    updateFileUploaded(file) {
      this.fileUploaded = file;

      analytics.track(SEGMENT_EVENTS.if_file_uploaded, {
        fileType: this.formFields.fileType,
        fileName: file.name,
        fileSize: file.size,
      });
    },
    removeFileUploaded() {
      this.fileUploaded = null;
    },
    getRefToUploadSingleFileComponent() {
      if (this.formFields.fileType === BANK_ACCOUNT_DOC_TYPE.BANK_STATEMENTS) {
        return this.$refs.uploadSingleFileBankStatements;
      } else if (
        this.formFields.fileType === BANK_ACCOUNT_DOC_TYPE.VOID_CHEQUE ||
        this.formFields.fileType === BANK_ACCOUNT_DOC_TYPE.DEBIT_AUTHORIZATION
      ) {
        return this.$refs.uploadSingleFileVoidCheque;
      }
      return this.$refs.uploadSingleFileBasic;
    },
    async submitForm(values) {
      this.isSubmitting = true;

      if (this.fileUploaded) {
        await this.getRefToUploadSingleFileComponent().processFiles();

        if (this.addUploadRequest.isError) {
          this.errorUploadingFile =
            'Error uploading file, please try again or contact support.';
          return;
        }

        analytics.track(SEGMENT_EVENTS.if_file_submitted_succesfully, {
          fileType: this.formFields.fileType,
          fileName: this.fileUploaded.name,
          fileSize: this.fileUploaded.size,
        });
      }

      this.fileUploaded = null;
      this.isSubmitting = false;
      this.resetForm();
      this.$emit('close-modal');
    },
    formatType(type) {
      return type && type.replace(/_/g, ' ').toLowerCase();
    },
    bankStatementsLink() {
      const bankAccountId =
        this.primaryBankAccount?.id ?? this.currentBankAccountToBePrimary?.id;

      this.$router.push({
        name: PAYMENTS_ROUTE_NAMES.PROFILE_BANK_STATEMENTS,
        params: { id: bankAccountId },
        query: { navigatedFrom: PAYMENTS_ROUTE_NAMES.ALL_DOCUMENTS },
      });
    },
    getEntityBasedOnType(fileType) {
      for (const entity in FILE_TYPES_IN_ENTITIES) {
        if (FILE_TYPES_IN_ENTITIES[entity].includes(fileType)) {
          return entity;
        }
      }

      return null;
    },
    getUniqueEntries(data, key) {
      return [...new Set(data.map((entry) => entry[key]))];
    },
    closeModal() {
      this.resetForm();
      this.fileUploaded = null;
      this.errorUploadingFile = null;
      this.$emit('close-modal');
    },
    resetForm(fieldsToKeep = []) {
      const resetFields = {};

      if (!fieldsToKeep.length) {
        return (this.formFields = { ...this.initalFormFields });
      }

      for (const field in this.initalFormFields) {
        if (!fieldsToKeep.includes(field)) {
          resetFields[field] = this.initalFormFields[field];
        }
      }

      return (this.formFields = { ...this.formFields, ...resetFields });
    },
  },
  watch: {
    visibility(newVal) {
      if (newVal && this.openOnFileType) {
        this.$nextTick(() => {
          this.formFields.fileType = this.openOnFileType;
          this.$refs.formUpload.setFieldValue('file-type', this.openOnFileType);
        });
      }
    },
  },
};
</script>

<style>
.p-dropdown-item,
.p-dropdown-label {
  text-transform: capitalize;
}

.c-form .file-upload-widget {
  margin-bottom: 0;
}
</style>

<style lang="less" module>
.container {
  margin: 20px auto 0;
}

.form {
  margin-top: 2rem;
  &:first-child {
    margin-top: 0;
  }
}

.modal-message {
  max-width: 280px;
  margin: 0;

  a {
    text-decoration: underline;
    color: #7f30ff;
    cursor: pointer;
  }
}

.modal-message--error {
  color: #df0000;
}
</style>

<template>
  <Message severity="info" icon="pi-shield" :closable="false">
    <p>
      {{ $t('components.encryptionMessage.customersTrustOur') }}
      <a
        :href="addLocaleParam(clearbancURL + '/faq')"
        target="_blank"
        @click="trackLinkClick('fe_encryption_clicked')"
        >{{ $t('components.encryptionMessage.encryption') }}</a
      >.
    </p>
  </Message>
</template>

<script>
import Message from '@clearbanc/clear-components/message';
import addLocaleParam from '@/utils/add-locale-param';
import analytics from '@/utils/analytics';
import { ENV } from '../../config/app-env';

export default {
  components: {
    Message,
  },
  computed: {
    clearbancURL() {
      return ENV.CLEARCOM_URL;
    },
  },
  methods: {
    addLocaleParam,
    trackLinkClick(event) {
      analytics.track(event);
    },
  },
};
</script>

<style lang="less" scoped>
.p-message {
  display: inline-block;
}
</style>

import { authGuard } from '@auth0/auth0-vue';
import { OAUTH } from '@/data/routes';
import OauthSignupPage from '@/pages/page-oauth-signup';
import OauthTriggerPage from '@/pages/page-oauth-trigger';
import OauthCallbackPage from '@/pages/page-oauth-callback';
import PlaidOauthRedirect from '@/pages/oauth-plaid-redirect';
import { LocalStorage, LocalStorageKeys } from '@/utils/local-storage';

export default [
  {
    path: '/oauth-trigger',
    name: OAUTH.TRIGGER,
    component: OauthTriggerPage, // Redirected here from Shopify App store when installing our Shopify Native app
  },
  {
    path: '/oauth-signup',
    name: OAUTH.SIGNUP,
    component: OauthSignupPage, // Redirected here with authorization code from Shopify when installing our Shopify Native app
    beforeEnter: async (to, _) => {
      const query = to.query;

      const oauthParams = LocalStorage.getItem(
        LocalStorageKeys.SHOPIFY_OAUTH_PAYLOAD,
      );

      if (!oauthParams) {
        LocalStorage.setItem(LocalStorageKeys.SHOPIFY_OAUTH_PAYLOAD, query);
      }

      return authGuard(to);
    },
  },
  // oauth redirects to this page > calls parent window js + closes
  // Used to connect external accounts
  {
    path: '/oauth-callback',
    name: OAUTH.CALLBACK,
    component: OauthCallbackPage,
    beforeEnter: authGuard,
  },
  {
    path: '/plaid-oauth-callback',
    name: OAUTH.PLAID_CALLBACK,
    component: PlaidOauthRedirect,
  },
];

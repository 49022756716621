<template>
  <div full-width no-footer>
    <div>
      <div :class="$style.menu">
        <div :class="[$style.title, $style.large, $style.white]">
          {{ $t('paymentsPage.repaymentTransactions') }}
        </div>
        <div :class="$style.line"></div>
        <div :class="$style['dropdown-container']">
          <custom-dropdown
            :label="$t('paymentsPage.advanceType')"
            :model-value="filters.advanceType"
            :options="advanceTypeFilterOptions"
            :class="$style.dropdown"
            required
            @input="filterByAdvanceType"
          />
          <custom-dropdown
            :label="$t('paymentsPage.advance')"
            :model-value="filters.advanceId"
            :options="advancesFilterOptions"
            :class="$style.dropdown"
            required
            @input="(val) => (filters.advanceId = val)"
          />
          <custom-dropdown
            :label="$t('paymentsPage.type')"
            :model-value="filters.type"
            :options="typeFilterOptions"
            :class="$style.dropdown"
            required
            @input="(val) => (filters.type = val)"
          />
          <custom-dropdown
            :label="$t('paymentsPage.status')"
            :model-value="filters.status"
            :options="statusFilterOptions"
            :class="$style.dropdown"
            required
            @input="(val) => (filters.status = val)"
          />
          <custom-dropdown
            :label="$t('paymentsPage.monthYear')"
            :model-value="filters.createdAt"
            :options="dateFilterOptions"
            :class="$style.dropdown"
            required
            @input="(val) => (filters.createdAt = val)"
          />
        </div>
      </div>
      <div :class="$style.container">
        <div :class="$style.title">
          {{ $t('paymentsPage.listOfTransactions') }}
        </div>
        <design-button
          :text="$t('paymentsPage.downloadXlsx')"
          :class="$style.button"
          @click="openTransactionReportPopup"
        >
          <template v-slot:tooltip>
            {{ $t('paymentsPage.containsBalance') }}<br />
            {{ $t('paymentsPage.cashbackInfo') }}
          </template>
        </design-button>

        <custom-table
          :data="paymentReport"
          :empty-msg="$t('paymentsPage.noTransactionsAvailable')"
          :loading="fetchPaymentReport.isPending"
        >
          <template #row="slotProps">
            <custom-column
              :row="slotProps?.row"
              show="date"
              :label="() => $t('paymentsPage.date')"
              type="date"
              align="left"
            />
            <custom-column
              :row="slotProps?.row"
              :label="() => $t('paymentsPage.advanceType')"
              show="advance_type"
              capitalize
            />
            <custom-column
              :row="slotProps?.row"
              :label="() => $t('paymentsPage.advanceId')"
            >
              #{{ slotProps?.row?.advance_id }}
            </custom-column>
            <custom-column
              :row="slotProps?.row"
              show="type"
              :label="() => $t('paymentsPage.type')"
            />
            <custom-column
              :row="slotProps?.row"
              :label="() => $t('paymentsPage.description')"
            >
              <a
                v-if="slotProps?.row?.child_invoice_id"
                href="#"
                @click.prevent="showDetailsPopup(slotProps?.row.transaction_id)"
                >{{ slotProps?.row.description }}</a
              >
              <span v-else>{{ slotProps?.row.description }}</span>
            </custom-column>
            <custom-column
              :row="slotProps?.row"
              show="status"
              :label="() => $t('paymentsPage.status')"
              uppercase
            />
            <custom-column
              :row="slotProps?.row"
              :label="() => $t('paymentsPage.amount')"
            >
              {{
                $filters.currency(
                  slotProps?.row.amount,
                  slotProps?.row.currency_code,
                )
              }}
            </custom-column>
            <custom-column
              :row="slotProps?.row"
              :label="() => $t('paymentsPage.amountOutstanding')"
            >
              {{
                $filters.currency(
                  slotProps?.row.amount_outstanding,
                  slotProps?.row.currency_code,
                )
              }}
            </custom-column>
          </template>
        </custom-table>
      </div>

      <pagination
        :items="paymentReport"
        :total-count="paymentReportCount"
        :filters="filters"
        table-key="paymentReport"
        :with-display="false"
        class="m-b-50"
        @refreshData="refreshData"
      />

      <ui-modal
        ref="requestTransactionReportPopup"
        :title="$t('paymentsPage.downloadTransaction')"
        align="left"
      >
        <div :class="$style['modal-content']">
          <div v-if="requestPaymentReportRequest.isSuccess">
            <div>
              {{ $t('paymentsPage.requestReceived') }}
            </div>
            <design-button
              :text="$t('paymentsPage.ok')"
              class="m-t-70"
              @click="$refs.requestTransactionReportPopup.close()"
            />
          </div>
          <div v-else-if="requestPaymentReportRequest.isPending">
            {{ $t('paymentsPage.generatingReport') }}
          </div>
          <div v-else-if="requestPaymentReportRequest.isError">
            <error-message :request-status="requestPaymentReportRequest" />
            <div>
              {{ $t('paymentsPage.processingRequestError') }}
            </div>
            <design-button
              :text="$t('paymentsPage.ok')"
              class="m-t-20"
              @click="$refs.requestTransactionReportPopup.close()"
            />
          </div>
          <div v-else>
            {{ $t('paymentsPage.whichEmail') }}
            <ui-radio
              theme="dark-green"
              name="primaryEmail"
              :label="$t('paymentsPage.primaryEmail', { email: user.email })"
              :value="user.email"
              :selected="
                reportsPreferredEmail === user.email && !freeformSelected
              "
              :class="$style.radio"
              required
              @input="onInput"
            >
              {{ user.email }}
            </ui-radio>
            <ui-radio
              v-if="business.billingEmail"
              theme="dark-green"
              name="billingEmail"
              :label="
                $t('paymentsPage.billingEmail', {
                  billingEmail: business.billingEmail,
                })
              "
              :value="business.billingEmail"
              :selected="
                reportsPreferredEmail === business.billingEmail &&
                !freeformSelected
              "
              :class="$style.radio"
              required
              @input="onInput"
            />
            <ui-radio
              v-else
              :label="$t('paymentsPage.billingEmailLabel')"
              :class="$style.radio"
              disabled
            >
              {{ $t('paymentsPage.pleaseContactBillingEmail') }}
            </ui-radio>
            <ui-radio
              theme="dark-green"
              name="freeformEmail"
              :selected="freeformSelected"
              :label="$t('paymentsPage.otherEmail')"
              :class="$style.radio"
              @input="selectFreeformEmail"
            />
            <ui-input
              v-model="freeformEmail"
              type="email"
              placeholder="email@example.com"
              :class="$style['email-input']"
              :disabled="!freeformSelected"
              @input="inputFreeformEmail"
            />
            <design-button
              :text="$t('paymentsPage.submit')"
              class="m-t-20"
              @click="requestTransactionReport"
            />
          </div>
        </div>
      </ui-modal>

      <ui-modal
        ref="viewDetailsPopup"
        :title="$t('paymentsPage.invoiceDetails')"
        largest
        align="left"
      >
        <div :class="$style['modal-content']">
          <div :class="$style.subtitle">
            {{
              $t('paymentsPage.invoiceId', {
                invoiceID: selectedTransactionInvoice.id,
              })
            }}
          </div>
          <div
            v-if="selectedTransactionInvoice.invoiceTooLarge"
            :class="[$style.subtitle, $style.small]"
          >
            {{ $t('paymentsPage.tooManyTransactions') }}
          </div>
          <div
            v-else-if="isInvoiceDateRangeExtended"
            :class="[$style.subtitle, $style.small]"
          >
            {{
              $t('paymentsPage.includesTransactionsFrom', {
                startDate: selectedInvoiceDateRange.startDate,
                endDate: selectedInvoiceDateRange.endDate,
              })
            }}
          </div>

          <custom-table
            v-if="selectedTransactionInvoice.details"
            :data="selectedTransactionInvoice.details.lineItems"
            :empty-msg="emptyTableMessage"
          >
            <template #row="slotProps">
              <custom-column
                :row="slotProps?.row"
                show="at"
                :label="() => $t('paymentsPage.date')"
                type="date"
                align="left"
              />
              <custom-column
                :row="slotProps?.row"
                show="label"
                :label="() => $t('paymentsPage.description')"
              />
              <custom-column
                :row="slotProps?.row"
                :label="() => $t('paymentsPage.amount')"
              >
                {{
                  $filters.currency(
                    slotProps?.row.amountCents,
                    slotProps?.row.convertTo || slotProps?.row.currencyCode,
                  )
                }}
              </custom-column>
              <custom-column
                :row="slotProps?.row"
                :label="() => $t('paymentsPage.originalCurrency')"
              >
                {{ slotProps?.row.convertFrom || slotProps?.row.currencyCode }}
              </custom-column>
            </template>
          </custom-table>
        </div>

        <template v-slot:footer>
          <div v-if="!!selectedTransactionInvoice.details" class="p-30">
            <div :class="$style.info">
              <div :class="$style.label">
                {{ $t('paymentsPage.totalRevenue') }}
              </div>
              {{
                $filters.currency(
                  selectedTransactionInvoice.details.revenueSubtotalCents,
                  selectedTransactionInvoice.currencyCode,
                )
              }}
            </div>
            <div :class="$style.info">
              <div :class="$style.label">
                {{ $t('paymentsPage.remittanceRate') }}
              </div>
              {{
                $filters.percent(
                  selectedTransactionInvoice.details.repaymentRate,
                )
              }}
            </div>
            <div :class="$style.info">
              <div :class="$style.label">
                {{ $t('paymentsPage.invoiceCurrency') }}
              </div>
              {{ selectedTransactionInvoice.currencyCode }}
            </div>
            <div :class="$style.info">
              <div :class="$style.label">
                {{ $t('paymentsPage.invoiceAmount') }}
              </div>
              {{
                $filters.currency(
                  selectedTransactionInvoice.amountCents,
                  selectedTransactionInvoice.currencyCode,
                )
              }}
            </div>
            <div
              v-if="
                selectedTransactionInvoice.amountCents !==
                selectedTransaction.amountCents
              "
              :class="$style.info"
            >
              <div :class="$style.label">
                {{ $t('paymentsPage.debitAmount') }}
              </div>
              {{
                $filters.currency(
                  selectedTransaction.amountCents,
                  selectedTransactionInvoice.currencyCode,
                )
              }}
            </div>
            <design-button
              :text="$t('paymentsPage.downloadCsv')"
              :class="$style['modal-footer-button']"
              @click="openInvoiceReportRequestPopup"
            />
          </div>
        </template>
      </ui-modal>

      <ui-modal
        ref="requestInvoiceReportPopup"
        :title="$t('paymentsPage.downloadInvoiceReport')"
        align="left"
      >
        <div :class="$style['modal-content']">
          <div v-if="requestInvoiceReportRequest.isSuccess">
            <div>
              {{ $t('paymentsPage.requestReceived') }}
            </div>
            <design-button
              :text="$t('paymentsPage.ok')"
              class="m-t-70"
              @click="$refs.requestInvoiceReportPopup.close()"
            />
          </div>
          <div v-else-if="requestInvoiceReportRequest.isPending">
            {{ $t('paymentsPage.generatingReport') }}
          </div>
          <div v-else-if="requestInvoiceReportRequest.isError">
            <error-message :request-status="requestInvoiceReportRequest" />
            <div>
              {{ $t('paymentsPage.processingRequestError') }}
            </div>
            <design-button
              :text="$t('paymentsPage.ok')"
              class="m-t-20"
              @click="$refs.requestInvoiceReportPopup.close()"
            />
          </div>
          <div v-else>
            {{ $t('paymentsPage.whichEmail') }}
            <ui-radio
              theme="dark-green"
              name="primaryEmail"
              :label="$t('paymentsPage.primaryEmail', { email: user.email })"
              :value="user.email"
              :selected="
                reportsPreferredEmail === user.email && !freeformSelected
              "
              :class="$style.radio"
              required
              @input="onInput"
            >
              {{ user.email }}
            </ui-radio>
            <ui-radio
              v-if="business.billingEmail"
              theme="dark-green"
              name="billingEmail"
              :label="
                $t('paymentsPage.billingEmail', {
                  billingEmail: business.billingEmail,
                })
              "
              :value="business.billingEmail"
              :selected="
                reportsPreferredEmail === business.billingEmail &&
                !freeformSelected
              "
              :class="$style.radio"
              required
              @input="onInput"
            >
              {{ business.billingEmail }}
            </ui-radio>
            <ui-radio
              v-else
              :label="$t('paymentsPage.billingEmailLabel')"
              :class="$style.radio"
              disabled
            >
              {{ $t('paymentsPage.pleaseContactBillingEmail') }}
            </ui-radio>
            <ui-radio
              theme="dark-green"
              name="freeformEmail"
              :selected="freeformSelected"
              :label="$t('paymentsPage.otherEmail')"
              :class="$style.radio"
              @input="selectFreeformEmail"
            />
            <ui-input
              v-model="freeformEmail"
              type="email"
              placeholder="email@example.com"
              :class="$style['email-input']"
              :disabled="!freeformSelected"
              @input="inputFreeformEmail"
            />
            <design-button
              :text="$t('paymentsPage.submit')"
              class="m-t-20"
              @click="requestInvoiceTransactionReport"
            />
          </div>
        </div>
      </ui-modal>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { UiInput, UiModal, UiRadio } from 'ui-components';
import { useMeta } from 'vue-meta';
import {
  format,
  subMonths,
  differenceInMonths,
  addMonths,
  endOfMonth,
} from 'date-fns';
import { formatDate } from '@/utils/dates';
import { mapRequestStatuses } from '@/utils/vuex-api-utils';
import trackFiltersMixin from '@/mixins/track-filters-in-query-params';
import { formatMoney } from '@/utils/currency';
import { ADVANCE_SUBTYPES, ADVANCE_SUPRATYPES } from '@/data/advance-subtypes';
import { DesignButton } from '@/components/mca';
import Pagination from '@/components';
import CustomDropdown from '@/components/CustomDropdownPayments';
import CustomTable from '@/components/CustomTablePayments';
import CustomColumn from '@/components/CustomColumnPayments';
import addLocaleParam from '@/utils/add-locale-param';
import { ENV } from '../../../../config/app-env';

export default {
  components: {
    UiInput,
    UiRadio,
    UiModal,
    DesignButton,
    CustomDropdown,
    CustomTable,
    CustomColumn,
    Pagination,
  },
  setup() {
    useMeta({
      title: 'My Payments | Clearco',
    });
  },
  mixins: [trackFiltersMixin],
  trackFilters: {
    // passed to a mixin which sets `filters` in data
    keys: [
      'status',
      'advanceId',
      'advanceType',
      'type',
      'page',
      'limit',
      'createdAt',
    ],
  },
  data() {
    return {
      reportsPreferredEmail: '',
      areFiltersVisible: true,
      freeformEmail: '',
      freeformSelected: false,
    };
  },
  computed: {
    ...mapGetters([
      'advances',
      'amountOutstanding',
      'paymentReport',
      'paymentReportCount',
      'business',
      'selectedTransaction',
      'selectedTransactionInvoice',
      'user',
      'supratypeById',
      'idsBySupratype',
    ]),
    ...mapRequestStatuses({
      fetchPaymentReport: 'FETCH_PAYMENT_REPORT',
      getAmountOutstanding: 'GET_AMOUNT_OUTSTANDING_BY_ADVANCE_TYPE',
      requestPaymentReportRequest: 'REQUEST_PAYMENT_REPORT',
      requestInvoiceReportRequest: 'REQUEST_INVOICE_EXPORT',
    }),
    advancesFilterOptions() {
      return this.advances
        .filter((adv) => {
          if (!this.filters.advanceType) {
            return adv.activatedAt || adv.settledAt;
          } else if (this.filters.advanceType === ADVANCE_SUBTYPES.INVENTORY) {
            return (
              (adv.activatedAt || adv.settledAt) &&
              adv.subtype === ADVANCE_SUBTYPES.INVENTORY
            );
          }
          return (
            (adv.activatedAt || adv.settledAt) &&
            adv.subtype !== ADVANCE_SUBTYPES.INVENTORY
          );
        })
        .map((a) => ({
          key: a.id,
          name: `${this.$t('paymentsPage.advanceWithId', {
            advanceId: a.id,
          })} ${formatMoney(a.amountCents, a.currencyCode)}`,
          value: a.id,
        }));
    },
    advanceTypeFilterOptions() {
      const filterOptions = Array.from(
        new Set(
          // only inventory is 'inventory', but unified, marketing, and marketing_facebook are all 'marketing'
          this.advances.map((adv) => ADVANCE_SUPRATYPES[adv.subtype]),
        ),
      );
      const optionsMap = {
        all: {
          key: 1,
          name: `${this.$t(
            'paymentsPage.all',
          )} - ${this.formatAmountOutstanding(
            this.amountOutstanding.marketing + this.amountOutstanding.inventory,
          )}`,
          value: null,
        },
        marketing: {
          key: 2,
          name: `${this.$t(
            'paymentsPage.marketing',
          )} - ${this.formatAmountOutstanding(
            this.amountOutstanding.marketing,
          )}`,
          value: 'marketing',
        },
        inventory: {
          key: 3,
          name: `${this.$t(
            'paymentsPage.inventory',
          )} - ${this.formatAmountOutstanding(
            this.amountOutstanding.inventory,
          )}`,
          value: 'inventory',
        },
      };

      // If this user has one type of advance, only show that option. Otherwise, show both options, as well as "All"
      const advanceTypes =
        filterOptions.length > 1 || !this.advances.length
          ? Object.values(optionsMap)
          : [optionsMap[filterOptions[0]]];
      return advanceTypes;
    },
    typeFilterOptions() {
      return [
        { key: 1, name: this.$t('paymentsPage.all'), value: null },
        { key: 2, name: this.$t('paymentsPage.payment'), value: 'payment' },
        { key: 3, name: this.$t('paymentsPage.credit'), value: 'credit' },
        { key: 4, name: this.$t('paymentsPage.refund'), value: 'refund' },
        {
          key: 5,
          name: this.$t('paymentsPage.balanceRollover'),
          value: 'topup',
        },
        { key: 6, name: this.$t('paymentsPage.advance'), value: 'advance' },
        {
          key: 7,
          name: this.$t('paymentsPage.discountCredit'),
          value: 'discount_credit',
        },
      ];
    },
    statusFilterOptions() {
      return [
        { key: 1, name: this.$t('paymentsPage.all'), value: null },
        { key: 2, name: this.$t('paymentsPage.pending'), value: 'pending' },
        {
          key: 3,
          name: this.$t('paymentsPage.inTransit'),
          value: 'in_transit',
        },
        { key: 4, name: this.$t('paymentsPage.failed'), value: 'failed' },
        { key: 5, name: this.$t('paymentsPage.success'), value: 'success' },
        { key: 6, name: this.$t('paymentsPage.cancelled'), value: 'canceled' },
        { key: 7, name: this.$t('paymentsPage.refund'), value: 'refund' },
      ];
    },
    dateFilterOptions() {
      const firstAdvanceCreatedAt =
        this.advances[this.advances.length - 1]?.createdAt;
      const firstAdvanceDate = firstAdvanceCreatedAt
        ? new Date(firstAdvanceCreatedAt)
        : new Date();
      const datesArray = [
        ...Array(differenceInMonths(new Date(), firstAdvanceDate)),
      ].reduceRight((acc, val, idx) => {
        const date = addMonths(firstAdvanceDate, idx + 1);

        return acc.concat({
          key: idx + 1,
          name: format(date, 'MMMM yyyy'),
          value: format(endOfMonth(date), 'yyyy-MM-dd'),
        });
      }, []);

      return [
        { key: 0, name: this.$t('paymentsPage.allDates'), value: null },
        ...datesArray,
      ];
    },
    selectedInvoiceDateRange() {
      const invoice = this.selectedTransactionInvoice;

      if (!invoice || !invoice.lineItems || invoice.lineItems.length < 1)
        return {};

      const datesBucket = [];
      for (let i = 0; i < invoice.lineItems.length; i++) {
        const date = formatDate(new Date(invoice.lineItems[i].at));
        if (!datesBucket.includes(date)) {
          datesBucket.push(date);
        }
      }
      datesBucket.sort();

      return {
        startDate: datesBucket[0],
        endDate: datesBucket[datesBucket.length - 1],
      };
    },
    isInvoiceDateRangeExtended() {
      const dates = this.selectedInvoiceDateRange;
      if (!dates.startDate || !dates.endDate) return false;
      return dates.startDate !== dates.endDate;
    },
    emptyTableMessage() {
      if (this.selectedTransactionInvoice.invoiceTooLarge) {
        return this.$t('paymentsPage.invoiceTooLarge');
      }
      return this.$t('paymentsPage.noMatchingPayments');
    },
    clearbancURL() {
      return ENV.CLEARCOM_URL;
    },
  },
  watch: {
    filters: {
      deep: true,
      handler() {
        this.refreshData();
      },
    },
  },
  beforeMount() {
    if (!this.filters.page) {
      this.filters.page = 1;
      this.filters.limit = 50;
    }
  },
  mounted() {
    this.refreshData();
  },
  methods: {
    addLocaleParam,
    onInput(val) {
      this.reportsPreferredEmail = val;
      this.freeformSelected = false;
    },
    inputFreeformEmail(val) {
      this.freeformEmail = val;
    },
    async showDetailsPopup(transactionId) {
      this.$store.dispatchApiAction('FETCH_TRANSACTION_DETAILS', {
        id: transactionId,
      });
      this.showDetailsItemId = transactionId;
      this.$refs.viewDetailsPopup.open();
    },
    filterByAdvanceType(advanceType) {
      if (!advanceType) {
        // no type selected === all advance types
        this.filters.advanceId = this.advances.map((adv) => adv.id);
      } else {
        this.filters.advanceId = this.idsBySupratype[advanceType];
      }

      this.filters.advanceType = advanceType;
    },
    formatAmountOutstanding(amount) {
      // reverse pos / neg sign
      const dollarSign = Math.sign(amount) < 0 ? '$' : '-$';
      return `${dollarSign}${Math.abs(amount).toFixed(2)}`;
    },
    refreshData() {
      if (!this.getAmountOutstanding.isPending) {
        this.$store.dispatchApiAction('GET_AMOUNT_OUTSTANDING_BY_ADVANCE_TYPE');
      }

      if (!this.fetchPaymentReport.isPending) {
        this.filterByAdvanceType(this.filters.advanceType);

        this.$store.dispatchApiAction('FETCH_PAYMENT_REPORT', {
          ...this.filters,
          offset: (this.filters.page - 1) * this.filters.limit || 0,
          businessId: this.business.id,
          advanceId: this.filters.advanceId,
          userId: this.user.id,
          type: this.filters.type,
        });
      }
    },
    requestTransactionReport() {
      const advanceIds = this.filters.advanceId.map((adv) => Number(adv));

      this.$store.dispatchApiAction('REQUEST_PAYMENT_REPORT', {
        id: this.user.id,
        preferredEmail: this.freeformSelected
          ? this.freeformEmail
          : this.reportsPreferredEmail,
        advanceIds,
        reportStartDate: this.filters.createdAt
          ? formatDate(subMonths(new Date(this.filters.createdAt), 1))
          : null,
        reportEndDate: this.filters.createdAt
          ? formatDate(new Date(this.filters.createdAt))
          : null,
        transactionStatus: this.filters.status,
        transactionType: this.filters.type,
      });
    },
    selectFreeformEmail() {
      this.freeformSelected = !this.freeformSelected;
    },
    openTransactionReportPopup() {
      this.$store.commit('CLEAR_REQUEST_STATUS', 'REQUEST_PAYMENT_REPORT');
      this.$refs.requestTransactionReportPopup.open();
    },
    openInvoiceReportRequestPopup() {
      this.$refs.viewDetailsPopup.close();
      this.$refs.requestInvoiceReportPopup.open();
    },
    requestInvoiceTransactionReport() {
      this.$store.dispatchApiAction('REQUEST_INVOICE_EXPORT', {
        userId: this.user.id,
        childInvoiceId: this.selectedTransactionInvoice.id,
        preferredEmail: this.reportsPreferredEmail,
      });
    },
    advanceTypeById(advanceId) {
      return this.supratypeById[advanceId];
    },
  },
};
</script>

<style lang="less" module>
.menu {
  position: relative;
  background: @color-green-dark;
  height: 400px;
  padding: 60px 40px;
  transition: height 200ms ease;

  .line {
    border-top: 1px solid @color-gold;
    margin-top: 25px;
  }

  .dropdown-container {
    position: absolute;
    top: 50px;
    right: 40px;

    .dropdown {
      max-width: 240;
      margin: 2px;
    }

    @media only screen and (max-width: 970px) {
      position: relative;
      display: flex;
      align-items: top;
      justify-content: space-between;
      flex-flow: wrap;
      top: unset;
      right: unset;
      margin-top: 20px;
    }
  }

  @media only screen and (max-width: 970px) {
    padding: 20px;
    height: auto;
  }
}

.container {
  position: relative;
  background: @color-white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  height: 100%;
  min-height: 400px;
  width: calc(100% - 80px);
  margin: -245px 40px 40px 40px;
  padding: 30px;

  .button {
    position: absolute;
    top: 30px;
    right: 30px;

    @media only screen and (max-width: 800px) {
      position: relative;
      top: unset;
      right: unset;
      margin: 10px 0;
    }
  }

  @media only screen and (max-width: 970px) {
    margin: 0 0 40px 0;
    padding: 20px;
    width: 100%;
  }
}

.title {
  font-family: 'Quincy';
  font-size: 21px;
  color: @color-black;
  margin-bottom: 50px;

  &.large {
    font-size: 28px;
    margin-bottom: 0;

    @media only screen and (max-width: 800px) {
      font-size: 21px;
    }
  }

  &.white {
    color: @color-white;
  }

  @media only screen and (max-width: 800px) {
    margin-bottom: 10px;
  }
}

.footer {
  text-align: center;
  color: @color-gray-new;
  font-size: 12px;
  line-height: 15px;
  margin-bottom: 40px;
}

.modal-content {
  text-align: left;

  .radio {
    padding: 20px 20px 20px 50px;
    border-radius: 4px;
    background: @color-lightgray-100;
    margin: 5px 0 0 0;

    span[class^='radio_'] {
      top: 20px;
      left: 20px;
    }
  }

  .subtitle {
    color: @color-black-new;
    font-size: 16px;
    font-weight: 100;

    &.small {
      font-size: 12px;
    }
  }
}

.info {
  display: inline-block;
  border-left: 1px solid @color-gray;
  padding: 0 20px;
  color: @color-green-dark;
  font-weight: 500;
  font-size: 16px;

  &:first-child {
    border-left: none;
    padding: 0 20px 0 0;
  }

  .label {
    font-weight: 400;

    @media only screen and (max-width: 800px) {
      display: inline-block;
      vertical-align: middle;
    }
  }

  @media only screen and (max-width: 800px) {
    display: block;
    border: none;
    padding: 0;
  }
}

.modal-footer-button {
  float: right;

  @media only screen and (max-width: 900px) {
    display: block;
    float: unset;
    margin-top: 20px;
  }
}

.email-input {
  padding: 10px 0;
}
</style>

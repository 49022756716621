/* eslint-disable import/first */
// eslint-disable-next-line import/order
import {
  beforeBreadcrumbFilter,
  enrichErrorEvent,
  initFaroRuntime,
} from './utils/init-tracing';
import { createApp } from 'vue';
import { createGtm } from '@gtm-support/vue-gtm';
import App from '@/components/App';
import store from '@/store';
import routerV1 from '@/router';
import { googlePlugin, authOptions } from '@/store/plugins/google-oauth';
import VueSocialSharing from 'vue-social-sharing';
import VueApexCharts from 'vue3-apexcharts';
import { initSentry } from '@/utils/init-error-tracking';
import '@/utils/init-google-autocomplete';
import i18n from '@/plugins/i18n';
import { createMetaManager } from 'vue-meta';
import clickOutside from '@/plugins/directives/click-outside';
import onlyNumbers from '@/plugins/directives/only-numbers';
import { $filters } from '@/utils/init-filters';
import TableComponent from '@clearbanc/design-components/src/components/table-components';
import '@/assets/style/components/table-component.less';
import '@clearbanc/clear-components/resources/tailwind.css';
import '@clearbanc/clear-components/resources/theme.css';
import { ClearConfig } from '@clearbanc/clear-components/config';
import ToastService from '@clearbanc/clear-components/toastservice';
import ConfirmationService from '@clearbanc/clear-components/confirmationservice';
import Tooltip from '@clearbanc/clear-components/tooltip';
import prismic from '@/plugins/prismic';
import { VueQueryPlugin } from 'vue-query';
import routerV2 from '@/routes';
import './assets/external/company_toolkit';
import { auth0 } from './utils/auth0';
import { ENV, isProduction, isStaging } from '../config/app-env';

const router = ENV.ENABLE_ROUTES_V2 ? routerV2 : routerV1;

// eslint-disable-next-line no-unused-vars
const faro = initFaroRuntime(
  ENV.FARO_COLLECTOR_URL,
  ENV.FARO_APP_NAME,
  ENV.CLEARBANC_ENV,
  ENV.GIT_COMMIT_SHA,
);
const metaManager = createMetaManager();
const app = createApp(App);
initSentry(app, ENV.SENTRY_DSN, ENV.SENTRY_ENVIRONMENT, {
  beforeSend: enrichErrorEvent.bind(
    undefined,
    ENV.FARO_APP_ID,
    ENV.CLEARBANC_ENV,
  ),
  beforeBreadcrumb: beforeBreadcrumbFilter,
});
app.config.globalProperties.$filters = $filters;
app.config.globalProperties.$googlePlugin = googlePlugin();
app.config.globalProperties.$googlePlugin.load(authOptions);

app.use(VueQueryPlugin);
app.use(store);
// https://github.com/auth0/auth0-vue/blob/main/EXAMPLES.md#protecting-a-route
// router should be registered before auth0
app.use(router);
app.use(auth0);
app.use(prismic);
app.use(i18n);
app.use(ClearConfig);
app.provide('i18n', i18n);
app.use(metaManager);
app.directive('click-outside', clickOutside);
app.directive('only-numbers', onlyNumbers);
app.use(ConfirmationService);

// // Init Googs Tag Manager
app.use(
  createGtm({
    id: 'GTM-P8XCP59',
    debug: true,
    loadScript: isProduction || isStaging,
    vueRouter: router,
    trackOnNextTick: false,
  }),
);

// init social sharing library
app.use(VueSocialSharing);

app.use(VueApexCharts);
app.use(TableComponent);
app.use(ToastService);
app.directive('tooltip', Tooltip);

app
  .component('form-group', require('@/components/forms/FormGroup').default)
  .component('form-input', require('@/components/forms/FormInput').default)
  .component(
    'form-input-option',
    require('@/components/forms/FormInputOption').default,
  )
  .component('form-row', require('@/components/forms/FormRow').default)
  .component('save-bar', require('@/components/SaveBar').default)
  .component('error-message', require('@/components/ErrorMessage').default)
  .component(
    'UploadFileWidget',
    require('@/components/UploadFileWidget').default,
  )
  .component('icon', require('@/components/icons/Icon').default)
  .component('popup', require('@/components/popups/Popup').default)
  .component('todo-section', require('@/components/TodoSection').default)
  .component('v-button', require('@/components/VButton').default);

router.isReady().then(() => app.mount('#app'));

<template>
  <Modal
    v-model:visible="showModal"
    :header="
      $t(
        'components.tileGroupConnectSales.modalShopify.connectYourShopifyStore',
      )
    "
    :modal="true"
    :draggable="false"
    append-to="self"
    @show="$emit('modalAction', SALES_PLATFORMS.SHOPIFY, 'open')"
    @hide="$emit('modalAction', SALES_PLATFORMS.SHOPIFY, 'close')"
  >
    <div :class="$style['shopify-tip-content']">
      <p>
        {{
          $t(
            'components.tileGroupConnectSales.modalShopify.logInToShopifyAdmin',
          )
        }}
      </p>
      <p>
        {{
          $t(
            'components.tileGroupConnectSales.modalShopify.storeNameWillBeIncluded',
          )
        }}
        <button
          v-tooltip.focus.bottom="{
            value: handleShopifyToolTipContent,
            class: $style['shopify-modal-tooltip-content'],
            escape: true,
          }"
          type="button"
          class="pi pi-info-circle"
          :class="$style.tooltip"
          @focus="sendTooltipSegmentEvent"
        />
      </p>
    </div>
    <div :class="$style['input-container']">
      <div class="p-inputgroup">
        <InputText
          v-model="shopifyStoreName"
          data-cy="shopify-store-url"
          :placeholder="
            $t('components.tileGroupConnectSales.modalShopify.yourStoreName')
          "
          :class="errorMsg ? 'p-invalid' : ''"
        />
        <span class="p-inputgroup-addon">.myshopify.com</span>
      </div>
      <small v-if="errorMsg" class="p-error">{{ errorMsg }}</small>
      <DSButton
        data-cy="connect-shopify-button"
        :label="
          $t('components.tileGroupConnectSales.modalShopify.connectShopify')
        "
        class="p-button-secondary"
        :class="$style.button"
        @click="handleSubmit"
      />
    </div>
    <Message severity="success" icon="pi-shield" :closable="false">
      <p>
        {{
          $t(
            'components.tileGroupConnectSales.modalShopify.shopifyUsersShareTheirData',
          )
        }}
        <a
          :href="
            addLocaleParam(
              'https://help.clear.co/s/article/Connecting-your-accounts',
            )
          "
          target="_blank"
          @click="trackLearnMoreSegmentEvent"
        >
          {{ $t('components.tileGroupConnectSales.modalShopify.learnMore') }}</a
        >
      </p>
    </Message>
    <div :class="$style['help-section']">
      <i18n-t
        keypath="components.tileGroupConnectSales.modalShopify.haveTroubleWithThisStep"
        tag="p"
      >
        <template #contactSupport>
          <a
            :href="
              addLocaleParam(
                'https://help.clear.co/s/article/Connecting-Your-Shopify-Account',
              )
            "
            target="_blank"
            @click="trackLinkClick('fe_shopify_help_centre_click')"
            >{{
              $t('components.tileGroupConnectSales.modalShopify.contactSupport')
            }}</a
          >
        </template>
      </i18n-t>
    </div>
  </Modal>
</template>

<script>
import { shopifyUrlMixin, isMobileMixin } from '@/utils/vue-mixins';
import { mapGetters } from 'vuex';
import stateModuleAware from '@/mixins/state-module-aware';
import { SALES_PLATFORMS } from '@/data/platforms';
import analytics from '@/utils/analytics';
import DSButton from '@clearbanc/clear-components/button';
import Message from '@clearbanc/clear-components/message';
import Modal from '@clearbanc/clear-components/dialog';
import InputText from '@clearbanc/clear-components/inputtext';
import addLocaleParam from '@/utils/add-locale-param';

export default {
  components: {
    Modal,
    DSButton,
    InputText,
    Message,
  },
  mixins: [shopifyUrlMixin, isMobileMixin, stateModuleAware],
  data() {
    return {
      shopifyStoreName: '',
      errorMsg: '',
      storeName: '',
      showModal: false,
    };
  },
  computed: {
    ...mapGetters(['productSegmentLabel']),
    handleShopifyToolTipSrc() {
      if (this.isMobile()) {
        return require('@/assets/images/shopify-modal-tooltip-screenshot-mobile-en-us.jpg');
      }
      return require('@/assets/images/shopify-modal-tooltip-screenshot-en-us.jpg');
    },
    handleShopifyToolTipContent() {
      const src = this.handleShopifyToolTipSrc;
      const alt = this.$t(
        'components.tileGroupConnectSales.modalShopify.howToFindYourShopifyStore',
      );
      return `<strong>${this.$t(
        'components.tileGroupConnectSales.modalShopify.findYourShopifyStore',
      )}</strong><span>${this.$t(
        'components.tileGroupConnectSales.modalShopify.loginToYourShopifyAccount',
      )}</span><img src="${src}" alt="${alt}" title="${alt}" />`;
    },
    SALES_PLATFORMS: () => SALES_PLATFORMS,
  },
  methods: {
    addLocaleParam,
    open() {
      this.shopifyStoreName = '';
      this.storeName = '';
      this.errorMsg = '';
      this.showModal = true;
    },
    cleanStoreName() {
      // Remove spaces, .com, .ca and temporarily remove .myshopify.com as well
      const substringToRemove = [
        ' ',
        '.myshopify',
        '.com',
        '.ca',
        ')',
        '(',
        ',',
        '/admin',
      ];
      for (const substring of substringToRemove) {
        this.shopifyStoreName = this.shopifyStoreName?.replaceAll(
          substring,
          '',
        );
      }
    },
    async isInputValid() {
      this.cleanStoreName();
      const handleObject = this.handleUrl(this.shopifyStoreName, true);
      this.storeName = handleObject.storeName ? handleObject.storeName : '';
      this.errorMsg = handleObject.errorMessage ?? '';
      return !this.errorMsg;
    },
    async handleSubmit() {
      analytics.track('fe_shopify_store_connect_click', {
        productSegment: this.productSegmentLabel,
        experiment: this.defaultConfig?.experiments?.modals,
      });
      const inputValid = await this.isInputValid();
      if (inputValid) {
        this.$emit('submit', this.storeName);
        this.showModal = false;
      } else {
        // if the input is invalid, we want to emit to tell the ext-account component that oauth was attempted.
        // This will cause the ext-account tile to enter its error state. If the input IS valid, we don't want
        // to emit because otherwise the tile will enter its error state when an error hasn't yet occurred.
        this.$emit('error');
      }
      analytics.track('fe_shopify_store_entered', {
        storeName: this.shopifyStoreName,
        isValidStoreName: !this.errorMsg,
        productSegment: this.productSegmentLabel,
        experiment: this.defaultConfig?.experiments?.modals,
      });
    },
    trackLinkClick(eventName) {
      analytics.track(eventName, {
        targetArea: this.$route.name,
        productSegment: this.productSegmentLabel,
        experiment: this.defaultConfig?.experiments?.modals,
      });
    },
    trackLearnMoreSegmentEvent() {
      analytics.track('fe_shopify_data_collect_info_click', {
        targetArea: this.$route.name,
      });
    },
    sendTooltipSegmentEvent() {
      analytics.track('fe_shopify_tooltip_click', {
        targetArea: this.$route.name,
      });
    },
  },
};
</script>

<style lang="less" module>
.input-container {
  max-width: 400px;
  margin: 2rem auto;
  text-align: left;
  .button {
    display: block;
    margin-top: 2rem;
    margin-left: auto;
  }
}
.help-section {
  margin-top: 1.5rem;
  p {
    margin: 0;
  }
}
.shopify-tip-content {
  @media only screen and (max-width: 500px) {
    text-align: left;
  }
  p {
    margin: 0;
  }
}
.tooltip {
  color: @color-black;
  padding: 4px;
  cursor: help;
}
.shopify-modal-tooltip-content {
  max-width: 400px !important;

  @media only screen and (max-width: 500px) {
    max-width: calc(100% - 40px) !important;
    left: 20px !important;
  }

  strong,
  span,
  img {
    display: block;
  }

  img {
    max-width: 100%;
    margin-top: 1rem;
  }
}
</style>

// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container__Pvkg{padding:0}.steps_hz3bw{bottom:auto}.title_pS3Cd{color:currentColor;font-size:45px;line-height:45px;text-align:left;padding-bottom:10px}.subtitle_f8FlL{color:gray;font-size:12px;text-align:left}.custom-modal_DHKD9 div[class^=modal_]{min-height:400px;max-height:80%;height:auto;min-width:300px;max-width:750px;width:80%;overflow:auto}.custom-modal_DHKD9 div[class^=modal_] div[class^=content_]{margin:0;max-width:100%}.custom-modal_DHKD9 div[class^=modal_] div[class^=content_] div[class^=container_]{min-height:250px;height:-webkit-fit-content;height:-moz-fit-content;height:fit-content;max-height:100%;margin-bottom:100px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container__Pvkg",
	"steps": "steps_hz3bw",
	"title": "title_pS3Cd",
	"subtitle": "subtitle_f8FlL",
	"custom-modal": "custom-modal_DHKD9"
};
export default ___CSS_LOADER_EXPORT___;

import _ from 'lodash';
import { buildApiActions } from '@/utils/vuex-api-utils';

export default {
  state: {
    transactions: {},
    selectedTransactionDetails: {},
    paymentReport: [],
    transactionsCount: null,
    paymentReportCount: null,
    amountOutstanding: {
      marketing: 0,
      inventory: 0,
    },
  },
  getters: {
    amountOutstanding: (state) => state.amountOutstanding,
    convertedAmountCents: (state) => state.convertedAmountCents,
    transactions: (state) => _.values(state.transactions),
    selectedTransaction: (state) =>
      state.selectedTransactionDetails.transaction || {},
    selectedTransactionInvoice: (state) =>
      state.selectedTransactionDetails.invoice || {},
    vendorTransactions: (state) =>
      Object.values(state.transactions).filter(
        (txn) => txn.type === 'vendor_invoice',
      ),
    transactionsCount: (state) => state.transactionsCount,
    paymentReport: (state) => state.paymentReport,
    paymentReportCount: (state) => state.paymentReportCount,
  },
  ...buildApiActions(
    {
      FETCH_TRANSACTIONS: {
        action: (ctx, payload) => ({
          method: 'get',
          url: '/transactions',
          params: payload,
          async afterFailure(err) {
            await ctx.commit('api-FETCH_TRANSACTIONS', {
              response: [],
              responseCount: 0,
            });
          },
        }),
        mutation: (state, { response, responseTotalCount }) => {
          state.transactions = _.keyBy(response, 'id');
          state.transactionsCount = responseTotalCount;
        },
      },
      FETCH_TRANSACTION_DETAILS: {
        action: (ctx, { id }) => ({
          method: 'get',
          url: `/transactions/${id}`,
          options: { timeout: 45000 },
        }),
        mutation: (state, { response }) => {
          const { invoiceTooLarge, invoice } = response;
          invoice.invoiceTooLarge = invoiceTooLarge;

          // response includes transaction, invoice, logs
          state.selectedTransactionDetails = {
            ...response,
            invoice,
          };
          if (state.transactions[response.transaction.id]) {
            state.transactions[response.transaction.id] = response.transaction;
          }
        },
      },
      FETCH_PAYMENT_REPORT: {
        action: (ctx, payload) => ({
          method: 'get',
          url: `/users/${payload.userId}/repayments`,
          params: payload,
          async afterFailure(err) {
            await ctx.commit('api-FETCH_PAYMENT_REPORT', {
              response: [],
              responseCount: 0,
            });
          },
        }),
        mutation: (state, { response, responseTotalCount }) => {
          state.paymentReport = [...response];
          state.paymentReportCount = responseTotalCount;
        },
      },
      // create vendor payment (aka transaction)
      CREATE_VENDOR_PAYMENT: {
        action: (ctx, payload) => ({
          keyRequestStatusBy: payload.vendorId,
          method: 'post',
          url: `/vendors/${payload.vendorId}/payments`,
          params: payload,
          options: { timeout: 95000 },
        }),
        mutation: (state, { response }) => {
          state.transactions[response.id] = response;
        },
      },
      REQUEST_TRANSACTION_REPORT: {
        action: (ctx, payload) => ({
          method: 'post',
          url: `/users/${payload.id}/export-transaction-data`,
          params: payload,
        }),
      },
      REQUEST_PAYMENT_REPORT: {
        action: (ctx, payload) => ({
          method: 'post',
          url: `/users/${payload.id}/export-payment-report`,
          params: payload,
        }),
      },
      REQUEST_INVOICE_EXPORT: {
        action: (ctx, payload) => ({
          method: 'post',
          url: `/users/${payload.userId}/invoices/${payload.childInvoiceId}/export-transaction-data`,
          params: payload,
        }),
      },
      GET_PAYMENT_CONFIRMATION: {
        action: (ctx, payload) => ({
          method: 'get',
          url: `/transactions/${payload.transactionId}/confirmation`,
        }),
        mutation: (state, { response }) => {
          if (response.confirmationUrl) {
            // Use the confirmation url returned in the response
            // to download the payment confirmation PDF.
            const downloadLink = document.createElement('a');
            downloadLink.href = response.confirmationUrl;
            downloadLink.download = true;

            // Use a temporary anchor link to download the PDF.
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
          }
        },
      },
      GET_CONVERTED_CURRENCY_AMOUNT: {
        action: (ctx, payload) => ({
          method: 'post',
          url: '/transactions/convert-currency',
          params: payload,
        }),
        mutation: (state, { response }) => {
          state.convertedAmountCents = response.convertedAmountCents;
        },
      },
      GET_AMOUNT_OUTSTANDING_BY_ADVANCE_TYPE: {
        action: (ctx) => ({
          method: 'get',
          url: `/users/${ctx.rootGetters.userId}/amount-outstanding-by-advance-type`,
        }),
        mutation: (state, { response }) => {
          state.amountOutstanding = response;
        },
      },
    },
    {
      mutations: {
        CLEAR_SELECTED_TRANSACTION: (state) => {
          state.selectedTransactionDetails = {};
        },
        CLEAR_CONVERTED_CURRENCY_AMOUNT: (state) => {
          state.convertedAmountCents = null;
        },
      },
    },
  ),
};

<script setup>
import { computed } from 'vue';
import addLocaleParam from '@/utils/add-locale-param';
import { ENV } from '../../config/app-env';

const clearbancURL = computed(() => ENV.CLEARCOM_URL);
</script>

<template>
  <div :class="$style.footer">
    <div :class="$style.footer__item">
      Need help? Contact us at
      <a :class="$style.link" href="mailto:support@clear.co" target="_blank">
        support@clear.co
      </a>
    </div>

    <a
      :class="[$style.link, $style.footer__item]"
      :href="addLocaleParam(clearbancURL + '/privacy')"
      target="_blank"
    >
      {{ $t('common.privacyPolicy') }}
    </a>
  </div>
</template>

<style lang="less" module scoped>
.footer {
  display: flex;
  flex-direction: column;
}

.footer__item + .footer__item {
  margin: 24px 0 0 0;
}

@media (min-width: 768px) {
  .footer {
    flex-direction: row;
    justify-content: space-between;
  }

  .footer__item + .footer__item {
    margin: 0 0 0 24px;
  }
}
</style>

import store from '@/store';
import analytics from '@/utils/analytics';
import Cookies from 'js-cookie';
import { auth0 } from '@/utils/auth0';
import { PAYMENTS_TRACKING_PARAMS } from '@/data/payments';
import { AUTH, HOME } from '@/data/routes';
import LoginAuth0Page from '@/pages/PageLoginAuth0';
import PasswordResetPage from '@/pages/password-reset';
import Auth0ErrorPage from '@/pages/PageAuth0Error';
import { LocalStorage, LocalStorageKeys } from '@/utils/local-storage';

const signupExperiments = ['ctrl', 'ex-cl2024', 'ex-valentines2025'];

export default [
  // This is to be sure that the user is redirected with the required params in order to show the "signup" tab on the auth0 component
  {
    path: '/signup',
    name: AUTH.SIGNUP,
    beforeEnter: (to) => {
      if (
        Object.keys(to.query).some((param) =>
          PAYMENTS_TRACKING_PARAMS.includes(param),
        )
      ) {
        LocalStorage.setItem(LocalStorageKeys.SIGNUP_PARAMS, to.query);
      }

      const { location } = window;
      const experiment =
        signupExperiments[Math.floor(Math.random() * signupExperiments.length)];
      const params = new URLSearchParams(location.search);
      params.set('initialScreen', 'signUp');
      params.set('cb-ex', experiment);

      location.href = `${location.origin}/?${params.toString()}`;
    },
  },
  {
    path: '/login',
    name: AUTH.LOGIN,
    component: LoginAuth0Page,
    beforeEnter: (to, from) => {
      if (to.query.error) {
        return { name: AUTH.ERROR, query: to.query };
      }
      return { name: HOME.INDEX, query: to.query }; // redirect to /home in order to be consistent with deployed environments, see `_redirects`
    },
  },
  {
    path: '/logout',
    name: AUTH.LOGOUT,
    beforeEnter: async (to, from) => {
      // Using setTimeout to ensure the state is reset
      // after the next rendering cycle
      setTimeout(() => {
        store.commit('USER_LOGOUT');
        store.commit('RESET_SIGNUP_STATE');
      });
      analytics.track('fe_logout');
      Cookies.remove('cb-user-email', { path: '/' });
      await auth0.logout({
        returnTo: window.location.origin,
      });
    },
  },
  {
    path: '/password-reset',
    name: AUTH.PASSWORD_RESET,
    component: PasswordResetPage,
  },
  {
    path: '/error',
    name: AUTH.ERROR,
    component: Auth0ErrorPage,
    props: (route) => ({
      errorMessage: route.query?.error_description,
    }),
  },
];

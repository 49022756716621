<template>
  <LayoutAuth
    :on-submit="onSubmit"
    :initial-values="initialValues"
    :schema="formSchema"
    :error="requestError"
    :disabled="!!responseData"
    :success-message="responseData"
    submit-text="Send email"
    title="Reset your password"
  >
    <template #header>
      <Message
        data-cy="message-banner"
        :closable="false"
        :class="$style['message-banner']"
      >
        <p>
          To further enhance the protection of your account and data, we have
          recently implemented stronger security requirements. In order to align
          with these new security measures please update your password.
        </p>
        <p>
          For any questions or assistance, please contact us at
          <a href="mailto:support@clear.co">support@clear.co</a>
        </p>
      </Message>
    </template>
    <template #body>
      <div :class="$style['reset-content']">
        <span data-cy="email-label">
          Please enter your email address. We will send you an email to reset
          password.
        </span>
        <p class="p-input-icon-left">
          <i class="pi pi-envelope" />
          <InputTextGroup
            :disabled="!!responseData"
            name="email"
            type="email"
            placeholder="Email"
            data-cy="email-input"
          />
        </p>
      </div>
    </template>
  </LayoutAuth>
</template>

<script>
import { object, string } from 'yup';
import axios from 'axios';
import Message from '@clearbanc/clear-components/message';
import InputTextGroup from '@clearbanc/clear-components/inputtextgroup';
import { ENV } from '../../config/app-env';
import LayoutAuth from '../components/layouts/LayoutAuth';

export default {
  components: {
    LayoutAuth,
    InputTextGroup,
    Message,
  },
  data() {
    return {
      logoSVG: require('@/assets/logos/clearco-logo.svg'),
      email: '',
      requestError: null,
      responseData: null,
    };
  },
  methods: {
    async onSubmit(values) {
      const { email } = values;
      try {
        const { data } = await axios.post(
          `https://${ENV.AUTH0_DOMAIN}/dbconnections/change_password`,
          {
            client_id: ENV.AUTH0_CLIENT_ID,
            email,
            connection: 'Username-Password-Authentication',
          },
        );
        this.responseData = data;
      } catch (err) {
        this.requestError = err.message;
      }
    },
  },
  computed: {
    initialValues() {
      return {
        email: this.$route.query?.email || '',
      };
    },
    formSchema() {
      return object({
        email: string().email('Invalid email').required('Email is required'),
      });
    },
  },
};
</script>

<style lang="less" module>
.message-banner {
  margin: 20px 20px 0 20px !important;
}

.reset-content {
  input {
    padding-left: 40px;
  }
  i {
    top: 23px !important;
  }
  span,
  p {
    width: 100%;
  }
}
</style>

<template>
  <div class="data-security-page">
    <h4>{{ $t('account.pages.pageRemoveData.anonymizePersonalData') }}</h4>
    <p>
      <i18n-t
        keypath="account.pages.pageRemoveData.thisWillPermanentlyRemoveUserAccount"
      >
        <template #email>
          <b>{{ user.email }}</b>
        </template>
        <template #privacyPolicy>
          <a
            :href="addLocaleParam(clearbancURL + '/privacy')"
            target="_blank"
            >{{ $t('account.pages.pageRemoveData.privacyPolicy') }}</a
          >
        </template>
      </i18n-t>
    </p>
    <form-row>
      <form-input
        v-model="revokeConsentCheckbox"
        type="checkbox"
        no-label="no-label"
      >
        <span class="small">
          <i18n-t keypath="account.pages.pageRemoveData.revokeConsentTo">
            <template #privacyPolicy>
              <a
                :href="addLocaleParam(clearbancURL + '/privacy')"
                target="_blank"
                >{{ $t('account.pages.pageRemoveData.privacyPolicy') }}</a
              >
            </template>
          </i18n-t>
        </span>
      </form-input>
    </form-row>
    <div
      v-if="removeDataRequest.isSuccess"
      class="data-security-page__success-msg"
    >
      <icon name="check-circle" scale="1.2" />{{
        $t('account.pages.pageRemoveData.requestToRemoveYourData')
      }}
    </div>
    <v-button
      v-else
      :loading="removeDataRequest.isPending"
      :disabled="!revokeConsentCheckbox"
      theme="red"
      @click="openValidatePasswordPopup"
    >
      {{ $t('account.pages.pageRemoveData.anonymizeMyPersonalData') }}
    </v-button>
    <popup
      ref="verifyPasswordPopup"
      :title="$t('account.pages.pageRemoveData.confirm')"
    >
      <form-row no-inputs="no-inputs">
        <p>
          <i18n-t
            keypath="account.pages.pageRemoveData.pleaseConfirmYourRequest"
          >
            <template #email>
              <b>{{ user.email }}</b>
            </template>
          </i18n-t>
        </p>
        <p>
          <i18n-t
            keypath="account.pages.pageRemoveData.yourAccessWillBeRemoved"
          >
            <template #note>
              <strong>{{ $t('account.pages.pageRemoveData.note') }}</strong>
            </template>
            <template #privacyPolicy>
              <a
                :href="addLocaleParam(clearbancURL + '/privacy')"
                target="_blank"
                >{{ $t('common.privacyPolicyLowerCase') }}</a
              >
            </template>
          </i18n-t>
        </p>
      </form-row>
      <form-row>
        <form-input
          v-model="email"
          name="user[email]"
          autocomplete="on"
          type="email"
          :label="$t('common.email')"
          required="required"
          @keyup.enter="sendRequest"
        />
      </form-row>
      <form-row type="container">
        <error-message :request-status="removeDataRequest" />
        <v-button
          :loading="removeDataRequest.isPending"
          theme="red"
          :disabled="email != this.user.email"
          @click="sendRequest"
        >
          {{ $t('account.pages.pageRemoveData.confirm') }}
        </v-button>
      </form-row>
    </popup>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { mapRequestStatuses } from '@/utils/vuex-api-utils';
import analytics from '@/utils/analytics';
import addLocaleParam from '@/utils/add-locale-param';
import { useMeta } from 'vue-meta';
import { ENV } from '../../../../config/app-env';

export default {
  setup() {
    useMeta({
      title: 'Remove data | Clearco',
    });
  },
  data() {
    return {
      email: '',
      password: '',
      revokeConsentCheckbox: null,
    };
  },
  computed: {
    ...mapGetters(['user']),
    ...mapRequestStatuses({
      removeDataRequest: 'REMOVE_PERSONAL_DATA',
    }),
    clearbancURL() {
      return ENV.CLEARCOM_URL;
    },
    getTargetArea() {
      return {
        targetArea: this.$route.name,
      };
    },
    segmentProps() {
      return {
        productSegment: this.productSegmentLabel,
        ...this.getTargetArea,
      };
    },
  },
  methods: {
    addLocaleParam,
    openValidatePasswordPopup() {
      this.$refs.verifyPasswordPopup.open();
    },
    async sendRequest() {
      await this.$store.dispatchApiAction('REMOVE_PERSONAL_DATA');
      if (this.removeDataRequest.isSuccess) {
        analytics.track('fe_anonymize_data_confirm_clicked', this.segmentProps);
        this.$refs.verifyPasswordPopup.close();
        this.$router.push({ name: 'logout' });
      }
    },
  },
};
</script>

<style lang="less">
.data-security-page__list {
  margin: 0;
}
</style>

/* eslint-disable import/no-mutable-exports, no-console, no-unused-vars */
/* eslint-disable no-param-reassign, no-multi-assign */

/*
  This file is mostly just a replacement for segment's analytics.js snippet
  We copied their snippet which does the following:
  - make sure the snippet wasnt already loaded
  - stub their methods & add calls to queue which gets handled after load
  - load the script (async)
  - copied their argument re-shuffling code

  But also made some changes and added some functionality:
  - create an exportable module instead of relying on window.analytics
    inspired by https://github.com/vvo/analytics.js-loader
  - always load HTTPS version
  - add a callback for when it gets loaded
  - add a global kill switch to disable segment which:
    - uses config from process.env (production OR test mode)
    - also turns off user agent is phantom, which is used to pre-render
      otherwise we had scripts/pixels included from pre-render loading again
  - create wrappers around identify, track, page which:
    - respect kill switch and only log if tracking is disabled
    - if we have an unauth'd user but we do have an email, we split each call
      and send vero the info with email as the primary id so we can email
      abandoned signups
      see https://segment.com/docs/destinations/vero/
  
  An error will be raised in sentry if analytics fails to load within 10 seconds
*/
import Cookies from 'js-cookie';
import * as Sentry from '@sentry/vue';
import { ENV, isDevelopment } from '../../config/app-env';
import { ENVIRONMENTS } from '../data/environments';

const isAdmin = Cookies.get('cb-admin-token');
let ENABLE_TRACKING =
  [
    ENVIRONMENTS.PRODUCTION,
    ENVIRONMENTS.STAGING,
    ENVIRONMENTS.PREVIEW,
  ].includes(ENV.CLEARBANC_ENV) &&
  !isDevelopment &&
  !isAdmin;
if (ENV.TEST_SEGMENT) ENABLE_TRACKING = true;
// detect the pre-render so we dont include a bunch of 3rd party scripts
if (navigator.userAgent.indexOf('Headless') > 0) ENABLE_TRACKING = false;

if (!ENABLE_TRACKING) console.log('TRACKING DISABLED - calls logged only');

// THIS IS COPIED FROM SEGMENT'S ANALYTICS.js SNIPPET //////////////////////////
// https://segment.com/docs/sources/website/analytics.js/quickstart/

// Create a queue, but don't obliterate an existing one!
// eslint-disable-next-line
let analytics = (window.analytics = window.analytics || []);
// If the snippet was invoked already show an error.
if (analytics.invoked) throw new Error('segment loaded twice');
analytics.invoked = true; // flag to make sure snippet never invoked twice
analytics.SNIPPET_VERSION = '4.0.0';
// A list of the methods in Analytics.js to stub.
analytics.methods =
  'trackSubmit trackClick trackLink trackForm pageview identify reset group track ready alias debug page once off on'.split(
    ' ',
  );

// Define a factory to create stubs. These are placeholders
// for methods in Analytics.js so that you never have to wait
// for it to load to actually record data. The `method` is
// stored as the first argument, so we can replay the data.
analytics.factory = function (method) {
  return function (...args) {
    args.unshift(method);
    analytics.push(args);
    return analytics;
  };
};

// For each of our methods, generate a queueing stub.
for (let i = 0; i < analytics.methods.length; i++) {
  const key = analytics.methods[i];
  analytics[key] = analytics.factory(key);
}
// END SEGMENT SNIPPET /////////////////////////////////////////////////////////

// we wrapped the script loading part in a promise and only use HTTPS
const analyticsLoaded = new Promise((resolve, reject) => {
  if (!ENABLE_TRACKING) {
    resolve();
    return;
  }

  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.async = true;
  script.src = `https://cdn.segment.com/analytics.js/v1/${ENV.SEGMENT_WRITE_KEY}/analytics.min.js`;
  script.onload = () => {
    let retry = 100;
    const handle = setInterval(() => {
      if (window.analytics) {
        clearInterval(handle);
        ({ analytics } = window);
        resolve();
      } else if (retry < 0) {
        clearInterval(handle);
        reject(new Error('analytics failed to load'));
      } else {
        --retry;
      }
    }, 100);
  };

  // attach the script
  const firstScriptTag = document.getElementsByTagName('script')[0];
  if (firstScriptTag) {
    firstScriptTag.parentNode.insertBefore(script, firstScriptTag);
  }
});

// copied from https://github.com/enricomarino/is
const is = {
  fn(value) {
    const isAlert = typeof window !== 'undefined' && value === window.alert;
    if (isAlert) return true;
    const str = Object.prototype.toString.call(value);
    return (
      str === '[object Function]' ||
      str === '[object GeneratorFunction]' ||
      str === '[object AsyncFunction]'
    );
  },
  object(value) {
    return Object.prototype.toString.call(value) === '[object Object]';
  },
  string(value) {
    return Object.prototype.toString.call(value) === '[object String]';
  },
};

function callIfFunction(fn) {
  if (typeof fn === 'function') fn();
}

// writing a wrapper around analytics.js so we can intercept and mess with
// options and destinations.
// I copied the "Argument Shuffling" sections from their code
const cbAnalytics = {
  async identify(id, traits, options, fn) {
    try {
      await analyticsLoaded;
      // Argument reshuffling.
      /* eslint-disable no-unused-expressions, no-sequences */
      if (is.fn(options)) (fn = options), (options = null);
      if (is.fn(traits)) (fn = traits), (options = null), (traits = null);
      if (is.object(id)) (options = traits), (traits = id), (id = null);
      /* eslint-enable no-unused-expressions, no-sequences */

      options = options || {};
      // dont want to default traits to {} as it can reset segment
      if (ENABLE_TRACKING) {
        const existingTraits = analytics.user().traits() || {};
        const email = (traits || {}).email || existingTraits.email;

        /**
         * FullStory (FS) is integrated through Google Tag Manager (GTM)
         * which is integrated through Segment which attaches window.FS
         * object once loaded
         */
        if (window.FS) {
          window.FS.identify(id);
        }

        // if we have an email address but no user ID yet
        if (!id && email && !analytics.user().id()) {
          // track to vero using email as primary ID
          const veroOptions = {
            ...options,
            integrations: { All: false, Vero: true },
          };
          analytics.user().id(email);
          analytics.identify(traits, veroOptions);

          // track to everything BUT vero with a normal anonymous ID
          const nonVeroOptions = {
            ...options,
            integrations: { ...options.integrations, Vero: false },
          };
          analytics.user().id(null);
          analytics.identify(traits, nonVeroOptions, fn);
        } else {
          analytics.identify(id, traits, options, fn);
        }
      } else {
        console.log(
          `[SEGMENT] identify | ID=${id || 'ANONYMOUS'}`,
          traits,
          options,
        );
        callIfFunction(fn);
      }
    } catch (error) {
      Sentry.captureMessage(
        `Something went wrong with analytics library (segment) identify ${error}`,
        'warning',
      );
    }
  },
  async track(event, properties, options, fn) {
    try {
      await analyticsLoaded;
      // Argument reshuffling.
      /* eslint-disable no-unused-expressions, no-sequences */
      if (is.fn(options)) (fn = options), (options = null);
      if (is.fn(properties))
        (fn = properties), (options = null), (properties = null);
      /* eslint-enable no-unused-expressions, no-sequences */

      options = options || {};

      properties = properties || {};
      if (ENABLE_TRACKING) {
        const existingTraits = analytics.user().traits();

        // we will send FB Standard events seperately
        if (properties.fb) {
          const fbProps = properties.fb === true ? {} : properties.fb;
          delete properties.fb;

          // default fb standard event type = ViewContent
          const fbStandardEventType = fbProps.event || 'ViewContent';
          // default content_name = original event name (for ViewContent only)
          let fbContentName = fbProps.content_name;
          if (fbStandardEventType === 'ViewContent')
            fbContentName = fbContentName || event;
          analytics.track(
            fbStandardEventType,
            {
              ...(fbContentName && { content_name: fbContentName }),
              ...properties,
            },
            {
              ...options,
              integrations: { All: false, 'Facebook Pixel': true },
            },
          );
          // you can toggle on the event to only track to facebook
          if (fbProps.only) return;
        }

        // do not track custom events to facebook
        options.integrations = {
          ...options.integrations,
          'Facebook Pixel': false,
        };

        // if we have an email address but no user ID yet
        if (existingTraits.email && !analytics.user().id()) {
          // track to vero using email as primary ID
          const veroOptions = {
            ...options,
            integrations: { All: false, Vero: true },
          };
          analytics.user().id(existingTraits.email);
          analytics.track(event, properties, veroOptions);

          // track to everything BUT vero with a normal anonymous ID
          const nonVeroOptions = {
            ...options,
            integrations: { ...options.integrations, Vero: false },
          };
          analytics.user().id(null);
          analytics.track(event, properties, nonVeroOptions, fn);
        } else {
          analytics.track(event, properties, options, fn);
        }
      } else {
        console.log(`[SEGMENT] track | EVENT=${event}`, properties, options);
        callIfFunction(fn);
      }
    } catch (error) {
      Sentry.captureMessage(
        `Something went wrong with analytics library (segment) track ${error}`,
        'warning',
      );
    }
  },
  async page(category, name, properties, options, fn) {
    try {
      await analyticsLoaded;
      // Argument reshuffling.
      /* eslint-disable no-unused-expressions, no-sequences */
      if (is.fn(options)) (fn = options), (options = null);
      if (is.fn(properties)) (fn = properties), (options = properties = null);
      if (is.fn(name)) (fn = name), (options = properties = name = null);
      if (is.object(category))
        (options = name), (properties = category), (name = category = null);
      if (is.object(name))
        (options = properties), (properties = name), (name = null);
      if (is.string(category) && !is.string(name))
        (name = category), (category = undefined);
      /* eslint-enable no-unused-expressions, no-sequences */

      options = options || {};
      if (ENABLE_TRACKING) {
        const existingTraits = analytics.user().traits();

        // if we have an email address but no user ID yet
        if (existingTraits.email && !analytics.user().id()) {
          // track to vero using email as primary ID
          const veroOptions = {
            ...options,
            integrations: { All: false, Vero: true },
          };
          analytics.user().id(existingTraits.email);
          analytics.page(category, name, properties, veroOptions, fn);

          // track to everything BUT vero with a normal anonymous ID
          const nonVeroOptions = {
            ...options,
            integrations: {
              ...options.integrations,
              Vero: false,
              Salesforce: false,
            },
          };
          analytics.user().id(null);
          analytics.page(category, name, properties, nonVeroOptions, fn);
        } else {
          options = {
            ...options,
            integrations: { ...options.integrations, Salesforce: false },
          };
          try {
            if (properties.path.includes('/partners/')) {
              const array = properties.path.split('/');
              const index = array.indexOf('partners');
              properties.partner = array[index + 1];
              if (properties.fullPath?.includes('is_referral')) {
                properties.source = 'Landing page';
              } else {
                properties.source = 'iFrame';
              }
            }
          } catch (error) {
            // if error it means extra attributes were not present on parameters
          }
          analytics.page(category, name, properties, options, fn);
        }
      } else {
        console.log(
          `[SEGMENT] page | NAME=${category ? `${category}/` : ''}${name}`,
          properties,
          options,
        );
        callIfFunction(fn);
      }
    } catch (error) {
      Sentry.captureMessage(
        `Something went wrong with analytics library (segment) page ${error}`,
        'warning',
      );
    }
  },
  async reset() {
    try {
      await analyticsLoaded;
      if (ENABLE_TRACKING) {
        analytics.reset();
      } else {
        console.log('[SEGMENT] reset');
      }
    } catch (error) {
      Sentry.captureMessage(
        `Something went wrong with analytics library (segment) reset ${error}`,
        'warning',
      );
    }
  },
};

export default cbAnalytics;

<template>
  <div :class="$style.container">
    <!-- Mobile sidebar popout menu -->
    <Sidebar
      v-model:visible="isMenuOpen"
      :class="$style['mobile-sidebar']"
      position="full"
    >
      <template #header>
        <router-link
          :to="getLogoRoute()"
          data-cy="header-logo-link"
          :class="$style['mobile-sidebar-logo']"
          aria-label="Home"
          @click="isMenuOpen = false"
        >
          <span v-html="logoSVG" />
        </router-link>
      </template>
      <div>
        <menu-links
          v-for="link in mobileDisplayLinks"
          :key="link.title()"
          :links="link"
          :class="$style['mobile-sidebar-menu-links']"
          :open-dropdown="isDropdownOpen(link) || link.forceDropdown"
          :force-dropdown="link.forceDropdown"
          ref-type="mobile-link"
          @linkClicked="handleLinkClick"
          @toggleAccordion="trackAccordionEvent"
        />
      </div>
      <div :class="$style['mobile-sidebar-bottom-links']">
        <CustomLink
          :url="helpCenterURL"
          target="_blank"
          :class="$style['help-center']"
          :text="$t('common.helpCenter')"
        />
      </div>
    </Sidebar>

    <div :class="$style['desktop-container']">
      <!-- Legacy Modal -->
      <ModalLegacyFeature
        v-model:visible="isLegacyModalVisible"
        title="You are navigating to Legacy Capital Advances"
      >
        <template #subtitle>
          Capital Advances are legacy Clearco marketing and inventory products.
          Functionality is limited. Navigate back to
          <a
            style="text-decoration: underline; cursor: pointer"
            @click.prevent="$router.push('/')"
          >
            {{ 'Funding' }}
          </a>
          to fund your invoices and receipts.
        </template>
      </ModalLegacyFeature>
      <!-- Desktop sidebar menu -->
      <div :class="$style['desktop-sidebar']">
        <div :class="$style['desktop-menu']">
          <div>
            <router-link
              :to="getLogoRoute()"
              data-cy="header-logo-link"
              :class="$style['desktop-sidebar-logo']"
              aria-label="Home"
              @click="isMenuOpen = false"
            >
              <span v-html="logoSVG" />
            </router-link>
            <menu-links
              v-for="link in desktopDisplayLinks"
              :key="link.title()"
              :links="link"
              :class="$style['menu-links']"
              force-dropdown
              @linkClicked="handleLinkClick"
              @toggleAccordion="trackAccordionEvent"
            />
          </div>
          <div>
            <CustomLink
              :url="helpCenterURL"
              target="_blank"
              :class="$style['help-center']"
              :text="$t('common.helpCenter')"
            />
          </div>
        </div>
      </div>

      <div :class="$style['right-side']">
        <div>
          <div :class="topMenuClasses">
            <router-link
              :to="getLogoRoute()"
              data-cy="header-logo-link"
              aria-label="Home"
              @click="isMenuOpen = false"
            >
              <span :class="$style['mobile-sidebar-logo']" v-html="logoSVG" />
            </router-link>
            <hamburger-menu
              :has-notif="!!hamburgerNotifTheme"
              :notif-theme="hamburgerNotifTheme"
              :class="$style['mobile-menu-button']"
              data-ref="mobile-menu-button"
              @toggleMenu="toggleMenu"
            />
            <!-- My account - desktop dropdown -->
            <div
              v-click-outside="closeAccountMenu"
              :class="$style['my-account-dropdown']"
            >
              <button
                :class="myAccountDropdownClasses"
                data-ref="my-account-button"
                data-cy="my-account-button"
                aria-label="my account dropdown"
                @click="toggleAccountMenu"
              >
                {{ $t('data.wayfinding.wayfindingMenuTemplate.myAccount') }}
                <span :class="$style['accordion-container']">
                  <accordion-button
                    :is-inverted="displayAccountDropdown"
                    :class="$style['accordion-button']"
                  />
                </span>
              </button>
              <menu-links
                v-if="displayAccountDropdown"
                :links="accountDisplayLinks"
                list-children-only
                :class="$style['account-children']"
                @linkClicked="handleLinkClick"
              />
            </div>
          </div>
          <router-view :class="$style.page" />
        </div>
        <wayfinding-footer :class="footerClasses" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import _ from 'lodash';
import { ALTERNATE_CURRENCY_SYMBOLS } from '@/data/currency-code-with-names';
import { COLOR_THEMES } from '@/data/color-themes';
import { detectScrollMixin } from '@/utils/vue-mixins';
import {
  WAYFINDING_MENU_TEMPLATE,
  WAYFINDING_MENU_KEYS,
  WAYFINDING_ROUTES,
} from '@/data/wayfinding';
import analytics from '@/utils/analytics';
import { formatMoney } from '@/utils/currency';
import {
  CustomLink,
  AccordionButton,
  HamburgerMenu,
  MenuLinks,
} from '@/components';
import Sidebar from '@clearbanc/clear-components/sidebar';
import { PAYMENTS_ROUTE_NAMES } from '@/data/payments';
import { mapStateModuleGetters } from '@/utils/state-modules';
import ModalLegacyFeature from '@/components/modals/ModalLegacyFeature';
import { helpCenterURL } from '@/data/shared-faqs';
import WayfindingFooter from './FooterWayfinding';

export default {
  components: {
    MenuLinks,
    HamburgerMenu,
    Sidebar,
    AccordionButton,
    WayfindingFooter,
    ModalLegacyFeature,
    CustomLink,
  },
  mixins: [detectScrollMixin],
  data() {
    return {
      logoSVG: require('@/assets/logos/clearco-logo.svg'),
      closeSVG: require('@/assets/icons/close-black.svg'),
      isMenuOpen: false,
      desktopDisplayLinks: [],
      mobileDisplayLinks: [],
      displayAccountDropdown: false,
      helpCenterURL,
    };
  },
  computed: {
    ...mapGetters([
      'business',
      'connectedSalesAccounts',
      'userHasEverHadActiveAdvance',
      'activeAdvances',
      'productSegmentLabel',
      'totalInventoryCreditRemainingCents',
      'totalCreditRemainingCents',
      'issuedClearbancCard',
      'totalVenturesCreditRemainingCents',
      'getAdvanceCurrencyCode',
      'userId',
      'advances',
      'cards',
      'userHasOfferToBeSigned',
      'userHasAdvanceOffer',
      'isCoreUser',
      'isClearcashUser',
      'activeValidAdvanceOrAvailableValidSpend',
      'bills',
      'isCABusiness',
      'isUSBusiness',
      'netNewUser',
      'netNewInternationalUser',
      'userHasForceBnplFlag',
      'isDocumentUploadsEnabled',
      'showLegacyModal',
    ]),
    ...mapStateModuleGetters(['userIsInDiligence']),
    hasConnectedSalesAccounts() {
      return !!this.connectedSalesAccounts.length;
    },
    accountDisplayLinks() {
      const linkGroup = _.cloneDeep(
        WAYFINDING_MENU_TEMPLATE[WAYFINDING_MENU_KEYS.ACCOUNT],
      );
      const linksToExclude = [];
      if (
        this.netNewInternationalUser ||
        (this.isUSBusiness && !this.activeValidAdvanceOrAvailableValidSpend)
      ) {
        // Net new intl and non-active MCA US users don't see MCA content
        linksToExclude.push(
          WAYFINDING_ROUTES.ACCOUNT_CONNECTIONS,
          WAYFINDING_ROUTES.BUSINESS_DETAILS,
          WAYFINDING_ROUTES.DOCUMENT_UPLOADS,
        );
      }

      linkGroup.children = linkGroup.children.filter(
        (link) => !linksToExclude.includes(link.route),
      );

      return linkGroup;
    },
    getTargetArea() {
      return {
        targetArea: this.$route.name,
      };
    },
    showBankingTab() {
      return (
        (this.isClearcashUser || this.isUSBusiness) &&
        this.userHasEverHadActiveAdvance
      );
    },
    eventCommonProps() {
      return {
        ...this.getTargetArea,
        productSegment: this.productSegmentLabel,
        userId: this.userId,
        businessId: this.business.id,
      };
    },
    hasAvailableClearcoCardCredit() {
      const hasAvailableCredit =
        this.totalCreditRemainingCents > 0 ||
        this.totalVenturesCreditRemainingCents > 0;
      return this.issuedClearbancCard && hasAvailableCredit;
    },
    hasAvailableInventoryAdvanceCredit() {
      return this.totalInventoryCreditRemainingCents > 0;
    },
    userIsInvoiceOnly() {
      return (
        this.hasAvailableInventoryAdvanceCredit &&
        !this.hasAvailableClearcoCardCredit
      );
    },
    userHasAvailableAdvanceCredit() {
      return (
        this.hasAvailableClearcoCardCredit ||
        this.hasAvailableInventoryAdvanceCredit
      );
    },
    availableAmount() {
      return this.hasAvailableInventoryAdvanceCredit
        ? `${
            ALTERNATE_CURRENCY_SYMBOLS[this.getAdvanceCurrencyCode]
          }${this.formatAmountCents(
            this.totalInventoryCreditRemainingCents,
            this.getAdvanceCurrencyCode,
          )}`
        : '';
    },
    topMenuClasses() {
      return {
        [this.$style.header]: true,
        [this.$style.scrolled]: this.scrolledWithReset && !this.isMenuOpen,
      };
    },
    myAccountDropdownClasses() {
      return {
        [this.$style['account-dropdown']]: true,
        [this.$style.selected]: this.accountDisplayLinks.children
          .map((link) => link.route)
          .includes(this.$route.name),
      };
    },
    footerClasses() {
      return {
        [this.$style['footer-margin']]: this.userHasAvailableAdvanceCredit,
      };
    },
    hamburgerNotifTheme() {
      const { WARNING, INFO } = COLOR_THEMES;

      if (!this.userHasEverHadActiveAdvance) return null;

      if (this.userHasOfferToBeSigned) return WARNING; // @color-warning-300

      if (this.userHasAdvanceOffer) return INFO; // @color-info-300

      return null;
    },
    isLegacyModalVisible() {
      return this.showLegacyModal;
    },
  },
  async mounted() {
    await this.$store.dispatch('refreshAdvances');
    this.$store.dispatchApiAction('FETCH_USER_CONTRACTS');
    if (this.userHasEverHadActiveAdvance && this.cards.length === 0) {
      await this.$store.dispatchApiAction('FETCH_USER_CARDS');
    }

    // visibility for menu links
    Object.keys(WAYFINDING_MENU_TEMPLATE).forEach((link) => {
      const linkGroup = this.getVisibleLinks(link);
      if (linkGroup) {
        this.mobileDisplayLinks.push(linkGroup);
        if (link !== WAYFINDING_MENU_KEYS.ACCOUNT) {
          this.desktopDisplayLinks.push(linkGroup);
        }
      }
    });
  },
  created() {
    window.addEventListener('resize', this.closeAccountMenu);
  },
  unmounted() {
    window.removeEventListener('resize', this.closeAccountMenu);
  },
  methods: {
    closeAccountMenu() {
      if (this.displayAccountDropdown) {
        this.displayAccountDropdown = false;
      }
    },
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
    toggleAccountMenu() {
      analytics.track(
        `fe_my_account_${
          this.displayAccountDropdown ? 'collapse' : 'expand'
        }_clicked`,
        {
          ...this.eventCommonProps,
        },
      );
      this.displayAccountDropdown = !this.displayAccountDropdown;
    },
    getLogoRoute() {
      let route = this.$route.name;
      const activeMCAUser =
        this.userHasAdvanceOffer || !!this.activeAdvances.length;
      if (
        this.bills.length ||
        (!activeMCAUser && !this.userHasAvailableAdvanceCredit)
      ) {
        route = PAYMENTS_ROUTE_NAMES.PAYMENT_TABLE;
      } else if (this.userHasAvailableAdvanceCredit) {
        route = 'spend';
      }
      return { name: route };
    },
    getVisibleLinks(linkName) {
      const linkGroup = _.cloneDeep(WAYFINDING_MENU_TEMPLATE[linkName]);
      switch (linkName) {
        case WAYFINDING_MENU_KEYS.ACCOUNT:
          return this.accountDisplayLinks;

        case WAYFINDING_MENU_KEYS.PAYMENTS: // BNPL
          if (this.userHasForceBnplFlag) {
            return linkGroup;
          }
          // CA and net-new Intl businesses should not see a dropdown for BNPL
          if (this.isCABusiness || this.netNewInternationalUser) {
            linkGroup.children = [];
          }

          return linkGroup;

        case WAYFINDING_MENU_KEYS.FINANCING: // MCA
          // Users who do not see MCA:
          // no active MCA advances, no MCA spend available and no settled advances
          if (this.netNewUser) {
            return false;
          }

          linkGroup.children = linkGroup.children.filter((link) =>
            [
              WAYFINDING_ROUTES.SPEND,
              WAYFINDING_ROUTES.ADVANCES,
              WAYFINDING_ROUTES.PAYMENTS_BIZ_PROFILE_DASHBOARD,
            ].includes(link.route),
          );
          return linkGroup;

        case WAYFINDING_MENU_KEYS.REVENUE_SOURCES:
          if (this.netNewInternationalUser) {
            return false;
          }
          return linkGroup;

        case WAYFINDING_MENU_KEYS.ALL_DOCUMENTS:
          if (this.netNewInternationalUser) {
            return false;
          }
          if (this.isDocumentUploadsEnabled) {
            return linkGroup;
          }
          return false;

        default:
          return false;
      }
    },
    shouldDisplayQuickLink(quickLinkGroup) {
      switch (quickLinkGroup) {
        case WAYFINDING_MENU_KEYS.PAYMENTS:
          return true;

        case WAYFINDING_MENU_KEYS.VENDORS:
          return (
            (this.hasAvailableClearcoCardCredit ||
              this.hasAvailableInventoryAdvanceCredit) &&
            (this.userIsInDiligence ||
              this.isCoreUser ||
              this.userHasEverHadActiveAdvance)
          );

        case WAYFINDING_MENU_KEYS.CARD:
          return this.hasAvailableClearcoCardCredit;

        default:
          return false;
      }
    },
    trackLinkClickEvent(args) {
      analytics.track(`fe_${args.routeName.replace(/-/g, '_')}_clicked`, {
        ...this.eventCommonProps,
        ...args.eventBadgeProps,
      });
    },
    trackAccordionEvent(details) {
      const name = details.link.toLowerCase().split(' ').join('_');
      analytics.track(`fe_${name}_${details.type}_clicked`, {
        ...this.eventCommonProps,
      });
    },
    handleLinkClick(args) {
      this.trackLinkClickEvent(args);
      this.displayAccountDropdown = false;
      this.isMenuOpen = false;
      this.$router.push({ name: args.routeName }).catch(() => {});
    },
    formatAmountCents(amountCents, currencyCode) {
      return formatMoney(amountCents, currencyCode, {}, true);
    },
    isDropdownOpen(links) {
      return (
        links?.children &&
        links?.children.map((link) => link.route).includes(this.$route.name)
      );
    },
  },
};
</script>
<style>
body .p-sidebar .p-sidebar-content {
  padding: 0;
}
</style>
<style lang="less" module>
.container {
  min-height: 100vh;
  min-width: 100%;
  background-color: @color-grey-50;
  position: absolute;
  top: 0;
  left: 0;

  .right-side {
    width: calc(100% - 270px);
    overflow-y: auto;
    height: 100vh;
    @media only screen and (max-width: @wayfinding-tablet-width) {
      width: 100vw;
    }

    flex-direction: column;
    justify-content: space-between;
    float: right;
    display: flex;
  }

  .header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 1.55rem 1.25rem 1.25rem;
    height: 75px;
    transition: background-color 0.2s ease-out;
    width: 100%;
    z-index: 3;
    &.scrolled {
      z-index: 4;
      background: @color-grey-50;
      filter: drop-shadow(0 2px 10px rgba(0, 0, 0, 0.06));
      @media only screen and (max-width: @wayfinding-tablet-width) {
        background: @color-white;
      }
    }
    .mobile-menu-button {
      background: none;
      display: none;
      z-index: 2;
      @media only screen and (max-width: @wayfinding-tablet-width) {
        display: block;
        padding: 0 7px 4px;
      }
    }

    .my-account-dropdown {
      display: block;
      @media only screen and (max-width: @wayfinding-tablet-width) {
        display: none;
      }
    }
    .account-dropdown {
      align-items: center;
      background: none;
      border: none;
      color: @cc-light-brown;
      cursor: pointer;
      display: flex;
      font-family: @gerstner-font;
      font-size: @font-size-14;
      font-weight: 400;
      height: 60px;
      margin-right: 40px;
      padding: unset;
      &.selected {
        font-weight: 700;
        color: @color-black;
      }
      &:focus {
        outline: none;
      }
      .accordion-container {
        padding-left: 5px;
        .accordion-button {
          height: 25px;
          width: 25px;
          padding: 5px;
        }
      }
    }
    .account-children {
      background: @color-white;
      border-radius: 4px;
      border: 1px solid @color-grey-200;
      padding: 20px 10px;
      position: absolute;
      right: 3.5rem;
      top: 4rem;
      z-index: 2;
    }
    @media only screen and (max-width: @wayfinding-tablet-width) {
      .account-dropdown,
      .account-children {
        display: none;
      }
    }
  }
  .desktop-container {
    display: flex;
    flex-direction: row;
  }
  .desktop-sidebar {
    border-right: 1px solid @color-grey-200;
    background-color: @color-grey-50;
    font-family: @gerstner-font;
    font-size: @font-size-16;
    height: 100vh;
    width: 270px;
    z-index: 3;
    overflow-y: auto;
    pointer-events: all;
    padding: 1.25rem;
    @media only screen and (max-width: @wayfinding-tablet-width) {
      display: none;
    }
  }
  .desktop-sidebar-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1.5rem;

    svg {
      width: 170px;
    }
  }
  .desktop-sidebar::-webkit-scrollbar {
    display: none;
  }

  .desktop-sidebar:hover .desktop-sidebar:active {
    overflow-y: auto;
  }
  .page {
    @media only screen and (max-width: @wayfinding-tablet-width) {
      left: 0;
    }
  }
  .desktop-menu {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    .menu-links {
      padding-top: 1rem;
    }
  }
  .help-center {
    display: inline-block;
    padding: 0 0 0.855rem 1.875rem;
    color: @cc-light-brown;
    text-decoration: underline;
  }
  .footer-margin {
    @media only screen and (max-width: @wayfinding-tablet-width) {
      margin-bottom: 40px;
    }
  }
  .mobile-quick-links {
    display: none;
    @media only screen and (max-width: @wayfinding-tablet-width) {
      display: block;
      z-index: 3;
    }
  }
  .available-amount-text {
    font-size: 24px;
    font-weight: 500;
    margin-right: 80px;
  }
  .available-amount-container {
    align-items: center;
    display: flex;
    flex-grow: 1;
    height: 100%;
    justify-content: center;
    margin: auto;
    white-space: nowrap;
  }
  @media only screen and (max-width: 500px) {
    .available-amount-text {
      font-size: 22px;
      margin: auto;
    }
    .available-amount-container {
      margin: auto 20px;
      font-size: 4vw;
      justify-content: space-between;
    }
  }
  .desktop-quick-links {
    margin-bottom: 30px;
  }
}
.mobile-sidebar {
  [class='p-sidebar-content'] {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  [class='p-sidebar-header'] {
    justify-content: space-between;
  }
  .mobile-sidebar-menu-links {
    border-top: 1px solid #ceced0;
    &:last-child {
      border-bottom: 1px solid #ceced0;
    }
  }
  .mobile-sidebar-bottom-links {
    text-align: left;
    padding: 0 0 2.12rem 1.875rem;
  }
}
.mobile-sidebar-logo {
  display: none;
  width: 170px;
  @media only screen and (max-width: @wayfinding-tablet-width) {
    display: block;
  }
}
</style>

// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".vendorInfoBox_q9vqf{vertical-align:top;flex-grow:1}.infoBoxContainer_MEcpo{margin-bottom:10px;display:flex;flex-direction:row;align-items:stretch;min-height:200px}@media screen and (max-width:600px){.infoBoxContainer_MEcpo{flex-direction:column}}.middleBox_jxkIz{border-left:solid;border-right:solid;border-left-width:2px;border-right-width:2px;border-left-color:#d3d3d3;border-right-color:#d3d3d3}@media screen and (max-width:600px){.middleBox_jxkIz{border-left:none;border-right:none;border-top:solid;border-bottom:solid;border-top-width:2px;border-bottom-width:2px;border-top-color:#d3d3d3;border-bottom-color:#d3d3d3}}.infoBoxTitle_aZnlh{color:#000;padding-bottom:8px;text-align:center;font-weight:700}.warningMessage_JZh_X{border:solid;border-color:orange;border-radius:5px;border-width:2px;padding:10px;margin-bottom:20px}.errorMsg_NIqoH{color:red}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"vendorInfoBox": "vendorInfoBox_q9vqf",
	"infoBoxContainer": "infoBoxContainer_MEcpo",
	"middleBox": "middleBox_jxkIz",
	"infoBoxTitle": "infoBoxTitle_aZnlh",
	"warningMessage": "warningMessage_JZh_X",
	"errorMsg": "errorMsg_NIqoH"
};
export default ___CSS_LOADER_EXPORT___;

<template>
  <div v-if="items && items.length" class="pagination">
    <span
      v-if="numResults !== 1"
      tabindex="0"
      class="icon_container"
      @click="previousTen()"
      @keyup.enter="previousTen()"
    >
      <ui-icon name="back" class="pagination__icon" />
    </span>
    <div
      v-for="n in determinePages"
      :key="`detpag-${n}`"
      tabindex="0"
      class="pagination__page"
      :class="{ 'pagination__page--active': n === Number(filters.page) }"
      @click="changePage(n)"
      @keyup.enter="changePage(n)"
    >
      {{ n }}
    </div>
    <span
      v-if="numResults + 10 < totalPages"
      tabindex="0"
      class="icon_container"
      @click="nextTen()"
      @keyup.enter="nextTen()"
    >
      <ui-icon name="back" class="pagination__icon rotated" />
    </span>
    <div v-if="withDisplay" class="pagination__display">
      Display: <b>{{ filters.limit }}</b>
      <div
        v-for="n in 4"
        :key="`${n}-pagdispitem`"
        tabindex="0"
        class="pagination__display__item"
        :class="{
          'pagination__display__item--active': filters.limit === n * 50,
        }"
        @click="changeLimit(n * 50)"
        @keyup.enter="changeLimit(n * 50)"
      >
        {{ n * 50 }}
      </div>
    </div>
  </div>
</template>

<script>
import { UiIcon } from 'ui-components';
import { LocalStorage, LocalStorageKeys } from '@/utils/local-storage';

export default {
  components: { UiIcon },
  props: {
    items: {
      type: Array,
      required: true,
    },
    filters: {
      type: Object,
      required: true,
    },
    tableKey: String,
    totalCount: String,
    withDisplay: { type: Boolean, default: true },
  },
  data() {
    const nextNumResults = Math.floor(this.filters.page / 10) * 10;
    return {
      numResults: !nextNumResults ? 1 : nextNumResults,
    };
  },
  computed: {
    totalPages() {
      return Math.ceil(this.totalCount / this.filters.limit);
    },
    determinePages() {
      return [...Array(this.totalPages + 1).keys()].slice(
        this.numResults,
        10 + this.numResults,
      );
    },
  },
  methods: {
    changePage(num) {
      this.filters.page = num;
    },
    changeLimit(num) {
      const { tableKey } = this;
      const savedLimit =
        LocalStorage.getItem(LocalStorageKeys.PAGINATION_LIMIT) || {};

      LocalStorage.setItem(LocalStorageKeys.PAGINATION_LIMIT, {
        ...savedLimit,
        [tableKey]: num,
      });

      this.filters.limit = num;
      this.changePage(1);
      this.$emit('refreshData');
    },
    previousTen() {
      const nextNumResults = this.numResults - 10;
      this.numResults = nextNumResults < 1 ? 1 : nextNumResults;
    },
    nextTen() {
      this.numResults += 10;
    },
  },
};
</script>

<style lang="less">
.pagination {
  text-align: center;
  position: relative;
  user-select: none;

  .icon_container {
    margin: 0 10px;
    cursor: pointer;
  }

  .pagination__icon {
    height: 12px;
    width: 12px;
    transition: all 200ms ease;

    &.rotated {
      transform: rotate(180deg);

      &:hover {
        transform: rotate(180deg) scale(1.3);
      }
    }

    &:hover {
      transform: scale(1.3);
    }
  }

  .pagination__page {
    display: inline-block;
    background: transparent;
    padding: 0 8px;
    height: 35px;
    width: 35px;
    line-height: 35px;
    border-radius: 50%;
    font-family: 'Montserrat';
    font-size: 14px;
    color: @color-green-dark;
    cursor: pointer;
    font-weight: 700;
    transition: all 200ms ease;

    &.pagination__page--active {
      border: 1px solid @color-green-dark;
    }

    &:hover:not(.pagination__page--active) {
      text-decoration: underline;
    }
  }

  .pagination__display {
    position: absolute;
    top: 0;
    right: 0;
    width: 82px;
    height: 25px;
    overflow: hidden;
    font-size: 14px;
    cursor: default;
    transition: all 200ms ease;

    &:hover {
      width: 230px;
    }

    .pagination__display__item {
      display: inline-block;
      height: 24px;
      width: 28px;
      text-align: center;
      cursor: pointer;
      border-radius: 2px;
      background-color: white;
      margin: 0 2px;
      font-size: 12px;
      opacity: 0.7;

      &:hover {
        opacity: 1;
      }

      &:nth-child(2) {
        margin-left: 20px;
      }

      &.pagination__display__item--active {
        opacity: 1;
        font-weight: 700;
        background: @cta-blue;
        color: white;
      }
    }
  }
}
</style>

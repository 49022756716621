<template>
  <span>
    <ext-account
      title="shopify"
      :logo="shopify"
      :success="success"
      :disconnected="disconnected"
      :error="error"
      :logo-alt="$t('components.tileGroupConnectSales.shopifyLogo')"
      @click="openModal"
    />
    <!-- Shopify Modal -->
    <ModalShopify
      ref="shopifyModal"
      @submit="continueShopifyOauth"
      @error="$emit('error', SALES_PLATFORMS.SHOPIFY)"
      @modalAction="
        (platformName, action) => $emit('modalAction', platformName, action)
      "
    />
  </span>
</template>

<script>
import { isMobileMixin, modalsRedirectMixin } from '@/utils/vue-mixins';
import { LocalStorage, LocalStorageKeys } from '@/utils/local-storage';
import { SALES_PLATFORMS } from '@/data/platforms';
import ExtAccount from '@/components/modals/ModalExternalAccountOnboarding';
import ModalShopify from '@/components/modals/ModalShopify';

export default {
  components: {
    ExtAccount,
    ModalShopify,
  },
  mixins: [isMobileMixin, modalsRedirectMixin],
  props: {
    authorizeUri: {
      type: String,
      required: true,
    },
    success: Boolean,
    disconnected: Boolean,
    error: Boolean,
  },
  data() {
    return {
      storeName: '',
    };
  },
  computed: {
    shopify: () => require('@/assets/logos/shopify.png'),
    SALES_PLATFORMS: () => SALES_PLATFORMS,
  },
  methods: {
    openModal() {
      // For Shopify app approval testing we need to be able to connect a native (non-fivetran) account
      // and show the checkmark but not have any further interactions, see SC-170954
      // this.$emit('tileClick', SALES_PLATFORMS.SHOPIFY);
      // this.$refs.shopifyModal.open();
    },
    continueShopifyOauth(storeName) {
      this.storeName = storeName;
      // authorize URI provides link to the authorization page; www is replaced with store name for store's auth page.
      const authorizeUri = this.authorizeUri.replace('www', this.storeName);
      const options = { authorizeUri };
      if (this.isMobile()) {
        options.noPopup = true;
        options.redirectRoute = this.redirectRoute;
        LocalStorage.setItem(
          LocalStorageKeys.OAUTH_PROVIDER,
          SALES_PLATFORMS.SHOPIFY,
        );
        LocalStorage.setItem(
          LocalStorageKeys.OAUTH_REDIRECT_ROUTE,
          this.redirectRoute,
        );
      }
      this.$emit('submit', options);
    },
  },
};
</script>

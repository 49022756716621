<template>
  <div full-width no-footer>
    <div :class="$style.menu">
      <div style="display: flex; align-items: center">
        <!-- eslint-disable vuejs-accessibility/click-events-have-key-events -->
        <span
          :class="$style.backSpan"
          data-cy="go-back-button"
          @click="$router.go(-1)"
        >
          <ui-icon
            name="chevron-down"
            size="16"
            color="white"
            :class="$style.backChevron"
          />
        </span>
        <!-- eslint-enable -->

        <div :class="[$style.title, $style.large, $style.white]">
          {{ $t('spendPage.spendTransactions') }}
        </div>
      </div>

      <div :class="$style.line"></div>
      <div :class="$style['dropdown-container']">
        <custom-dropdown
          data-cy="advance-type-dropdown"
          :label="$t('spendPage.advanceType')"
          :model-value="filters.advanceType"
          :options="advanceTypeFilterOptions"
          :class="$style.dropdown"
          @input="(val) => (filters.advanceType = val)"
        />
        <custom-dropdown
          data-cy="advance-dropdown"
          :label="$t('spendPage.advance')"
          :model-value="filters.advanceId"
          :options="advancesFilterOptions"
          :class="$style.dropdown"
          required
          @input="(val) => (filters.advanceId = val)"
        />
        <custom-dropdown
          data-cy="type-dropdown"
          :label="$t('spendPage.type')"
          :model-value="filters.type"
          :options="typeFilterOptions"
          :class="$style.dropdown"
          required
          @input="(val) => (filters.type = val)"
        />
        <custom-dropdown
          data-cy="status-dropdown"
          :label="$t('spendPage.status')"
          :model-value="filters.status"
          :options="statusFilterOptions"
          :class="$style.dropdown"
          required
          @input="(val) => (filters.status = val)"
        />
        <custom-dropdown
          data-cy="monthyear-dropdown"
          :label="$t('spendPage.monthYear')"
          :model-value="filters.createdAt"
          :options="dateFilterOptions"
          :class="$style.dropdown"
          required
          @input="(val) => (filters.createdAt = val)"
        />
      </div>
    </div>
    <div :class="$style.container">
      <div :class="$style.title">
        {{ $t('spendPage.listOfSpendTransactions') }}
      </div>
      <design-button
        :text="$t('spendPage.downloadXlsx')"
        :class="$style.button"
        :disabled="filteredTransactions.length === 0"
        @click="openTransactionReportPopup"
      >
        <template v-slot:tooltip>
          {{ $t('spendPage.containsSpend') }}<br />{{
            $t('spendPage.andCashbackInformation')
          }}
        </template>
      </design-button>

      <spend-transactions-table :transactions="filteredTransactions" />
    </div>

    <pagination
      :items="filteredTransactions"
      :total-count="transactionsCount"
      :filters="filters"
      table-key="transactions"
      :with-display="false"
      class="m-b-50"
      @refreshData="refreshData"
    />

    <ui-modal
      ref="requestTransactionReportPopup"
      data-cy="request-transaction-report-popup"
      :title="$t('spendPage.downloadTransactionReport')"
      align="left"
    >
      <div :class="$style['modal-content']">
        <div v-if="requestTransactionReportRequest.isSuccess">
          <div>
            {{ $t('spendPage.requestReceived') }}
          </div>
          <design-button
            :text="$t('spendPage.ok')"
            class="m-t-70"
            @click="$refs.requestTransactionReportPopup.close()"
          />
        </div>
        <div v-else-if="requestTransactionReportRequest.isPending">
          {{ $t('spendPage.generatingReport') }}
        </div>
        <div v-else-if="requestTransactionReportRequest.isError">
          <error-message :request-status="requestTransactionReportRequest" />
          <div>
            {{ $t('spendPage.thereWasAProblem') }}
          </div>
          <design-button
            :text="$t('spendPage.ok')"
            class="m-t-20"
            @click="$refs.requestTransactionReportPopup.close()"
          />
        </div>
        <div v-else>
          {{ $t('spendPage.whichEmail') }}
          <ui-radio
            theme="dark-green"
            name="primaryEmail"
            :label="$t('spendPage.primaryEmail', { email: user.email })"
            :value="user.email"
            :selected="
              reportsPreferredEmail === user.email && !freeformSelected
            "
            :class="$style.radio"
            required
            @input="onInput"
          >
            {{ user.email }}
          </ui-radio>
          <ui-radio
            v-if="business.billingEmail"
            theme="dark-green"
            name="billingEmail"
            :label="
              $t('spendPage.billingEmail', {
                billingEmail: business.billingEmail,
              })
            "
            :value="business.billingEmail"
            :selected="
              reportsPreferredEmail === business.billingEmail &&
              !freeformSelected
            "
            :class="$style.radio"
            required
            @input="onInput"
          />
          <ui-radio
            v-else
            :label="$t('spendPage.setBillingEmailMessage')"
            :class="$style.radio"
            disabled
          >
            {{ $t('spendPage.contactToSetBilling') }}
          </ui-radio>
          <ui-radio
            theme="dark-green"
            name="freeformEmail"
            :selected="freeformSelected"
            :label="$t('paymentsPage.otherEmail')"
            :class="$style.radio"
            @input="selectFreeformEmail"
          />
          <ui-input
            v-model="freeformEmail"
            type="email"
            placeholder="email@example.com"
            :class="$style['email-input']"
            :disabled="!freeformSelected"
            @input="inputFreeformEmail"
          />
          <design-button
            :text="$t('spendPage.submit')"
            class="m-t-20"
            @click="requestTransactionReport"
          />
        </div>
      </div>
    </ui-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { UiInput, UiModal, UiRadio, UiIcon } from 'ui-components';
import { useMeta } from 'vue-meta';
import {
  format,
  subMonths,
  differenceInMonths,
  addMonths,
  endOfMonth,
} from 'date-fns';
import { formatDate } from '@/utils/dates';
import { mapRequestStatuses } from '@/utils/vuex-api-utils';
import trackFiltersMixin from '@/mixins/track-filters-in-query-params';
import { formatMoney } from '@/utils/currency';
import { ADVANCE_SUBTYPES, ADVANCE_SUPRATYPES } from '@/data/advance-subtypes';
import { DesignButton } from '@/components/mca';
import CustomDropdown from '@/components/CustomDropdownPayments';
import SpendTransactionsTable from '@/components/SpendTransactionsTable';
import Pagination from '@/components/Pagination';
import addLocaleParam from '@/utils/add-locale-param';

export default {
  components: {
    UiInput,
    UiRadio,
    UiModal,
    UiIcon,
    DesignButton,
    CustomDropdown,
    SpendTransactionsTable,
    Pagination,
  },
  setup() {
    useMeta({
      title: 'My Spend Transactions | Clearco',
    });
  },
  mixins: [trackFiltersMixin],
  trackFilters: {
    // passed to a mixin which sets `filters` in data
    keys: [
      'status',
      'advanceId',
      'advanceType',
      'type',
      'page',
      'limit',
      'createdAt',
    ],
  },
  data() {
    return {
      reportsPreferredEmail: '',
      freeformEmail: '',
      freeformSelected: false,
    };
  },
  computed: {
    ...mapGetters([
      'advances',
      'transactions',
      'transactionsCount',
      'business',
      'user',
    ]),
    ...mapRequestStatuses({
      fetchTransactionDetailsRequest: 'FETCH_TRANSACTION_DETAILS',
      fetchTransactionsRequest: 'FETCH_TRANSACTIONS',
      requestTransactionReportRequest: 'REQUEST_TRANSACTION_REPORT',
    }),
    advancesFilterOptions() {
      return this.advances
        .filter((adv) => {
          const advanceActivatedOrSettled = adv.activatedAt || adv.settledAt;
          if (!this.filters.advanceType) {
            return advanceActivatedOrSettled;
          } else if (this.filters.advanceType === ADVANCE_SUBTYPES.INVENTORY) {
            return (
              advanceActivatedOrSettled &&
              adv.subtype === ADVANCE_SUBTYPES.INVENTORY
            );
          }
          return (
            advanceActivatedOrSettled &&
            adv.subtype !== ADVANCE_SUBTYPES.INVENTORY
          );
        })
        .map((a) => ({
          key: a.id,
          name: `${this.$t('spendPage.advance')}#${a.id} - ${formatMoney(
            a.amountCents,
            a.currencyCode,
          )}`,
          value: a.id,
        }));
    },
    advanceTypeFilterOptions() {
      const filterOptions = Array.from(
        new Set(
          // only inventory is 'inventory', but unified, marketing, and marketing_facebook are all 'marketing'
          this.advances.map((adv) => ADVANCE_SUPRATYPES[adv.subtype]),
        ),
      );
      const optionsMap = {
        all: { key: 1, name: this.$t('spendPage.all'), value: null },
        marketing: {
          key: 2,
          name: this.$t('spendPage.marketing'),
          value: 'marketing',
        },
        inventory: {
          key: 3,
          name: this.$t('spendPage.inventory'),
          value: 'inventory',
        },
      };

      // If this user has one type of advance, only show that option. Otherwise, show both options, as well as "All"
      const advanceTypes =
        filterOptions.length > 1 || !this.advances.length
          ? Object.values(optionsMap)
          : [optionsMap[filterOptions[0]]];
      return advanceTypes;
    },
    typeFilterOptions() {
      return [
        { key: 1, name: this.$t('spendPage.all'), value: null },
        { key: 2, name: this.$t('spendPage.spend'), value: 'spend' },
        { key: 3, name: this.$t('spendPage.advance'), value: 'advance' },
        {
          key: 4,
          name: this.$t('spendPage.vendorBillpay'),
          value: 'vendor_invoice',
        },
      ];
    },
    statusFilterOptions() {
      return [
        { key: 1, name: this.$t('spendPage.all'), value: null },
        { key: 2, name: this.$t('spendPage.pending'), value: 'pending' },
        { key: 3, name: this.$t('spendPage.inTransit'), value: 'in_transit' },
        { key: 4, name: this.$t('spendPage.failed'), value: 'failed' },
        { key: 5, name: this.$t('spendPage.success'), value: 'success' },
        { key: 6, name: this.$t('spendPage.canceled'), value: 'canceled' },
        { key: 7, name: this.$t('spendPage.refund'), value: 'refund' },
      ];
    },
    dateFilterOptions() {
      const firstAdvanceCreatedAt =
        this.advances[this.advances.length - 1]?.createdAt;
      const firstAdvanceDate = firstAdvanceCreatedAt
        ? new Date(firstAdvanceCreatedAt)
        : new Date();
      const datesArray = [
        ...Array(differenceInMonths(new Date(), firstAdvanceDate)),
      ].reduceRight((acc, val, idx) => {
        const date = addMonths(firstAdvanceDate, idx + 1);

        return acc.concat({
          key: idx + 1,
          name: format(date, 'MMMM yyyy'),
          value: format(endOfMonth(date), 'yyyy-MM-dd'),
        });
      }, []);

      return [
        { key: 0, name: this.$t('spendPage.allDates'), value: null },
        ...datesArray,
      ];
    },
    filteredTransactions() {
      const txns = this.transactions.reduce((acc, txn) => {
        // when type is refund, the "incoming" info of the transaction is not useful
        // as it is just a signal if it affects the user's balance or not
        if (txn.type !== 'refund') {
          acc.push(txn);
        }

        // now split out the nested refund info into another row of info
        return !txn.refund
          ? acc
          : acc.concat({
              ...txn.refund,
              repaysAdvanceId: txn.repaysAdvanceId,
              // show user negative amount to signify refund
              amountCents: -1 * txn.refund.amountCents,
              description: `[REFUND] ${txn.refund.description || ''}`,
            });
      }, []);

      const filteredAdvance = this.advances
        .filter((adv) =>
          this.filters.advanceId
            ? adv.id === this.filters.advanceId
            : adv.activatedAt || adv.settledAt,
        )
        .map((adv) => adv.id);

      // If we're not on page 1 we don't want to add the first cashback line item
      // because it wouldn't represent the first transaction of that advance properly
      let skipLineItem = this.filters.page > 1;

      // Insert cashback line item in to the txns
      filteredAdvance.forEach((advId) => {
        const cashbackIndex = txns.findIndex((txn) => {
          return txn.repaysAdvanceId === parseInt(advId);
        });
        const relevantAdvance = this.advances.find(
          (advance) => advance.id === parseInt(advId),
        );
        if (cashbackIndex >= 0 && !skipLineItem) {
          txns.splice(cashbackIndex, 0, {
            repaysAdvanceId: advId,
            amountCents: relevantAdvance?.cashbackTotalCents,
            description: `${this.$t(
              'spendPage.cashbackEarnedForAdvanceId',
            )} #${advId}${
              relevantAdvance?.settledAt ? '' : this.$t('spendPage.toDate')
            }`,
          });
        }
        skipLineItem = false;
      });
      return txns;
    },
  },
  watch: {
    filters: {
      deep: true,
      handler() {
        this.refreshData();
      },
    },
  },
  beforeMount() {
    if (!this.filters.page) {
      this.filters.page = 1;
      this.filters.limit = 50;
    }
  },
  mounted() {
    this.refreshData();
  },
  methods: {
    addLocaleParam,
    onInput(val) {
      this.reportsPreferredEmail = val;
      this.freeformSelected = false;
    },
    inputFreeformEmail(val) {
      this.freeformEmail = val;
    },
    refreshData() {
      if (!this.fetchTransactionsRequest.isPending) {
        let advanceId;
        if (
          this.filters.advanceType &&
          this.filters.advanceType === ADVANCE_SUBTYPES.INVENTORY
        ) {
          advanceId = this.advances
            .filter((adv) => adv.subtype === ADVANCE_SUBTYPES.INVENTORY)
            .map((adv) => adv.id);
        } else if (this.filters.advanceType) {
          advanceId = this.advances
            .filter((adv) => adv.subtype !== ADVANCE_SUBTYPES.INVENTORY)
            .map((adv) => adv.id);
        }

        this.$store.dispatchApiAction('FETCH_TRANSACTIONS', {
          ...this.filters,
          ...(!this.filters.type && {
            type: ['spend', 'advance', 'vendor_invoice'],
          }),
          offset: (this.filters.page - 1) * this.filters.limit || 0,
          businessId: this.business.id,
          advanceId: this.filters.advanceId ?? advanceId,
        });
      }
    },
    requestTransactionReport() {
      const advanceIds = this.filters.advanceId ? [this.filters.advanceId] : [];

      this.$store.dispatchApiAction('REQUEST_TRANSACTION_REPORT', {
        id: this.user.id,
        preferredEmail: this.freeformSelected
          ? this.freeformEmail
          : this.reportsPreferredEmail,
        advanceIds,
        reportStartDate: this.filters.createdAt
          ? formatDate(subMonths(new Date(this.filters.createdAt), 1))
          : null,
        reportEndDate: this.filters.createdAt
          ? formatDate(new Date(this.filters.createdAt))
          : null,
        transactionStatus: this.filters.status,
        transactionType: this.filters.type,
      });
    },
    selectFreeformEmail() {
      this.freeformSelected = !this.freeformSelected;
    },
    openTransactionReportPopup() {
      this.$store.commit('CLEAR_REQUEST_STATUS', 'REQUEST_TRANSACTION_REPORT');
      this.$refs.requestTransactionReportPopup.open();
    },
  },
};
</script>

<style lang="less" module>
.menu {
  position: relative;
  background: @color-green-dark;
  height: 400px;
  padding: 60px 40px;
  transition: height 200ms ease;

  .line {
    border-top: 1px solid @color-gold;
    margin-top: 25px;
  }

  .dropdown-container {
    position: absolute;
    top: 50px;
    right: 40px;

    .dropdown {
      max-width: 240;
      margin: 2px;
    }

    @media only screen and (max-width: 970px) {
      position: relative;
      display: flex;
      align-items: top;
      justify-content: space-between;
      flex-flow: wrap;
      top: unset;
      right: unset;
      margin-top: 20px;
    }
  }

  @media only screen and (max-width: 970px) {
    padding: 20px;
    height: auto;
  }
}

.container {
  position: relative;
  background: @color-white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  height: 100%;
  min-height: 400px;
  width: calc(100% - 80px);
  margin: -245px 40px 40px 40px;
  padding: 30px;

  .button {
    position: absolute;
    top: 30px;
    right: 30px;

    @media only screen and (max-width: 800px) {
      position: relative;
      top: unset;
      right: unset;
      margin: 10px 0;
    }
  }

  @media only screen and (max-width: 970px) {
    margin: 0 0 40px 0;
    padding: 20px;
    width: 100%;
  }
}

.title {
  font-family: 'Quincy';
  font-size: 21px;
  color: @color-black;
  margin-bottom: 50px;

  &.large {
    font-size: 28px;
    margin-bottom: 0;

    @media only screen and (max-width: 800px) {
      font-size: 21px;
    }
  }

  &.white {
    color: @color-white;
  }

  @media only screen and (max-width: 800px) {
    margin-bottom: 10px;
  }
}

.footer {
  text-align: center;
  color: @color-gray-new;
  font-size: 12px;
  line-height: 15px;
  margin-bottom: 40px;
}

.modal-content {
  text-align: left;

  .radio {
    padding: 20px 20px 20px 50px;
    border-radius: 4px;
    background: @color-lightgray-100;
    margin: 5px 0 0 0;

    span[class^='radio_'] {
      top: 20px;
      left: 20px;
    }
  }

  .subtitle {
    color: @color-black-new;
    font-size: 16px;
    font-weight: 100;

    &.small {
      font-size: 12px;
    }
  }
}

span.backSpan {
  cursor: pointer;
  .backChevron {
    transform: rotate(90deg);
    margin-right: 24px;
  }
}

.email-input {
  padding: 10px 0;
}
</style>

// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".form_NLp2x[data-v-d731b8aa]{margin:40px 0 0}div[class^=c-form__row][data-v-d731b8aa]:empty{margin:0}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": "form_NLp2x"
};
export default ___CSS_LOADER_EXPORT___;

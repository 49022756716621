<script setup>
import { onMounted, computed, ref, readonly, watch } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { useForm } from 'vee-validate';
import { cloneDeep } from 'lodash';
import { format, parse, isDate } from 'date-fns';

import InputTextGroup from '@clearbanc/clear-components/inputtextgroup';
import DropdownGroup from '@clearbanc/clear-components/dropdowngroup';
import CheckboxGroup from '@clearbanc/clear-components/checkboxgroup';
import DSButton from '@clearbanc/clear-components/button';
import FormPhoneInput from '@/components/forms/FormPhoneInput';
import CalendarGroup from '@clearbanc/clear-components/calendargroup';
import { UploadFileWithDownloadableFileList } from '@/components';
import AutoCompleteAddress from '@/components/AutoCompleteAddress';
import FileUpload from 'primevue/fileupload';
import UploadFileList from '@/components/UploadFileList';
import FormInputRedacted from '@/components/forms/shared/FormInputRedacted';
import {
  readFileAsBase64,
  getBase64FileSize,
  validateMaxFileSize,
} from '@/utils/files';
import {
  getAllCallingCodes,
  getPhoneNumberCountryCode,
  getPhoneNumberWithoutCountryCode,
} from '@/utils/phone-numbers';
import { yup } from '@clearbanc/validation';
import { PAYMENTS_ROUTE_NAMES } from '@/data/payments';
import { parsePlaceData } from '@/utils/address';
import { getDisplayedErrorMessage } from '@/data/error-messages';
import { objectDiff } from '@/utils/object-comparison';
import { JobTitlesByCorpCountry } from '@/data/owner';
import { COUNTRY_CODES } from '@/data/supported-country-codes';
import {
  OWNER_ROLES,
  ENTITY_TYPE,
  ID_TYPE,
} from '@clearbanc/data-common-types';
import {
  stateOptionsForCountry,
  stateLabel,
  postalCodeLabel,
  personalTaxIdLabelShort,
} from '@/utils/local';
import {
  countryOptionsWithPriority,
  isCountryWithPostalCode,
} from '@/data/country-code-with-names';
import analytics from '@/utils/analytics';
import { EXTERNAL_FORM_TYPES } from '@/data/user-invite';
import { useToast } from '@/composables/useToast';
import { useProfiling } from '@/composables/useProfiling';
import { USER_INVITE } from '@/data/routes';
import { EVENT_TYPES } from '@/data/lexisnexis-types';

const MAX_TOTAL_FILE_SIZE_MB = 50;

// -----
// Setup
// -----

const store = useStore();
const router = useRouter();
const toast = useToast('bottom-left');
const props = defineProps({
  selectedOwner: {
    type: Object,
  },
  businessCorpCountry: {
    type: String,
  },
  removeContainer: {
    type: Boolean,
    default: false,
  },
  isExternalForm: {
    type: Boolean,
    default: false,
  },
  externalFormType: {
    type: String,
    required: true,
  },
  externalFieldStatus: {
    type: Object,
    required: true,
  },
});
const profiling = useProfiling(
  props.isExternalForm
    ? USER_INVITE.VERIFY
    : PAYMENTS_ROUTE_NAMES.PROFILE_OWNERS_STEP_ONE,
  {
    isExternalForm: props.isExternalForm,
    inviteId: props.selectedOwner?.uuid,
    ...(!props.isExternalForm && {
      type: store.getters.eventTypeBasedOnLoginsCount,
    }),
    ...(props.isExternalForm && {
      type:
        props.externalFormType === EXTERNAL_FORM_TYPES.EDIT
          ? EVENT_TYPES.LOGIN
          : EVENT_TYPES.ACCOUNT_CREATION,
    }),
  },
);

// ---------
// Constants
// ---------

const COUNTRY_CALLING_CODES = getAllCallingCodes();
const COUNTRIES = countryOptionsWithPriority([
  COUNTRY_CODES.US,
  COUNTRY_CODES.CA,
  COUNTRY_CODES.GB,
]);
const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10MB
const ACCEPTABLE_FILE_TYPES =
  '.docx,application/pdf,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,text/csv,image/jpeg,image/png';

// ---------
// Variables
// ---------

const isPrimaryUser = computed(() => !!props.selectedOwner?.isPrimary);
const passportFileUpload = ref(null);
const driversLicenseFileUpload = ref(null);
const errorMessage = ref(null);
const filesPassport = ref([]);
const filesDriversLicense = ref([]);
const requestStatuses = readonly({
  updateUser: computed(() => store.getters.requestStatus('UPDATE_USER')),
  updateUserWithoutAuth: computed(() =>
    store.getters.requestStatus('UPDATE_USER_WITHOUT_AUTH'),
  ),
});
const dynamicExternalFieldStatus = ref({});

// -------
// Methods
// -------

function citizenshipIsTaxIdCountry(usersCitizenshipCountry) {
  return [COUNTRY_CODES.US, COUNTRY_CODES.CA, COUNTRY_CODES.GB].includes(
    usersCitizenshipCountry,
  );
}

function onBack() {
  router.push({
    name: PAYMENTS_ROUTE_NAMES.PROFILE_OWNERS_LIST,
  });

  store.dispatch('UNSELECT_OWNER');
}

// -------
// Uploads
// -------

function generateFileFilter(...args) {
  return {
    metaId: props.selectedOwner?.id,
    entity: isPrimaryUser.value ? ENTITY_TYPE.USER : ENTITY_TYPE.OWNER,
    type: args.length > 1 ? args : args[0],
  };
}

function removeUpload(id) {
  store.dispatchApiAction('DELETE_USER_UPLOAD', { id });
}

const userUploads = computed(() => {
  const filter = generateFileFilter(
    ID_TYPE.PASSPORT_OR_LICENSE,
    ID_TYPE.ID,
    ID_TYPE.PASSPORT,
    ID_TYPE.DRIVERS_LICENSE,
  );

  return store.getters.uploadsMatching(filter);
});

// ----------------------------
// Unauthenticated File Uploads
// ----------------------------

async function onFileSelect(event, type) {
  const filesToUpload = event.files;
  const parsedFiles = await Promise.all(
    filesToUpload.map(async (file) => {
      const base64Data = await readFileAsBase64(file);
      const extension = file.name.split('.').pop().toUpperCase();
      return {
        name: file.name,
        extension,
        data: base64Data,
        documentType: type,
      };
    }),
  );

  if (type === ID_TYPE.DRIVERS_LICENSE) {
    filesDriversLicense.value = parsedFiles;
  } else if (type === ID_TYPE.PASSPORT) {
    filesPassport.value = parsedFiles;
  }
}

function onSelect(event, type) {
  const { files } = event;
  files.forEach((file) => {
    const fileUploadError = validateMaxFileSize(file, MAX_FILE_SIZE);
    if (fileUploadError) {
      if (type === ID_TYPE.PASSPORT) {
        passportFileUpload.value.remove(file);
      } else {
        driversLicenseFileUpload.value.remove(file);
      }
      toast.error(fileUploadError);
      return;
    }
    onFileSelect(event, type);
  });
}

// ----------
// Form Logic
// ----------

const validationSchema = computed(() =>
  yup.object({
    firstName: yup.userName(),
    lastName: yup.userName(),
    email: yup.email(),
    countryCallingCode: yup.phoneCode(),
    phone: dynamicExternalFieldStatus.value.phoneNumber
      ? yup.string().nullable()
      : yup.phone('countryCallingCode'),
    jobTitle: yup.jobTitle(),
    citizenship: yup.citizenship(),
    personalTaxId: dynamicExternalFieldStatus.value.personalTaxId
      ? yup.string().nullable()
      : yup.ssn('citizenship'),
    passportNumber: dynamicExternalFieldStatus.value.passportNumber
      ? yup.string().nullable()
      : yup.passport('citizenship'),
    birthday: dynamicExternalFieldStatus.value.birthday
      ? yup.string().nullable()
      : yup.birthday(),
    line1: dynamicExternalFieldStatus.value.address?.line1
      ? yup.string().typeError('').nullable()
      : yup.address(),
    line2: yup.address().notRequired(),
    city: dynamicExternalFieldStatus.value.address?.city
      ? yup.string().nullable()
      : yup.city(),
    state: dynamicExternalFieldStatus.value.address?.state
      ? yup.object().nullable()
      : yup.state(),
    country: yup.country(),
    postalCode: dynamicExternalFieldStatus.value.address?.postalCode
      ? yup.string().nullable()
      : yup.postalCode(),
    documentUploads: yup
      .string()
      .nullable()
      .when([], {
        is: () => {
          if (
            dynamicExternalFieldStatus.value.files?.passport ||
            dynamicExternalFieldStatus.value.files?.driversLicense
          ) {
            return false;
          }

          if (props.isExternalForm) {
            return (
              !filesPassport.value.length && !filesDriversLicense.value.length
            );
          }

          return userUploads.value.length < 1;
        },
        then: (schema) =>
          schema.required('Please upload your passport or drivers license.'),
        otherwise: (schema) => schema.notRequired(),
      })
      .test(
        'files-size-limit',
        `The total size of selected files cannot exceed the allowed limit of ${MAX_TOTAL_FILE_SIZE_MB} MB`,
        () => {
          if (props.isExternalForm) {
            const allUploadedFiles = [
              ...filesPassport.value,
              ...filesDriversLicense.value,
            ];
            const totalSizeFilesToUpload = allUploadedFiles.reduce(
              (acc, file) => acc + getBase64FileSize(file),
              0,
            );
            return totalSizeFilesToUpload < MAX_TOTAL_FILE_SIZE_MB;
          }
          return true;
        },
      ),
  }),
);

const { values, handleSubmit, setValues, isSubmitting, errors, validateField } =
  useForm({
    validationSchema,
  });

function populateSavedValues() {
  const initialValues = {};

  initialValues.firstName = props.selectedOwner?.firstName;
  initialValues.lastName = props.selectedOwner?.lastName;
  initialValues.phone = getPhoneNumberWithoutCountryCode(
    props.selectedOwner?.phoneNumber,
    props.selectedOwner?.address?.country,
  );
  initialValues.countryCallingCode =
    getPhoneNumberCountryCode(
      props.selectedOwner?.phoneNumber,
      props.selectedOwner?.address?.country,
    ) ||
    COUNTRY_CALLING_CODES.find((item) => {
      return item.value === COUNTRY_CODES.US;
    });
  initialValues.email = props.selectedOwner?.email;
  initialValues.personalTaxId =
    props.selectedOwner?.personalTaxId &&
    Number(props.selectedOwner?.personalTaxId);
  initialValues.passportNumber = props.selectedOwner?.passportNumber;
  initialValues.birthday = props.selectedOwner?.birthday;
  initialValues.line1 = props.selectedOwner?.address?.line1;
  initialValues.line2 = props.selectedOwner?.address?.line2;
  initialValues.city = props.selectedOwner?.address?.city;
  initialValues.postalCode = props.selectedOwner?.address?.postalCode;

  // Checkboxes
  if (props.selectedOwner?.jobRoles?.includes(OWNER_ROLES.DIRECTOR)) {
    initialValues.isDirector = [OWNER_ROLES.DIRECTOR];
  }

  if (props.selectedOwner?.jobRoles?.includes(OWNER_ROLES.OWNER)) {
    initialValues.isSignificantPercentageOwner = [OWNER_ROLES.OWNER];
  }

  // Job Title
  if (props.selectedOwner?.jobTitle) {
    initialValues.jobTitle = JobTitlesByCorpCountry(
      props.businessCorpCountry || COUNTRY_CODES.US,
    )?.find((item) => {
      return item.value === props.selectedOwner?.jobTitle;
    });
  }

  // State
  if (props.selectedOwner?.address?.state) {
    initialValues.state = stateOptionsForCountry(
      props.selectedOwner?.address?.country,
    )?.find((state) => state.value === props.selectedOwner?.address?.state);
  }

  // Country
  if (props.selectedOwner?.address?.country) {
    initialValues.country = COUNTRIES.find(
      (country) => country.value === props.selectedOwner?.address?.country,
    );
  }

  // Citizenship
  if (props.selectedOwner?.citizenship) {
    initialValues.citizenship = COUNTRIES?.find(
      (country) => country.value === props.selectedOwner?.citizenship,
    );
  }

  setValues(initialValues);
}

function formatFormDataForEndpoint(initialData, formValues) {
  const updatedData = cloneDeep(initialData);

  updatedData.firstName = formValues.firstName;
  updatedData.lastName = formValues.lastName;
  updatedData.jobTitle = formValues.jobTitle.value;
  updatedData.citizenship = formValues.citizenship.value;
  updatedData.address.country = formValues.country.value;

  updatedData.jobRoles = [];

  if (formValues.isSignificantPercentageOwner?.length) {
    updatedData.jobRoles.push(OWNER_ROLES.OWNER);
  }

  if (formValues.isDirector?.length) {
    updatedData.jobRoles.push(OWNER_ROLES.DIRECTOR);
  }

  if (props.selectedOwner?.jobRoles?.includes(OWNER_ROLES.SIGNATORY)) {
    updatedData.jobRoles.push(OWNER_ROLES.SIGNATORY);
  }

  if (!dynamicExternalFieldStatus.value.phoneNumber) {
    updatedData.phoneNumber =
      `${formValues.countryCallingCode.callingCode}${formValues.phone}`.replace(
        /\s+/g,
        '',
      );
  }

  if (
    !dynamicExternalFieldStatus.value.personalTaxId &&
    formValues.personalTaxId
  ) {
    updatedData.personalTaxId = String(formValues.personalTaxId);
  }

  if (!dynamicExternalFieldStatus.value.passportNumber) {
    updatedData.passportNumber = formValues.passportNumber;
  }

  if (!dynamicExternalFieldStatus.value.birthday) {
    const birthdayDate = isDate(formValues.birthday)
      ? formValues.birthday
      : parse(formValues.birthday, 'yyyy-MM-dd', new Date());
    updatedData.birthday = format(birthdayDate, 'yyyy-MM-dd');
  }

  if (!dynamicExternalFieldStatus.value.address?.line1) {
    updatedData.address.line1 = formValues.line1;
  }

  if (!dynamicExternalFieldStatus.value.address?.line2) {
    updatedData.address.line2 = formValues.line2;
  }

  if (!dynamicExternalFieldStatus.value.address?.city) {
    updatedData.address.city = formValues.city;
  }

  if (!dynamicExternalFieldStatus.value.address?.state) {
    updatedData.address.state = formValues.state?.value || null;
  }

  if (!dynamicExternalFieldStatus.value.address?.postalCode) {
    updatedData.address.postalCode = formValues.postalCode;
  }

  return updatedData;
}

function handleAddressSelected(placeData) {
  const parsedData = parsePlaceData(placeData);

  if (props.externalFormType === EXTERNAL_FORM_TYPES.EDIT) {
    dynamicExternalFieldStatus.value = {
      ...dynamicExternalFieldStatus.value,
      address: {
        line1: false,
        line2: false,
        city: false,
        state: false,
        postalCode: false,
      },
    };
  }

  setValues({
    ...values,
    ...parsedData,
  });
}

async function submitForm() {
  // Gather and format data for the endpoint
  const updatedSelectedOwner = formatFormDataForEndpoint(
    props.selectedOwner,
    values,
  );

  // Compare the data to confirm there were changes made
  const { changedProps: payload } = objectDiff(
    updatedSelectedOwner,
    props.selectedOwner,
  );

  // Make the request
  if (Object.keys(payload)?.length) {
    await store.dispatchApiAction('UPDATE_USER', payload);

    if (requestStatuses.updateUser.error) {
      errorMessage.value = getDisplayedErrorMessage(
        requestStatuses.updateUser.error,
      );
      return;
    }
    toast.success(`Changes saved.`);
  }

  profiling.finish();

  router.push({
    name: PAYMENTS_ROUTE_NAMES.PROFILE_OWNERS_LIST,
  });

  store.dispatch('UNSELECT_OWNER');
}

async function submitExternalForm() {
  const updatedSelectedOwner = formatFormDataForEndpoint(
    props.selectedOwner,
    values,
  );

  await store.dispatchApiAction('UPDATE_USER_WITHOUT_AUTH', {
    ...updatedSelectedOwner,
    files: [...filesPassport.value, ...filesDriversLicense.value],
  });

  if (requestStatuses.updateUserWithoutAuth.error) {
    return (errorMessage.value = getDisplayedErrorMessage(
      requestStatuses.updateUserWithoutAuth.error,
    ));
  }

  profiling.finish();
  return router.push({ name: 'user-invite-success' });
}

const onSubmit = handleSubmit(async () => {
  errorMessage.value = null;

  analytics.track('if_owner_profile_completed', {
    completed_user_id: props.selectedOwner?.id,
    completed_user_email: props.selectedOwner?.email,
  });

  if (props.isExternalForm) {
    return submitExternalForm();
  }

  return submitForm();
});

const onCitizenshipChange = () => {
  if (props.externalFormType === EXTERNAL_FORM_TYPES.EDIT) {
    dynamicExternalFieldStatus.value = {
      ...dynamicExternalFieldStatus.value,
      personalTaxId: false,
      passportNumber: false,
    };
  }

  setValues({
    ...values,
    personalTaxId: null,
    passportNumber: null,
  });
};

const onCountryChange = () => {
  if (props.externalFormType === EXTERNAL_FORM_TYPES.EDIT) {
    dynamicExternalFieldStatus.value = {
      ...dynamicExternalFieldStatus.value,
      address: {
        line1: false,
        line2: false,
        city: false,
        state: false,
        postalCode: false,
      },
    };
  }
};

// Vee Validate doesn't play well with our custom file upload component
// This is required to revalidate the form when a file is uploaded
watch([userUploads, filesPassport, filesDriversLicense], () => {
  validateField('documentUploads');
});

// -----------------
// Lifecycle Methods
// -----------------

onMounted(() => {
  if (props.selectedOwner?.email) {
    populateSavedValues();
  }

  if (props.externalFormType === EXTERNAL_FORM_TYPES.EDIT) {
    dynamicExternalFieldStatus.value = { ...props.externalFieldStatus };
  }
});
</script>

<template>
  <div :class="props.removeContainer ? '' : $style.container">
    <form
      class="c-form"
      :style="{ pointerEvents: isSubmitting ? 'none' : 'all' }"
      @submit="onSubmit"
    >
      <!-- ////////// -->
      <!-- Checkboxes -->
      <!-- ////////// -->

      <div class="c-group">
        <div class="c-form__row">
          <CheckboxGroup
            name="isDirector"
            :options="[
              {
                value: OWNER_ROLES.DIRECTOR,
                label: 'This individual is a Director / Board member',
              },
            ]"
          />
          <CheckboxGroup
            name="isSignificantPercentageOwner"
            :options="[
              {
                value: OWNER_ROLES.OWNER,
                label: 'This individual has 25%+ ownership',
              },
            ]"
          />
        </div>
      </div>

      <!-- //////////////////// -->
      <!-- Personal Information -->
      <!-- //////////////////// -->

      <div class="c-group">
        <div class="c-group__header">
          <h3 class="c-group__header__title">Personal Information</h3>
        </div>

        <div class="c-form__row grid grid-cols-12">
          <div class="col-span-6 md:col-span-12">
            <InputTextGroup name="firstName" label="First Name" />
          </div>
          <div class="col-span-6 md:col-span-12">
            <InputTextGroup name="lastName" label="Last Name" />
          </div>
        </div>

        <div class="c-form__row grid grid-cols-12">
          <div class="col-span-6 md:col-span-12">
            <InputTextGroup disabled name="email" label="Email" />
          </div>
          <div class="col-span-6 md:col-span-12">
            <FormInputRedacted
              :is-redacted="dynamicExternalFieldStatus.phoneNumber"
              @unlock-field="dynamicExternalFieldStatus.phoneNumber = false"
            >
              <FormPhoneInput
                :dropdown-attrs="{
                  name: 'countryCallingCode',
                  options: COUNTRY_CALLING_CODES,
                  optionLabel: 'nameString',
                  placeholder: 'Select',
                  filter: true,
                }"
                :input-attrs="{
                  name: 'phone',
                  label: 'Phone Number',
                }"
              />
            </FormInputRedacted>
          </div>
        </div>

        <div class="c-form__row grid grid-cols-12">
          <div class="col-span-6 md:col-span-12">
            <DropdownGroup
              name="jobTitle"
              :label="$t('common.jobTitle')"
              option-label="label"
              :options="JobTitlesByCorpCountry(props.businessCorpCountry)"
              :placeholder="$t('common.selectOption')"
              append-to="self"
            />
          </div>
          <div class="col-span-6 md:col-span-12">
            <DropdownGroup
              filter
              reset-filter-on-hide
              name="citizenship"
              label="Country of Citizenship"
              option-label="nameString"
              :options="COUNTRIES"
              :placeholder="$t('common.selectOption')"
              append-to="self"
              @change="onCitizenshipChange"
            />
          </div>
        </div>

        <div class="c-form__row grid grid-cols-12">
          <div class="col-span-6 md:col-span-12">
            <div v-if="citizenshipIsTaxIdCountry(values?.citizenship?.value)">
              <FormInputRedacted
                :is-redacted="dynamicExternalFieldStatus.personalTaxId"
                @unlock-field="dynamicExternalFieldStatus.personalTaxId = false"
              >
                <InputTextGroup
                  v-only-numbers
                  name="personalTaxId"
                  :label="personalTaxIdLabelShort(values?.citizenship?.value)"
                  :use-grouping="false"
                />
              </FormInputRedacted>
            </div>
            <div v-else>
              <FormInputRedacted
                :is-redacted="dynamicExternalFieldStatus.passportNumber"
                @unlock-field="
                  dynamicExternalFieldStatus.passportNumber = false
                "
              >
                <InputTextGroup
                  name="passportNumber"
                  label="Passport Number"
                  :use-grouping="false"
                />
              </FormInputRedacted>
            </div>
          </div>
          <div class="col-span-6 md:col-span-12">
            <FormInputRedacted
              :is-redacted="dynamicExternalFieldStatus.birthday"
              @unlock-field="dynamicExternalFieldStatus.birthday = false"
            >
              <CalendarGroup
                :label="$t('common.dateOfBirth')"
                name="birthday"
                :max-date="new Date()"
                :manual-input="false"
                placeholder="yyyy-mm-dd"
                show-icon
              />
            </FormInputRedacted>
          </div>
        </div>
      </div>

      <!-- /////// -->
      <!-- Address -->
      <!-- /////// -->

      <div class="c-group">
        <div class="c-group__header">
          <h3 class="c-group__header__title">Address</h3>
        </div>
        <div class="c-form__row">
          <FormInputRedacted
            :is-redacted="dynamicExternalFieldStatus.address?.line1"
            @unlock-field="dynamicExternalFieldStatus.address.line1 = false"
          >
            <AutoCompleteAddress
              name="line1"
              :label="$t('common.address.homeAddress')"
              @addressSelected="(place) => handleAddressSelected(place)"
            />
          </FormInputRedacted>
        </div>
        <div class="c-form__row">
          <FormInputRedacted
            :is-redacted="dynamicExternalFieldStatus.address?.line2"
            @unlock-field="dynamicExternalFieldStatus.address.line2 = false"
          >
            <InputTextGroup
              name="line2"
              :label="$t('common.address.homeAddressLine2')"
            />
          </FormInputRedacted>
        </div>
        <div class="c-form__row grid grid-cols-12">
          <div class="col-span-6 md:col-span-12">
            <DropdownGroup
              name="country"
              :label="$t('common.address.country')"
              :reset-filter-on-hide="true"
              :filter="true"
              option-label="nameString"
              :options="COUNTRIES"
              :placeholder="$t('common.selectOption')"
              append-to="self"
              @change="onCountryChange"
            />
          </div>
          <div class="col-span-6 md:col-span-12">
            <FormInputRedacted
              :is-redacted="dynamicExternalFieldStatus.address?.city"
              @unlock-field="dynamicExternalFieldStatus.address.city = false"
            >
              <InputTextGroup name="city" :label="$t('common.address.city')" />
            </FormInputRedacted>
          </div>
        </div>
        <div
          v-if="
            stateOptionsForCountry(values.country?.value) ||
            (values.country && isCountryWithPostalCode(values.country?.value))
          "
          class="c-form__row grid grid-cols-12"
        >
          <div
            v-if="stateOptionsForCountry(values.country?.value)"
            class="col-span-6 md:col-span-12"
          >
            <FormInputRedacted
              :is-redacted="dynamicExternalFieldStatus.address?.state"
              @unlock-field="dynamicExternalFieldStatus.address.state = false"
            >
              <DropdownGroup
                name="state"
                :label="stateLabel(values.country)"
                :filter="true"
                :reset-filter-on-hide="true"
                option-label="nameString"
                :options="stateOptionsForCountry(values.country?.value)"
                :placeholder="$t('common.selectOption')"
                append-to="self"
              />
            </FormInputRedacted>
          </div>

          <div class="col-span-6 md:col-span-12">
            <FormInputRedacted
              :is-redacted="dynamicExternalFieldStatus.address?.postalCode"
              @unlock-field="
                dynamicExternalFieldStatus.address.postalCode = false
              "
            >
              <InputTextGroup
                v-if="
                  values.country &&
                  isCountryWithPostalCode(values.country?.value)
                "
                name="postalCode"
                :label="postalCodeLabel(values.country?.value)"
              />
            </FormInputRedacted>
          </div>
        </div>
      </div>

      <!-- ///////// -->
      <!-- Documents -->
      <!-- ///////// -->

      <div class="c-group">
        <div class="c-group__header">
          <h3 class="c-group__header__title">Documents</h3>
          <p class="c-group__header__description">
            Upload any available documents to support your application.
          </p>
          <InputTextGroup
            name="documentUploads"
            label=""
            :style="{ display: 'none' }"
          />
        </div>
        <div class="c-form__row grid grid-cols-12">
          <div class="col-span-6 md:col-span-12">
            <p class="c-group__header__description mb-2">
              Passport (front and back)
            </p>

            <div v-if="isExternalForm" :class="$style['unauthenticaed-upload']">
              <FormInputRedacted
                :is-redacted="dynamicExternalFieldStatus.files?.passport"
                is-full-height
                @unlock-field="
                  dynamicExternalFieldStatus.files.passport = false
                "
              >
                <FileUpload
                  ref="passportFileUpload"
                  :show-upload-button="false"
                  :show-cancel-button="false"
                  choose-icon="pi pi-upload"
                  severity="info"
                  class="p-button-outlined"
                  :accept="ACCEPTABLE_FILE_TYPES"
                  :multiple="true"
                  @remove="(e) => onFileSelect(e, ID_TYPE.PASSPORT)"
                  @select="(e) => onSelect(e, ID_TYPE.PASSPORT)"
                >
                  <template #empty>
                    <p>
                      {{ $t('common.messagePrompt.dragAndDropFilesOrBrowse') }}
                    </p>
                  </template>
                  <template #content="{ files, removeFileCallback }">
                    <UploadFileList
                      :files="files"
                      @remove-file="(index) => removeFileCallback(index)"
                    />
                  </template>
                </FileUpload>
              </FormInputRedacted>
            </div>

            <UploadFileWithDownloadableFileList
              v-else
              ref="uploadFileWithDownloadableFileList"
              :upload-filters="generateFileFilter(ID_TYPE.PASSPORT)"
              :display-filters="generateFileFilter(ID_TYPE.PASSPORT)"
              :empty-prompt="
                $t('common.messagePrompt.dragAndDropFilesOrBrowse')
              "
              theme="onboarding"
              no-list
              :files="
                store.getters.uploadsMatching(
                  generateFileFilter(ID_TYPE.PASSPORT),
                )
              "
              @fileRemove="removeUpload"
            />
          </div>
          <div class="col-span-6 md:col-span-12">
            <p class="c-group__header__description mb-2">
              Driver’s license (front and back)
            </p>

            <div v-if="isExternalForm" :class="$style['unauthenticaed-upload']">
              <FormInputRedacted
                :is-redacted="dynamicExternalFieldStatus.files?.driversLicense"
                is-full-height
                @unlock-field="
                  dynamicExternalFieldStatus.files.driversLicense = false
                "
              >
                <FileUpload
                  ref="driversLicenseFileUpload"
                  :show-upload-button="false"
                  :show-cancel-button="false"
                  choose-icon="pi pi-upload"
                  severity="info"
                  class="p-button-outlined"
                  :accept="ACCEPTABLE_FILE_TYPES"
                  :multiple="true"
                  @remove="(e) => onFileSelect(e, ID_TYPE.DRIVERS_LICENSE)"
                  @select="(e) => onSelect(e, ID_TYPE.DRIVERS_LICENSE)"
                >
                  <template #empty>
                    <p>
                      {{ $t('common.messagePrompt.dragAndDropFilesOrBrowse') }}
                    </p>
                  </template>
                  <template #content="{ files, removeFileCallback }">
                    <UploadFileList
                      :files="files"
                      @remove-file="(index) => removeFileCallback(index)"
                    />
                  </template>
                </FileUpload>
              </FormInputRedacted>
            </div>

            <UploadFileWithDownloadableFileList
              v-else
              ref="uploadFileWithDownloadableFileList"
              :upload-filters="generateFileFilter(ID_TYPE.DRIVERS_LICENSE)"
              :display-filters="generateFileFilter(ID_TYPE.DRIVERS_LICENSE)"
              :empty-prompt="
                $t('common.messagePrompt.dragAndDropFilesOrBrowse')
              "
              theme="onboarding"
              no-list
              :files="
                store.getters.uploadsMatching(
                  generateFileFilter(ID_TYPE.DRIVERS_LICENSE),
                )
              "
              @fileRemove="removeUpload"
            />
          </div>
        </div>
      </div>

      <!-- //////// -->
      <!-- Controls -->
      <!-- //////// -->

      <div class="c-form__controls" :style="{ flexWrap: 'wrap' }">
        <DSButton
          v-if="!props.isExternalForm"
          label="Back"
          class="p-button-outlined"
          :loading="isSubmitting"
          @click="onBack"
        />
        <DSButton label="Save Profile" type="submit" :loading="isSubmitting" />
        <div
          v-if="Object.keys(errors).length >= 1 || errorMessage"
          class="p-error"
          :class="$style['controls-error']"
        >
          <span v-if="errorMessage">
            {{ errorMessage }}
          </span>
          <span v-else>See errors above</span>
        </div>
      </div>
    </form>
  </div>
</template>

<style module>
.container {
  padding: 20px;
  margin-top: 40px;
  border: 1px solid #e8e8ea;
  box-shadow: 0 2px 8px 0 #0000000f;
  background: #fff;
}

.controls-error {
  text-align: right;
  width: 100%;
  margin-top: 20px;
}

@media (min-width: 800px) {
  .container {
    padding: 40px;
  }
}

.unauthenticaed-upload [class^='p-badge'] {
  display: none;
}
</style>

import { buildApiActions } from '@/utils/vuex-api-utils';

function initialState() {
  return {
    params: {
      businessType: 'ecom',
      productSegment: 'core',
      agreedToNewsletter: false,
      revenue: 0.06,
      adspend: 0.06,
    },
  };
}

export default {
  state: initialState,
  getters: {
    newSignupParams: (state) => state.params,
  },
  ...buildApiActions(
    {},
    {
      mutations: {
        UPDATE_SIGNUP_PARAMS: (state, field) => {
          state.params = { ...state.params, ...field };
        },
        RESET_SIGNUP_STATE: (state) => {
          const s = initialState();

          Object.keys(s).forEach((key) => {
            state[key] = s[key];
          });
        },
      },
      actions: {
        UPDATE_SIGNUP_PARAMS: ({ commit }, field) => {
          commit('UPDATE_SIGNUP_PARAMS', field);
        },
        RESET_SIGNUP_STATE: ({ commit }) => {
          commit('RESET_SIGNUP_STATE');
        },
      },
    },
  ),
};

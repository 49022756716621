// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".checkbox-container_wgkLR{position:relative;font-family:Montserrat;font-weight:500;font-size:12px;line-height:17px;color:#4f5362;min-height:20px;-webkit-user-select:none;-moz-user-select:none;user-select:none;padding-left:25px;text-align:left;clear:both}.checkbox-container_wgkLR input[type=checkbox]{display:none}.checkbox-container_wgkLR.error_DoZ6k{min-height:40px}.checkbox-container_wgkLR.disabled_sxpyP{color:#a7a6a6;pointer-events:none}.checkbox-container_wgkLR .text_YKqLz{display:inline-block;line-height:15px!important}.checkbox-container_wgkLR .checkbox_jGqEP{position:absolute;top:0;left:0;height:15px;width:15px;box-shadow:inset 0 0 0 1px #333;background:#fff;border-radius:2px;text-align:center;vertical-align:middle;cursor:pointer;color:#000;line-height:16px;padding-left:1px}.checkbox-container_wgkLR .checkbox_jGqEP:focus{outline:none}.disabled_sxpyP.checkbox-container_wgkLR .checkbox_jGqEP{color:#a7a6a6;box-shadow:inset 0 0 0 1px #a7a6a6}@media only screen and (max-width:670px){.checkbox-container_wgkLR .checkbox_jGqEP{height:20px;width:20px}}.checkbox-container_wgkLR .error-msg_SSDcD{font-size:10px;line-height:15px;font-weight:500;text-align:left;color:#881228}@media only screen and (max-width:670px){.checkbox-container_wgkLR{margin-bottom:10px;padding-top:3px}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"checkbox-container": "checkbox-container_wgkLR",
	"error": "error_DoZ6k",
	"disabled": "disabled_sxpyP",
	"text": "text_YKqLz",
	"checkbox": "checkbox_jGqEP",
	"error-msg": "error-msg_SSDcD"
};
export default ___CSS_LOADER_EXPORT___;

import { authGuard } from '@auth0/auth0-vue';
import { USER } from '@/data/routes';
import PaymentBizProfileBusinessOwners from '@/pages/payments/business-profile/business-owners';
import BusinessProfileOwnersStepOne from '@/pages/payments/business-profile/owners/step-one';

export default [
  {
    path: 'user',
    component: PaymentBizProfileBusinessOwners,
    beforeEnter: authGuard,
    children: [
      {
        path: '',
        alias: '/pay-vendors/business-profile/owners/:id/step-one',
        name: USER.INDEX,
        component: BusinessProfileOwnersStepOne,
      },
    ],
  },
];

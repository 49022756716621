<template>
  <div>
    <LayoutAccountConnector :class="classes">
      <ExtAccount
        ref="facebook"
        title="facebook"
        :logo="facebookLogo"
        :success="
          getExtAccStatus(MARKETING_PLATFORMS.FACEBOOK) ===
          CONNECTION_STATUS.CONNECTED
        "
        :error="errors.facebook"
        :logo-alt="$t('components.TileGroupConnectingMarketing.facebookLogo')"
        @click="attemptFacebookOauth"
      />
      <!-- Note: clicking the Instagram tile launches Facebook oauth -->
      <ExtAccount
        ref="instagram"
        title="instagram"
        :logo="instagramLogo"
        :success="
          getExtAccStatus(MARKETING_PLATFORMS.INSTAGRAM) ===
          CONNECTION_STATUS.CONNECTED
        "
        :error="errors.instagram"
        :logo-alt="$t('components.TileGroupConnectingMarketing.instagramLogo')"
        @click="
          handleTileClick(MARKETING_PLATFORMS.INSTAGRAM, eventTrackingFields);
          $refs.oauthConnect.launch(MARKETING_PLATFORMS.FACEBOOK);
        "
      />
      <ConnectGoogleads
        ref="googleads"
        :authorize-uri="OAUTH_PROVIDER_CONFIG.googleads.authorizeUri"
        :success="
          getExtAccStatus(MARKETING_PLATFORMS.GOOGLEADS) ===
          CONNECTION_STATUS.CONNECTED
        "
        :error="errors.googleads"
        @submit="
          (config) => attemptOauth(MARKETING_PLATFORMS.GOOGLEADS, config)
        "
        @modalAction="
          (platformName, action) =>
            trackModal(platformName, action, eventTrackingFields, isMobile())
        "
        @error="updateTileToErrorStatus(MARKETING_PLATFORMS.GOOGLEADS)"
      />
      <ConnectAmazonads
        ref="amazonads"
        :authorize-uri="OAUTH_PROVIDER_CONFIG.amazonads.authorizeUri"
        :success="
          getExtAccStatus(MARKETING_PLATFORMS.AMAZONADS) ===
          CONNECTION_STATUS.CONNECTED
        "
        :error="errors.amazonads"
        @submit="
          (config) => attemptOauth(MARKETING_PLATFORMS.AMAZONADS, config)
        "
        @modalAction="
          (platformName, action) =>
            trackModal(platformName, action, eventTrackingFields, isMobile())
        "
        @error="updateTileToErrorStatus(MARKETING_PLATFORMS.AMAZONADS)"
      />
      <ExtAccount
        is-missing-platform-tile
        @noPlatformModalAction="
          (action, platformName) =>
            trackNewPlatformModal(
              eventTrackingFields,
              action,
              INTEGRATION_TYPES.MARKETING,
              platformName,
            )
        "
      />
    </LayoutAccountConnector>
    <OauthConnect
      ref="oauthConnect"
      :provider-config="OAUTH_PROVIDER_CONFIG"
      @code="oauthSuccess"
      @error="handleOauthError"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { OAUTH_PROVIDER_CONFIG } from '@/data/oauth-provider-config';
import { CONNECTION_STATUS } from '@/data/connection-types';
import OauthConnect from '@/components/OauthConnect';
import { isMobileMixin } from '@/utils/vue-mixins';
import { INTEGRATION_TYPES } from '@/data/onboarding';
import { MARKETING_PLATFORMS } from '@/data/platforms';
import { mapRequestStatuses } from '@/utils/vuex-api-utils';
import externalAccountsComposables from '@/composables/external-accounts';
import ExtAccount from '@/components/modals/ModalExternalAccountOnboarding';
import ConnectGoogleads from '@/components/connect/ConnectGoogleads';
import ConnectAmazonads from '@/components/connect/ConnectAmazonads';
import LayoutAccountConnector from '@/components/layouts/LayoutAccountConnector';
import { useContextRoot } from '@/utils/context-root';
import { LocalStorage, LocalStorageKeys } from '@/utils/local-storage';

export default {
  components: {
    OauthConnect,
    ExtAccount,
    ConnectGoogleads,
    ConnectAmazonads,
    LayoutAccountConnector,
  },
  mixins: [isMobileMixin],
  props: {
    showError: { type: Boolean, required: true },
    eventTrackingFields: { type: Object, default: () => {} },
  },
  setup(props, context) {
    const {
      errors,
      showNextStepsError,
      trackAccountConnected,
      updateTileToErrorStatus,
      handleTileClick,
      handleOauthError,
      trackErrorBanner,
      trackNewPlatformModal,
      trackModal,
      getExtAccStatus,
    } = externalAccountsComposables(useContextRoot());
    return {
      errors,
      showNextStepsError,
      trackAccountConnected,
      updateTileToErrorStatus,
      handleTileClick,
      handleOauthError,
      trackErrorBanner,
      trackNewPlatformModal,
      trackModal,
      getExtAccStatus,
    };
  },
  data() {
    return {
      redirectRoute: this.$route.fullPath,
    };
  },
  computed: {
    ...mapGetters(['oauthPayload']),
    ...mapRequestStatuses({
      oauthConnectionRequest: 'OAUTH_CONNECT',
    }),
    OAUTH_PROVIDER_CONFIG: () => OAUTH_PROVIDER_CONFIG,
    facebookLogo: () => require('@/assets/logos/facebook.png'),
    instagramLogo: () => require('@/assets/logos/instagram.png'),
    classes() {
      return {
        [this.$style.connector]: true,
        [this.$style.error]: this.showNextStepsError,
      };
    },
    CONNECTION_STATUS: () => CONNECTION_STATUS,
    MARKETING_PLATFORMS: () => MARKETING_PLATFORMS,
    INTEGRATION_TYPES: () => INTEGRATION_TYPES,
  },
  watch: {
    showNextStepsError(newVal) {
      this.$emit('update:showError', newVal);
    },
  },
  async mounted() {
    if (this.oauthPayload?.code) {
      await this.oauthSuccess(this.oauthPayload);
    } else if (this.oauthPayload?.error) {
      this.handleOauthError(this.oauthPayload);
    }
    this.$store.dispatch('SET_OAUTH_PAYLOAD', null);
  },
  methods: {
    async oauthSuccess(payload) {
      await this.$store.dispatch('oauthConnect', payload);
      if (this.oauthConnectionRequest.isError) {
        payload.error = this.oauthConnectionRequest.errorMessage;
        this.handleOauthError(payload);
        return;
      }
      // Account connected
      this.trackAccountConnected(payload.provider, this.eventTrackingFields);
    },
    attemptOauth(platformName, config) {
      this.$refs.oauthConnect.launch(platformName, config);
    },
    attemptFacebookOauth() {
      this.handleTileClick(
        MARKETING_PLATFORMS.FACEBOOK,
        this.eventTrackingFields,
      );
      let config;
      if (this.isMobile()) {
        LocalStorage.setItem(
          LocalStorageKeys.OAUTH_PROVIDER,
          MARKETING_PLATFORMS.FACEBOOK,
        );
        LocalStorage.setItem(
          LocalStorageKeys.OAUTH_REDIRECT_ROUTE,
          this.redirectRoute,
        );
        config = {
          noPopup: true,
          redirectRoute: this.redirectRoute,
        };
      }
      this.attemptOauth(MARKETING_PLATFORMS.FACEBOOK, config);
    },
  },
};
</script>

<style lang="less" module>
.connector {
  max-width: 800px;
  margin: 60px auto 90px;
  text-align: center;
  &.error {
    margin-top: 10px;
  }
  @media only screen and (max-width: 500px) {
    margin: 30px auto 40px;
  }
}
</style>

/* eslint-disable no-param-reassign */
import { UsersApi, UserInvitesApi } from '@clearbanc/baus-sdk';
import Axios from 'axios';

import camelizeKeysDeep from 'camelcase-keys-deep';
import { auth0 } from '@/utils/auth0';
import { ENV } from '../../config/app-env';

export const bausRequest = Axios.create({
  baseURL: ENV.BAUS_API_URL,
  timeout: ENV.API_TIMEOUT,
  headers: {
    'Content-Type': 'application/json',
  },
});

bausRequest.interceptors.request.use(async (config) => {
  config.url = `${ENV.BAUS_API_URL}${config.url}`;
  if (auth0.isAuthenticated.value) {
    const token = await auth0.getAccessTokenSilently();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
  }
  return config;
});

bausRequest.interceptors.response.use((response) => {
  if (
    response.config.url.match(/.*\/v[12]\/.*/) &&
    response.headers['content-type'].indexOf('application/json') === 0
  ) {
    response.data = camelizeKeysDeep(response.data);
  }
  return response;
});

export const usersApi = new UsersApi(
  {
    isJsonMime: () => true,
  },
  undefined,
  bausRequest,
);

export const userInvitesApi = new UserInvitesApi(
  {
    isJsonMime: () => true,
  },
  undefined,
  bausRequest,
);

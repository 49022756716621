<template>
  <div>
    <FormError v-if="showError" :error-message="errorMessage" />
    <FormBusinessDetailsNew
      @validationSuccess="handleValidationSuccess"
      @validationFailure="handleError"
    />
  </div>
</template>

<script>
import { mapStateModuleGetters } from '@/utils/state-modules';
import stateModuleAware from '@/mixins/state-module-aware';
import FormBusinessDetailsNew from '@/components/forms/FormBusinessDetailsNew';
import FormError from '@/components/forms/shared/FormError';

export default {
  components: {
    FormError,
    FormBusinessDetailsNew,
  },
  mixins: [stateModuleAware],
  data() {
    return {
      showError: false,
      errorMessage: null,
    };
  },
  computed: {
    ...mapStateModuleGetters(['steps', 'experimentName']),
    currentStep() {
      return this.steps[this.$route.name];
    },
  },
  methods: {
    handleValidationSuccess() {
      this.dispatchToStateModule('UPDATE_STEP', {
        step: this.currentRoute,
      });
      this.$router.push({ name: this.currentStep.nextRoute.name });
    },
    handleError(error) {
      this.errorMessage = error;
      this.showError = true;
    },
  },
  mounted() {
    this.$store.dispatch('MODIFY_PAYMENT_STEPS');
  },
};
</script>

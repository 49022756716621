<script setup>
import { onMounted, reactive, computed } from 'vue';
import { useForm } from 'vee-validate';
import { yup, validators } from '@clearbanc/validation';
import { useStore } from 'vuex';

import DSButton from '@clearbanc/clear-components/button';
import InputTextGroup from '@clearbanc/clear-components/inputtextgroup';
import DropdownGroup from '@clearbanc/clear-components/dropdowngroup';
import AutoCompleteAddress from '@/components/AutoCompleteAddress';
import CheckboxGroup from '@clearbanc/clear-components/checkboxgroup';

import analytics from '@/utils/analytics';
import statesByCountry from '@/data/states-by-country';
import { COUNTRY_CODES } from '@/data/supported-country-codes';
import { parsePlaceData } from '@/utils/address';
import { getParsedMessage } from '@/data/error-messages';
import {
  stateLabel,
  postalCodeLabel,
  stateOptionsForCountry,
  getStateObject,
  getCountryObject,
} from '@/utils/local';
import {
  countryOptionsWithPriority,
  isCountryWithPostalCode,
} from '@/data/country-code-with-names';

// -----
// Setup
// -----

const store = useStore();
const emits = defineEmits(['goBack', 'validationSuccess', 'validationFailure']);

// ---------
// Variables
// ---------

const countries = countryOptionsWithPriority([
  COUNTRY_CODES.US,
  COUNTRY_CODES.CA,
  COUNTRY_CODES.GB,
]);

const requestStatuses = reactive({
  updateBusiness: computed(() =>
    store.getters.requestStatus('UPDATE_BUSINESS'),
  ),
});

const states = (country) => {
  return statesByCountry[country] || [];
};

// ----------
// Form Logic
// ----------

const validationSchema = yup.object({
  line1: yup.address(),
  country: yup.country(),
  city: yup.city(),
  state: yup.state(),
  postalCode: yup.postalCode(),
  line1Shipping: yup
    .address()
    .requiredWhen(
      'shippingAddressSame',
      (shippingAddressSame) => !shippingAddressSame?.length,
    ),
  countryShipping: yup
    .country()
    .requiredWhen(
      'shippingAddressSame',
      (shippingAddressSame) => !shippingAddressSame?.length,
    ),
  cityShipping: yup
    .string()
    .nullable()
    .requiredWhen(
      'shippingAddressSame',
      (shippingAddressSame) => !shippingAddressSame?.length,
    ),
  stateShipping: yup
    .object()
    .nullable()
    .requiredWhen(
      ['shippingAddressSame', 'countryShipping'],
      (shippingAddressSame, countryShipping) =>
        !shippingAddressSame && stateOptionsForCountry(countryShipping),
    ),
  postalCodeShipping: yup
    .string()
    .nullable()
    .validator(
      'test-shipping-postal-code',
      ['shippingAddressSame', 'countryShipping'],
      (value, shippingAddressSame, countryShipping) => {
        return shippingAddressSame?.length
          ? undefined
          : validators.validatePostalCode(value, countryShipping);
      },
    ),
});

const { values, handleSubmit, setValues, isSubmitting } = useForm({
  validationSchema,
});

const isShippingAddressSame = computed(
  () => !!values.shippingAddressSame?.length,
);

const onSubmit = handleSubmit(async () => {
  const shippingAddress = isShippingAddressSame.value
    ? {
        line1: values.line1,
        line2: values.line2,
        country: values.country?.value,
        state: values.state?.value,
        city: values.city,
        postalCode: values.postalCode,
      }
    : {
        line1: values.line1Shipping,
        line2: values.line2Shipping,
        country: values.countryShipping?.value,
        state: values.stateShipping?.value,
        city: values.cityShipping,
        postalCode: values.postalCodeShipping,
      };

  await store.dispatchApiAction('UPDATE_BUSINESS', {
    address: {
      line1: values.line1,
      line2: values.line2,
      country: values.country?.value,
      state: values.state?.value,
      city: values.city,
      postalCode: values.postalCode,
    },
    shippingAddress,
    shippingAddressSame: isShippingAddressSame.value,
  });

  if (requestStatuses.updateBusiness.isError) {
    emits(
      'validationFailure',
      getParsedMessage(requestStatuses.updateBusiness.error, true),
    );
    return;
  }

  analytics.track('if_business_details_completed');
  emits('validationSuccess');
});

function handleAddressSelected(placeData, suffix = '') {
  const parsedData = parsePlaceData(placeData);

  setValues({
    ...values,
    [`line1${suffix}`]: parsedData.line1,
    [`line2${suffix}`]: parsedData.line2,
    [`city${suffix}`]: parsedData.city,
    [`country${suffix}`]: parsedData.country,
    [`state${suffix}`]: parsedData.state,
    [`postalCode${suffix}`]: parsedData.postalCode,
  });
}

function populateSavedValues() {
  const initialValues = {};

  initialValues.shippingAddressSame = store.getters.business.shippingAddressSame
    ? ['shippingAddressSame']
    : null;

  // Registered Address
  initialValues.line1 = store.getters.business.addressStreet1;
  initialValues.line2 = store.getters.business.addressStreet2;
  initialValues.city = store.getters.business.addressCity;
  initialValues.country = getCountryObject(
    store.getters.business.addressCountryCode,
  );
  initialValues.state = getStateObject(
    store.getters.business.addressState,
    store.getters.business.addressCountryCode,
  );
  initialValues.postalCode = store.getters.business.addressPostalCode;

  // Shipping Address
  initialValues.line1Shipping = store.getters.business.shippingAddressStreet1;
  initialValues.line2Shipping = store.getters.business.shippingAddressStreet2;
  initialValues.cityShipping = store.getters.business.shippingAddressCity;
  initialValues.countryShipping = getCountryObject(
    store.getters.business.shippingAddressCountryCode,
  );
  initialValues.stateShipping = getStateObject(
    store.getters.business.shippingAddressState,
    store.getters.business.shippingAddressCountryCode,
  );
  initialValues.postalCodeShipping =
    store.getters.business.shippingAddressPostalCode;

  setValues(initialValues);
}

// -----------------
// Lifecycle Methods
// -----------------

onMounted(() => {
  populateSavedValues();
});
</script>

<template>
  <form
    class="c-form"
    :class="$style.form"
    :style="{ pointerEvents: isSubmitting ? 'none' : 'all' }"
    @submit="onSubmit"
  >
    <!-- ////////////////// -->
    <!-- Registered Address -->
    <!-- ////////////////// -->

    <div class="c-group">
      <div class="c-group__header">
        <h3 class="c-group__header__title">Registered Address</h3>
      </div>

      <div class="c-form__row grid grid-cols-12">
        <div class="col-span-6 md:col-span-12">
          <AutoCompleteAddress
            name="line1"
            label="Company Address"
            @addressSelected="(place) => handleAddressSelected(place)"
          />
        </div>
        <div class="col-span-6 md:col-span-12">
          <InputTextGroup name="line2" label="Company Address Line 2" />
        </div>
      </div>

      <div class="c-form__row grid grid-cols-12">
        <div class="col-span-6 md:col-span-12">
          <DropdownGroup
            name="country"
            label="Country"
            :options="countries"
            option-label="nameString"
            placeholder="Select"
            append-to="self"
            :filter="true"
            :reset-filter-on-hide="true"
            @change="
              setValues({
                ...values,
                state: null,
                postalCode: null,
              })
            "
          />
        </div>
        <div class="col-span-6 md:col-span-12">
          <InputTextGroup name="city" label="City" />
        </div>
      </div>

      <div class="c-form__row grid grid-cols-12">
        <div
          v-if="stateOptionsForCountry(values.country?.value)"
          class="col-span-6 md:col-span-12"
        >
          <DropdownGroup
            name="state"
            :label="stateLabel(values.country?.value)"
            :options="states(values.country?.value)"
            option-label="nameString"
            placeholder="Select"
            append-to="self"
            :filter="true"
            :reset-filter-on-hide="true"
          />
        </div>
        <div
          v-if="isCountryWithPostalCode(values.country?.value)"
          class="col-span-6 md:col-span-12"
        >
          <InputTextGroup
            name="postalCode"
            :label="postalCodeLabel(values.country?.value)"
          />
        </div>
      </div>
    </div>

    <!-- //////////////// -->
    <!-- Shipping Address -->
    <!-- //////////////// -->

    <div class="c-group">
      <div class="c-group__header">
        <h3 class="c-group__header__title">Mailing Address</h3>
      </div>

      <div class="c-form__row">
        <CheckboxGroup
          name="shippingAddressSame"
          :options="[
            {
              value: 'shippingAddressSame',
              label: 'Same as Registered Address',
            },
          ]"
        />
      </div>

      <template v-if="!isShippingAddressSame" :style="{ display: 'block' }">
        <div class="c-form__row grid grid-cols-12">
          <div class="col-span-6 md:col-span-12">
            <AutoCompleteAddress
              name="line1Shipping"
              label="Company Address"
              @addressSelected="
                (place) => handleAddressSelected(place, 'Shipping')
              "
            />
          </div>
          <div class="col-span-6 md:col-span-12">
            <InputTextGroup
              name="line2Shipping"
              label="Company Address Line 2"
            />
          </div>
        </div>

        <div class="c-form__row grid grid-cols-12">
          <div class="col-span-6 md:col-span-12">
            <DropdownGroup
              name="countryShipping"
              label="Country"
              :options="countries"
              option-label="nameString"
              placeholder="Select"
              append-to="self"
              :filter="true"
              :reset-filter-on-hide="true"
              @change="
                setValues({
                  ...values,
                  stateShipping: null,
                  postalCodeShipping: null,
                })
              "
            />
          </div>
          <div class="col-span-6 md:col-span-12">
            <InputTextGroup name="cityShipping" label="City" />
          </div>
        </div>

        <div class="c-form__row grid grid-cols-12">
          <div
            v-if="stateOptionsForCountry(values.countryShipping?.value)"
            class="col-span-6 md:col-span-12"
          >
            <DropdownGroup
              name="stateShipping"
              :label="stateLabel(values.countryShipping?.value)"
              :options="states(values.countryShipping?.value)"
              option-label="nameString"
              placeholder="Select"
              append-to="self"
              :filter="true"
              :reset-filter-on-hide="true"
            />
          </div>
          <div
            v-if="isCountryWithPostalCode(values.countryShipping?.value)"
            class="col-span-6 md:col-span-12"
          >
            <InputTextGroup
              name="postalCodeShipping"
              :label="postalCodeLabel(values.countryShipping?.value)"
            />
          </div>
        </div>
      </template>
    </div>

    <!-- ///////////// -->
    <!-- Form Controls -->
    <!-- ///////////// -->

    <div class="c-form__controls">
      <DSButton
        label="Back"
        class="p-button-outlined"
        @click="emits('goBack')"
      />
      <DSButton label="Next" type="submit" :loading="isSubmitting" />
    </div>
  </form>
</template>

<style module scoped>
.form {
  margin: 40px 0 0;
}

div[class^='c-form__row']:empty {
  margin: 0;
}
</style>

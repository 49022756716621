import _ from 'lodash';
import { CONNECTION_STATUS } from '@/data/connection-types';
import { buildApiActions } from '@/utils/vuex-api-utils';
import { getExtAccountStatus } from '@/utils/external-accounts';

export default {
  state: {
    externalAccounts: {},
    oauthPayload: null,
  },
  getters: {
    externalAccounts: (state) => _.values(state.externalAccounts),
    connectedAccountingAccounts(state, getters) {
      getters.externalAccounts.filter(
        (account) =>
          account.platformType === 'accounting' &&
          account.state === CONNECTION_STATUS.CONNECTED,
      );
    },
    connectedMarketingAccounts: (state, getters) => {
      if (
        getters.hasConnectedFacebookAccount &&
        getters.hasConnectedGoogleAdAccount &&
        getters.hasConnectedAmazonAdsAccount
      ) {
        return getters.externalAccounts.filter(
          (account) =>
            account.platformType === 'marketing' &&
            account.state === CONNECTION_STATUS.CONNECTED,
        );
      } else if (getters.hasConnectedGoogleAdAccount) {
        return getters.externalAccounts.filter(
          (account) =>
            account.platformType === 'marketing' &&
            account.platform === 'googleads' &&
            account.state === CONNECTION_STATUS.CONNECTED,
        );
      } else if (getters.hasConnectedFacebookAccount) {
        return getters.externalAccounts.filter(
          (account) =>
            account.platformType === 'marketing' &&
            account.platform === 'facebookad' &&
            account.state === CONNECTION_STATUS.CONNECTED,
        );
      } else if (getters.hasConnectedAmazonAdsAccount) {
        return getters.externalAccounts.filter(
          (account) =>
            account.platformType === 'marketing' &&
            account.platform === 'amazonads' &&
            account.state === CONNECTION_STATUS.CONNECTED,
        );
      }
      return [];
    },
    connectedSalesAccounts: (state, getters) =>
      getters.externalAccounts.filter(
        (account) =>
          !account.ignoreRevenueAt &&
          account.platformType === 'sales' &&
          account.state === CONNECTION_STATUS.CONNECTED,
      ),
    disconnectedSalesAccounts: (state, getters) =>
      getters.externalAccounts.filter(
        (account) =>
          !account.ignoreRevenueAt &&
          account.platformType === 'sales' &&
          account.state === CONNECTION_STATUS.DISCONNECTED,
      ),
    hasAllSalesAccountsConnected: (state, getters) => {
      const activeSalesAccounts = (getters.externalAccounts ?? []).filter(
        (account) =>
          !account.ignoreRevenueAt && account.platformType === 'sales',
      );
      return activeSalesAccounts.every(
        (account) =>
          getExtAccountStatus(account, getters.showShopifyFivetranTile) ===
          CONNECTION_STATUS.CONNECTED,
      );
    },
    connectedBillingAccounts(state, getters) {
      const isBillingAccount = (account) =>
        account.platformType === 'recurring_billing' ||
        ['stripe', 'braintree'].includes(account.platform);

      return getters.externalAccounts.filter(
        (account) =>
          isBillingAccount(account) &&
          account.state === CONNECTION_STATUS.CONNECTED,
      );
    },
    billingAccountsConnected: (state, getters) =>
      getters.connectedBillingAccounts.length > 0,
    billingOrManualAccountsConnected(state, getters, rootState, rootGetters) {
      return (
        getters.billingAccountsConnected ||
        rootGetters.manualReadOnlyAccountsConnected
      );
    },
    salesAccounts: (state, getters) =>
      _.filter(getters.externalAccounts, { platformType: 'sales' }),
    marketingAccounts(state, getters) {
      const isMarketingAccount = (account) =>
        account.platformType === 'marketing' || account.platform === 'facebook';
      return getters.externalAccounts.filter(
        (account) => isMarketingAccount(account) && !account.ignoreRevenueAt,
      );
    },
    salesAccountsConnected: (state, getters) =>
      getters.salesAccounts.length > 0,
    marketingAccountsConnected: (state, getters) =>
      getters.marketingAccounts.length > 0,
    hasActiveSalesAccounts: (state, getters) =>
      getters.connectedSalesAccounts.length > 0,
    hasDisconnectedSalesAccounts: (state, getters) =>
      !getters.hasAllSalesAccountsConnected,
    hasActiveMarketingAccounts: (state, getters) =>
      getters.connectedMarketingAccounts.length > 0,
    hasConnectedGoogleAdAccount: (state, getters) =>
      getters.externalAccounts.filter(
        (account) =>
          account.platformType === 'marketing' &&
          account.platform === 'googleads' &&
          account.state === CONNECTION_STATUS.CONNECTED,
      ).length > 0,
    hasConnectedFacebookAccount: (state, getters) =>
      getters.externalAccounts.filter(
        (account) =>
          account.platformType === 'auth' &&
          account.platform === 'facebook' &&
          account.state === CONNECTION_STATUS.CONNECTED,
      ).length > 0,
    hasConnectedAmazonAdsAccount: (state, getters) =>
      getters.externalAccounts.filter(
        (account) =>
          account.platformType === 'marketing' &&
          account.platform === 'amazonads' &&
          account.state === CONNECTION_STATUS.CONNECTED,
      ).length > 0,
    hasConnectedMarketingAccount: (state, getters) =>
      getters.connectedMarketingAccounts.length,
    oauthPayload: (state) => state.oauthPayload,
  },
  ...buildApiActions(
    {
      // fetch all external accounts for a business
      FETCH_USER_EXTERNAL_ACCOUNTS: {
        action: (ctx, payload) => ({
          method: 'get',
          url: `/businesses/${ctx.rootGetters.businessId}/external-accounts`,
        }),
        mutation: (state, { response }) => {
          state.externalAccounts = response.reduce(
            (accumulator, currentValue) => {
              const account = currentValue.account;
              return {
                ...accumulator,
                [account.id]: {
                  ...account,
                },
              };
            },
            {},
          );
        },
      },
      UPDATE_USER_EXTERNAL_ACCOUNT: {
        action: (ctx, payload) => ({
          method: payload.action ? 'post' : 'patch',
          url: [`/external-accounts/${payload.id}`, payload.action].join('/'),
          params: _.omit(payload, 'id', 'action'),
        }),
        mutation: (state, { response }) => {
          state.externalAccounts[response.id] = response;
        },
      },
      ADD_USER_EXTERNAL_ACCOUNT: {
        action: (ctx, payload) => ({
          method: 'post',
          url: `/businesses/${ctx.rootGetters.businessId}/external-accounts`,
          params: {
            ..._.omit(payload, 'id'),
            options: { timeout: 30000 },
          },
        }),
        mutation: (state, { response }) => {
          state.externalAccounts[response.id] = response;
        },
      },
    },
    {
      actions: {
        SET_OAUTH_PAYLOAD: ({ commit }, oauthPayload) => {
          commit('SET_OAUTH_PAYLOAD', oauthPayload);
        },
      },
      mutations: {
        SET_OAUTH_PAYLOAD: (state, oauthPayload) => {
          state.oauthPayload = oauthPayload;
        },
      },
    },
  ),
};

/* eslint-disable no-param-reassign */
import Axios from 'axios';

import camelizeKeysDeep from 'camelcase-keys-deep';
import { auth0 } from '@/utils/auth0';
import { ENV } from '../../config/app-env';

export const beneficiaryRequest = Axios.create({
  timeout: ENV.API_TIMEOUT,
  headers: {
    'Content-Type': 'application/json',
  },
});

beneficiaryRequest.interceptors.request.use(async (config) => {
  // in local dev, use webpack's proxy to call bills api
  config.url = `${ENV.BENEFICIARY_API_URL}${config.url}`;
  const token = await auth0.getAccessTokenSilently();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

beneficiaryRequest.interceptors.response.use((response) => {
  if (
    response.config.url.match(/.*\/v[12]\/.*/) &&
    response.headers['content-type'].indexOf('application/json') === 0
  ) {
    response.data = camelizeKeysDeep(response.data);
  }
  return response;
});

<template>
  <div
    :class="[$style.wrapper, condensedVersion && $style['condensed-version']]"
  >
    <div :class="$style.heading">
      <div :class="$style.heading__left">
        <div v-if="title || titleTag" :class="$style.heading__left__title">
          <h1 v-if="title" :class="$style.heading__left__title__text">
            {{ title }}
          </h1>
          <div v-if="titleTag" :class="[$style.tag, getTagClass]">
            {{ titleTag }}
          </div>
        </div>

        <p
          v-if="!!this.$slots['custom-description']"
          :class="$style.heading__left__description"
        >
          <slot name="custom-description"></slot>
        </p>

        <i18n-t
          v-if="helpParagraph"
          :keypath="helpParagraph"
          data-cy="capacity-error-paragraph"
          tag="p"
          :class="$style.heading__left__description"
        >
          <template v-if="helpLink && helpLinkText" #link>
            <a :href="helpLink" target="_blank">
              <u>
                {{ helpLinkText }}
              </u>
            </a>
          </template>
        </i18n-t>

        <p v-if="description" :class="$style.heading__left__description">
          {{ description }}
          <template v-if="descriptionRenewalInDays"
            >This Funding Capacity will be renewed in
            <span :class="$style['heading__left__description--strong']">{{
              descriptionRenewalInDays
            }}</span>
            days.</template
          ><button
            v-if="descriptionTooltip"
            v-tooltip.focus="descriptionTooltip"
            :class="$style.heading__left__description__tooltip"
            data-cy="capacity-renew-tooltip"
            type="button"
            class="pi pi-info-circle"
            aria-label="Funding Capacity Renew Disclaimer"
          />
        </p>
      </div>
      <div :class="$style.heading__right">
        <DSButton
          v-if="ctaVisible && ctaLabel && ctaClick"
          :class="[$style['cta'], $style['cta--desktop']]"
          :label="ctaLabel"
          :data-cy="getDataCyForCta"
          @click="ctaClick"
        />
      </div>
    </div>

    <p
      v-if="capacityCalculating"
      data-cy="capacity-calculating"
      :class="$style['calculating-capacity']"
    >
      <svg
        :class="$style['calculating-capacity__icon']"
        viewBox="0 0 17 17"
        v-html="require(`@/assets/icons/loading.svg`)"
      ></svg>
      {{ $t('paymentsPage.calculatingCapacityMessage') }}
    </p>

    <div
      v-if="availableCapacity || usedCapacity || totalCapacity"
      :class="$style.amount"
    >
      <div :class="$style.amount__top">
        <p :class="[$style['available'], $style['stacked-mobile']]">
          <span
            >${{ getFormattedAmount(availableCapacity)
            }}<template v-if="disclaimer">*</template></span
          >
          Available
        </p>
        <p :class="[$style['used'], $style['stacked-mobile']]">
          Used
          <span>${{ getFormattedAmount(usedCapacity) }}</span>
        </p>
      </div>

      <div :class="$style.bar">
        <div
          :class="$style.bar__available"
          :style="{
            width: getCapacityPercent(availableCapacity, totalCapacity),
          }"
        ></div>
      </div>

      <p :class="[$style.amount__bottom, $style['total']]">
        Total Funding Capacity
        <span>${{ getFormattedAmount(totalCapacity) }}</span>
      </p>
    </div>

    <p v-if="disclaimer" :class="$style.disclaimer">{{ disclaimer }}</p>

    <CustomLink
      v-if="trackEducationLink"
      :url="DISCLAIMERS.UNDERSTANDING_CAPACITY_LINK"
      text="How Funding Capacity works"
      :class="$style['help-center-link']"
      target="_blank"
      @click="trackEducationLink"
    />

    <DSButton
      v-if="ctaVisible && ctaLabel && ctaClick"
      :class="[$style['cta'], $style['cta--mobile']]"
      :label="ctaLabel"
      :data-cy="getDataCyForCta"
      @click="ctaClick"
    />

    <div
      v-if="connectedAccountsVisible"
      class="connect-accounts-accordion"
      data-cy="capacity-connect-accounts"
    >
      <Accordion :active-index="0">
        <AccordionTab :header="connectedAccountsTitle">
          <LayoutConnectAccounts
            :show-error="connectedAccountsShowError"
            :show-encryption-message="false"
            :event-tracking-fields="connectedAccountsCommonTrackingFields"
          >
            <TileGroupConnectSales
              v-model:show-error="connectedAccountsShowError"
              :event-tracking-fields="connectedAccountsCommonTrackingFields"
              show-only-payments-capacity-connections
            />
          </LayoutConnectAccounts>
        </AccordionTab>
      </Accordion>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import DSButton from '@clearbanc/clear-components/button';
import TileGroupConnectSales from '@/components/TileGroupConnectSales';
import Accordion from '@clearbanc/clear-components/accordion';
import AccordionTab from '@clearbanc/clear-components/accordiontab';
import { LayoutConnectAccounts, CustomLink } from '@/components';
import { currencyCodeByCorpCountry } from '@/utils/currency';
import { DISCLAIMERS } from '@/data/capacity';

export default {
  data() {
    return {
      DISCLAIMERS,
    };
  },
  components: {
    LayoutConnectAccounts,
    DSButton,
    TileGroupConnectSales,
    Accordion,
    AccordionTab,
    CustomLink,
  },
  props: {
    title: {
      type: String,
      required: false,
    },
    titleTag: {
      type: String,
      required: false,
      validator(value) {
        return ['Approved', 'Preliminary', 'Self-Reported'].includes(value);
      },
    },
    description: {
      type: String,
      required: false,
    },
    descriptionRenewalInDays: {
      type: Number,
      required: false,
    },
    descriptionTooltip: {
      type: String,
      required: false,
    },
    ctaVisible: {
      type: Boolean,
      required: false,
      default: true,
    },
    ctaLabel: {
      type: String,
      required: false,
    },
    ctaClick: {
      type: Function,
      required: false,
    },
    availableCapacity: {
      type: Number,
      required: false,
    },
    usedCapacity: {
      type: Number,
      required: false,
    },
    totalCapacity: {
      type: Number,
      required: false,
    },
    trackEducationLink: {
      type: Function,
      required: false,
    },
    connectedAccountsVisible: {
      type: Boolean,
      required: false,
    },
    connectedAccountsTitle: {
      type: String,
      required: false,
    },
    connectedAccountsShowError: {
      type: Boolean,
      required: false,
    },
    connectedAccountsCommonTrackingFields: {
      type: Object,
      required: false,
    },
    helpParagraph: {
      type: String,
      required: false,
    },
    helpLink: {
      type: String,
      required: false,
    },
    helpLinkText: {
      type: String,
      required: false,
    },
    capacityCalculating: {
      type: Boolean,
      required: false,
    },
    disclaimer: {
      type: String,
      required: false,
    },
    condensedVersion: {
      type: Boolean,
      required: false,
    },
  },
  computed: {
    ...mapGetters(['business', 'isBnplWaitlisted', 'userCanSubmitInvoices']),
    getDataCyForCta() {
      if (this.isBnplWaitlisted) {
        return 'join-the-waitlist-button';
      }
      if (this.userCanSubmitInvoices) {
        return 'submit-an-invoice-or-receipt-button';
      }
      return 'finish-application-button';
    },
    getTagClass() {
      return {
        [this.$style['tag--approved']]: this.titleTag === 'Approved',
        [this.$style['tag--preliminary']]: this.titleTag === 'Preliminary',
        [this.$style['tag--self-reported']]: this.titleTag === 'Self-Reported',
      };
    },
    currencyByCorpCountry() {
      return currencyCodeByCorpCountry(this.business.corpCountry);
    },
  },
  methods: {
    toAmountNoDecimal(amountCents) {
      return this.$filters.noDecimal(
        this.$filters.currency(amountCents, this.currencyByCorpCountry),
        this.currencyByCorpCountry,
      );
    },
    getFormattedAmount(cents) {
      return this.removeDollarSign(this.toAmountNoDecimal(cents));
    },
    removeDollarSign(str) {
      return str.replace(/\$/g, '');
    },
    getCapacityPercent(amount, total) {
      return `${(amount / total) * 100}%`;
    },
  },
};
</script>

<style lang="less" scoped>
.connect-accounts-accordion {
  margin-top: 16px;
}

:deep(.connect-accounts-accordion
    .p-accordion
    .p-accordion-header
    .p-accordion-header-link),
:deep(.connect-accounts-accordion
    .p-accordion
    .p-accordion-header:not(.p-disabled).p-highlight
    .p-accordion-header-link) {
  color: #393939;
  background: transparent !important;
  padding-left: 0;
  padding-right: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 0;
  border-radius: 0 !important;
}

:deep(.connect-accounts-accordion .p-accordion .p-accordion-content) {
  padding: 0;
  border: 0;
  border-radius: 0;
}
</style>

<style lang="less" module>
.wrapper {
  background: @white;
  border: 1px solid @color-grey-200;
  padding: 40px;
  border-radius: 4px;
  margin-bottom: 24px;
}

.heading {
  display: flex;
  justify-content: space-between;
}

.heading__left__title {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .tag {
    order: -1;
    margin: 0 0 8px 0;
  }
}

.wrapper h1.heading__left__title__text {
  font-family: 'Gerstner-Programm', 'Helvetica', 'Segoe UI', 'sans-serif';
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.4;
  margin: 0;
}

.heading__left__description {
  margin: 7px 0 0 0;
  color: @color-grey-400;
}

.heading__left__description--strong {
  font-weight: 500;
}

.heading__left__description__tooltip {
  margin-left: 8px;
}

.heading__right .cta {
  margin: 0;
  white-space: nowrap;
}

.tag {
  padding: 4px 8px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 1;
  font-family: 'Gerstner-Programm', 'Helvetica', 'Segoe UI', 'sans-serif';
  color: @color-success-500;
  background: @color-success-100;
  border-radius: 4px;
  display: inline-block;
}

.tag--approved {
  color: @color-success-500;
  background: @color-success-100;
}

.tag--preliminary {
  color: @color-info-500;
  background: @color-info-100;
}

.tag--self-reported {
  color: @color-warning-400;
  background: @color-warning-100;
}

.amount {
  margin-top: 24px;
}

.amount__top {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.available,
.used {
  font-size: 12px;
}

.available {
  margin: 0;
  line-height: 1;
  display: flex;
  flex-direction: column;
}

.available span {
  font-size: 28px;
  font-weight: bold;
  color: @black;
  display: inline-block;
  margin: 0 0 8px 0;
}

.used,
.total {
  margin: 0;
  display: flex;
  line-height: 1;
}

.used span,
.total span {
  font-weight: 500;
  color: @black;
  text-align: right;
}

.used {
  flex-direction: column;
  text-align: right;
}

.used span {
  order: -1;
  margin: 0 0 4px 0;
}

.total {
  font-size: 14px;
}

.total span {
  margin: 0 0 0 4px;
}

.bar {
  height: 16px;
  border-radius: 8px;
  background: @color-grey-200;
  margin: 24px 0 0 0;
  overflow: hidden;
}

.bar__available {
  height: 16px;
  width: 50%;
  background: @color-cc-purple-200;
  position: relative;
}

.bar__available:before {
  content: '';
  position: absolute;
  top: 0;
  left: 100%;
  width: 2px;
  height: 16px;
  background: @white;
}

.amount__bottom {
  margin-top: 24px;
}

.help-center-link {
  color: @color-cc-purple-200;
  text-decoration: underline;
  margin-top: 24px;
  display: inline-block;
}

button.cta {
  display: block;
}

button.cta--desktop {
  display: none;
}

button.cta--mobile {
  margin: 24px 0 0 0;
}

.calculating-capacity {
  display: flex;
  color: @lighter-black;
  margin: 0;
  border-top: 1px dashed rgba(0, 0, 0, 0.15);
  display: inline-block;
  margin-top: 6px;
  padding-top: 6px;
}

.calculating-capacity__icon {
  width: 17px;
  margin-right: 5px;
  display: inline;
  animation: spin 0.5s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.disclaimer {
  margin: 0;
  font-size: 14px;
  line-height: 1.2;
  color: @lighter-black;
  margin-top: 10px;
}

@media (min-width: 768px) {
  .heading__left__title {
    flex-direction: row;
    align-items: center;

    .tag {
      order: 0;
      margin: 0 0 0 8px;
    }
  }

  .available span {
    font-size: 36px;
  }
}

@media (min-width: 1100px) {
  .amount {
    margin-top: 32px;
  }

  .heading__left {
    margin-right: 50px;
  }

  .available,
  .used {
    font-size: 16px;
  }

  .available {
    flex-direction: row;
    align-items: flex-end;
    flex-direction: row;
  }

  .available span {
    font-size: 48px;
    margin: 0 8px 0 0;
    transform: translateY(6px);
  }

  .used {
    flex-direction: row;
  }

  .used span {
    order: 0;
    margin: 0 0 0 8px;
  }

  .total {
    font-size: 16px;
  }

  .total span {
    margin: 0 0 0 8px;
  }

  button.cta--mobile {
    display: none;
  }

  button.cta--desktop {
    display: block;
  }

  .help-center-link {
    margin-top: 40px;
  }
}

.condensed-version {
  padding: 16px 24px 24px 24px;
  margin: 0;

  h1.heading__left__title__text {
    font-size: 16px;
  }

  .amount {
    margin-top: 0;
  }

  .amount__top {
    margin-top: 16px;
  }

  .calculating-capacity,
  .available,
  .used,
  .total {
    font-size: 14px;
  }

  .available {
    margin-right: 20px;
  }

  .available span {
    font-size: 24px;
    transform: none;
  }

  .bar {
    margin-top: 16px;
  }

  .bar,
  .bar__available,
  .bar__available:before {
    height: 12px;
  }

  .amount__bottom {
    margin-top: 16px;
  }

  .disclaimer {
    margin-top: 16px;
  }
}
</style>

<script setup>
defineProps({
  url: { type: String, required: true },
  text: { type: String, default: '' },
  target: { type: String, default: '_self' },
  title: { type: String, default: '' },
});

const emit = defineEmits(['click']);

function handleClick(event) {
  event.stopImmediatePropagation();
  emit('click');
}
</script>

<template>
  <a
    :href="url"
    :class="$attrs.class || $style.link"
    :target="target"
    :title="title"
    @click="handleClick"
  >
    <slot>{{ text }}</slot>
  </a>
</template>

<style lang="less" module>
.link {
  text-decoration: underline;
  color: @color-black;
  cursor: pointer;
}
</style>

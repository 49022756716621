import {
  isSalesAccountConnected,
  isAccountingAccountConnected,
} from '@/utils/external-accounts';
import { isProfileComplete } from '@/store/modules/profile-module';
import {
  isCountryWithPostalCode,
  isQualifiedNorthAmericanCountry,
} from '@/data/country-code-with-names';
import { ONBOARDING_2_ROUTES } from '@/data/onboarding';
import { PRODUCT_SEGMENT } from '@/data/product-segments';
import { BANK_ERROR_STATUSES } from '@/data/bank-connection';
import { LocalStorage, LocalStorageKeys } from '@/utils/local-storage';
/**
 * The following helper functions assist in confirming whether specific groups of data have been
 * gathered from the user in order to determine at current stage of the onboarding flow. and may impact
 * routing, component rendering among others. These functions are used by onboarding-2 flow
 *
 * NOTE: when editing existing functions, ensure to test all components/modules that utilize the functions
 * and confirm with each respective pod that the changes are expected and approrpiate.
 */

export function isBasicUserDetailsStepComplete(user) {
  return !!(user.firstName && user.lastName && user.phoneNumber);
}

export function isBasicBusinessDetailsStepComplete(business) {
  return !!(
    business.name &&
    business.website &&
    business.vertical &&
    // eslint-disable-next-line camelcase
    business.qualifyingQuestions?.monthly_revenue
  );
}

// OFFERS
export function isOffersStepComplete(advanceInNegotiation, business) {
  if (business.productSegment.label === PRODUCT_SEGMENT.BNPL) {
    return true;
  }
  const { offerOptions } = advanceInNegotiation || {
    offerOptions: [],
  };

  if (!offerOptions || !offerOptions.length) {
    return false;
  }

  return offerOptions.some((offer) => !!offer.selectedAt);
}

// CONNECT ACCOUNTS
export function isConnectSalesAccountsStepComplete(externalAccounts) {
  return isSalesAccountConnected(externalAccounts);
}

// BANKING

export function isPlaidConnected(rootGetters) {
  return (
    (rootGetters.bankAccounts.length > 0 &&
      !rootGetters.isPrimaryBankAccountDisconnected) ||
    rootGetters.primaryBankAccountAuthorizedGoCardless
  );
}

export function isConnectBankingStepComplete(rootGetters) {
  return isPlaidConnected(rootGetters) || rootGetters.hasUploadedBankDocs;
}

export function isBankLinkConfirmationStepComplete(rootGetters) {
  return (
    (rootGetters.bankAccounts.some(
      (bankAccount) =>
        bankAccount.details?.setAsPrimary &&
        (!BANK_ERROR_STATUSES.includes(
          bankAccount?.connection?.plaidStatus?.summary,
        ) ||
          rootGetters.businessOnPlaidBypass),
    ) &&
      !rootGetters.isReorderBankingStepsEnabled) ||
    (rootGetters.primaryBankAccount &&
      (!rootGetters.isPrimaryBankAccountDisconnected ||
        rootGetters.businessOnPlaidBypass))
  );
}

export function isUploadBankStatementsStepComplete(rootGetters) {
  const bankAccount =
    rootGetters.primaryBankAccount ?? rootGetters.currentBankAccountToBePrimary;
  if (!bankAccount) return false;
  // TODO: sc-175570 keep only the hasRecentStatementData and remove the rest
  if (rootGetters.isHeronPdfAutomationEnabled) {
    if (rootGetters.isHeronDiligenceChecksEnabled) {
      return rootGetters.pdfStatementsCompletionDetails.isComplete;
    }
    return true;
  }
  // Check dates are set in file uploads table for each bank statement related to that account
  const bankUploads = rootGetters.uploadsMatching(
    rootGetters.bankUploadMatcher,
  );
  if (!bankUploads?.length) return false;
  const completedPrimaryUploads = bankUploads.filter(
    (upload) =>
      upload.metaId === `${bankAccount.id}` &&
      upload.startDate &&
      upload.endDate,
  );
  const incompletedPrimaryUploads = bankUploads.filter(
    (upload) =>
      upload.metaId === `${bankAccount.id}` &&
      (!upload.startDate || !upload.endDate),
  );
  return !!(
    completedPrimaryUploads.length && !incompletedPrimaryUploads.length
  );
}

// BUSINESS DETAILS
function getRequiredAddressFields(business) {
  if (business.shippingAddressSame) return [];

  const addressFields = [
    'addressStreet1',
    'addressCity',
    'addressState',
    'addressCountryCode',
  ];

  const shippingFields = [
    'shippingAddressStreet1',
    'shippingAddressCity',
    'shippingAddressState',
    'shippingAddressCountryCode',
  ];

  if (isCountryWithPostalCode(business.shippingAddressCountryCode))
    shippingFields.push('shippingAddressPostalCode');

  if (isCountryWithPostalCode(business.addressCountryCode))
    addressFields.push('addressPostalCode');

  return business.shippingAddressSame
    ? addressFields
    : addressFields.concat(shippingFields);
}

export function isBusinessDetailsStepComplete(business) {
  const requiredFields = ['name', 'phone'];

  const addressFields = getRequiredAddressFields(business);

  const allRequiredFields = requiredFields.concat(addressFields);

  return allRequiredFields.every((field) => !!business[field]);
}

export function isBizProfileBusinessDetailsStepComplete(business, rootGetters) {
  const requiredFields = ['name', 'phone', 'federalTaxId', 'corpDate'];
  const requiredUploads = [];

  // Require incorporation docs upload
  requiredUploads.push(rootGetters.incorporationUploadMatcher);

  const requiredAddressFields = getRequiredAddressFields(business);

  const allRequiredFields = requiredFields.concat(requiredAddressFields);

  const requiredFieldsComplete = allRequiredFields.every(
    (field) => !!business[field],
  );

  const requiredUploadsComplete = requiredUploads.length
    ? requiredUploads.every((uploadParams) => {
        return !!rootGetters.uploadsMatching({
          ...uploadParams,
          ...(rootGetters.isDiligenceServiceReadDataEnabled
            ? { externalId: business.id }
            : { businessId: business.id }),
        }).length;
      })
    : true;

  return requiredFieldsComplete && requiredUploadsComplete;
}

export function isBusinessDetailsComplete(business) {
  return !!(
    business.corpCountry &&
    business.corpState &&
    business.corpType &&
    business.name
  );
}

export function isRegisteredAddressStepComplete(business) {
  const shippingFields = [
    'shippingAddressStreet1',
    'shippingAddressCity',
    'shippingAddressState',
    'shippingAddressCountryCode',
  ];
  const requiredFields = [
    'phone',
    'addressStreet1',
    'addressCity',
    'addressState',
    'addressCountryCode',
  ];
  if (isCountryWithPostalCode(business.shippingAddressCountryCode))
    shippingFields.push('shippingAddressPostalCode');
  if (isCountryWithPostalCode(business.addressCountryCode))
    requiredFields.push('addressPostalCode');
  const allRequiredFields = requiredFields.concat(
    business.shippingAddressSame ? [] : shippingFields,
  );
  return allRequiredFields.every((field) => !!business[field]);
}

export function isBusinessFormationDocsStepComplete(rootGetters) {
  return !!(
    rootGetters.business.registrationNumber &&
    rootGetters.business.corpDate &&
    rootGetters.uploadsMatching(rootGetters.einUploadMatcher)
  );
}

// OWNERS

export function isOwnersStepComplete(
  owners,
  isPrimaryUserInfoComplete,
  isOwnerInfoComplete,
) {
  return (
    isPrimaryUserInfoComplete ||
    owners.some((owner) => isOwnerInfoComplete(owner.id))
  );
}

export function isOwnersListStepComplete(user, authorizedSignatory) {
  return !!authorizedSignatory && !!user?.agreedToKyc;
}

export function hasOwnerVerificationDocsBeenUploaded(uploads, ownerId) {
  return uploads.find((file) => file.metaId === ownerId.toString());
}

export function findIncompleteOwnerStepOne(allOwners, uploads) {
  return allOwners.find(
    (owner) =>
      !owner.firstName ||
      !owner.lastName ||
      !hasOwnerVerificationDocsBeenUploaded(uploads, owner.id),
  );
}

export function isOwnersStepOneComplete(allOwners, uploads) {
  return !!(
    allOwners.length && !findIncompleteOwnerStepOne(allOwners, uploads)
  );
}

export function findIncompleteOwner(allOwners) {
  return allOwners.find((owner) => {
    return !isProfileComplete(owner);
  });
}

export function isOwnersComplete(getters) {
  return (
    getters.isPrimaryUserInfoComplete &&
    !findIncompleteOwner(getters.allBusinessProfiles) &&
    !!getters.hasSignatory
  );
}

// CONTRACT

export function isBankContractStepComplete(rootGetters) {
  return (
    !isQualifiedNorthAmericanCountry(rootGetters.business.corpCountry) ||
    rootGetters.isPrimaryBankAccountContractSigned ||
    (rootGetters.hasUploadedBankDocs &&
      (rootGetters.isBankAccountToBePrimaryObfuscatingData ||
        !isPlaidConnected(rootGetters)))
  );
}

export function isAdvanceContractStepComplete(rootGetters) {
  return (
    rootGetters.isAdvanceContractSigned ||
    (!rootGetters.isAuthorizedSignatory &&
      isBankContractStepComplete(rootGetters))
  );
}

// STEP SUMMARY

export function isMissedStepsStepComplete(missedSteps) {
  return !(missedSteps?.length > 0);
}

export function isStepSkippedInLocalStorage(stepName) {
  return !!LocalStorage.getItem(LocalStorageKeys.SKIPPED_STEP_NAME(stepName));
}

export function wasMarketingStepSkipped(getters) {
  return (
    isStepSkippedInLocalStorage(ONBOARDING_2_ROUTES.CONNECT_MARKETING) ||
    isAccountingAccountConnected(getters.externalAccounts) ||
    isPlaidConnected(getters) ||
    getters.hasUploadedBankDocs ||
    isBusinessDetailsComplete(getters.business)
  );
}

export function wasAccountingStepSkipped(getters) {
  return (
    isStepSkippedInLocalStorage(ONBOARDING_2_ROUTES.CONNECT_ACCOUNTING) ||
    isPlaidConnected(getters) ||
    getters.hasUploadedBankDocs ||
    isBusinessDetailsComplete(getters.business)
  );
}

export function wasPlaidConnectStepSkipped(getters) {
  return (
    isStepSkippedInLocalStorage(ONBOARDING_2_ROUTES.CONNECT_BANKING) ||
    getters.hasUploadedBankDocs ||
    isBusinessDetailsComplete(getters.business)
  );
}

export function wasUploadBankStatementsStepSkipped(getters) {
  return (
    isStepSkippedInLocalStorage(ONBOARDING_2_ROUTES.BANK_FAILED_UPLOAD_DOCS) ||
    isBusinessDetailsComplete(getters.business)
  );
}

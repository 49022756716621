// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".uploadZone_G0tqH{height:100%!important;width:100%!important;margin:20px}.uploadZone_G0tqH .upload-list_HfnyY{display:none!important}.uploadZone_G0tqH .dropzone_O0lhh{height:90%!important;width:90%!important;vertical-align:bottom!important;margin:5%;border:1px dashed #69c1bc!important}.uploadZone_G0tqH .dropzone_O0lhh .dz-message_sh4k2{font-size:20px!important;font-family:Montserrat,sans-serif;font-style:normal!important;color:#000;white-space:pre-wrap!important;margin:auto}.uploadZone_G0tqH .dropzone_O0lhh.dz-clickable_eQr_V{display:flex;align-items:center;justify-content:center}.trashButton_MwTVD{width:.5em}.trashButton_MwTVD>*{text-align:center}.uploadSuccess_DJ6wE{border:solid;border-color:#69c1bc;border-radius:5px;border-width:2px;padding:10px;margin-bottom:auto 20px;width:100%}.document-column_ihp9S{color:#000;width:260px;font-weight:400}@media only screen and (max-width:960px){.document-column_ihp9S{width:140px}}@media only screen and (max-width:670px){.document-column_ihp9S{display:inline-block;width:-webkit-max-content;width:-moz-max-content;width:max-content}}.trash-column_elplr{width:30px}@media only screen and (max-width:960px){.trash-column_elplr{width:25px}}@media only screen and (max-width:670px){.trash-column_elplr{display:inline-block;width:-webkit-max-content;width:-moz-max-content;width:max-content}}.subtitle_m0loV{color:gray;font-size:12px;text-align:left;width:60%}@media screen and (max-width:600px){.subtitle_m0loV{width:100%;text-align:center}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"uploadZone": "uploadZone_G0tqH",
	"upload-list": "upload-list_HfnyY",
	"dropzone": "dropzone_O0lhh",
	"dz-message": "dz-message_sh4k2",
	"dz-clickable": "dz-clickable_eQr_V",
	"trashButton": "trashButton_MwTVD",
	"uploadSuccess": "uploadSuccess_DJ6wE",
	"document-column": "document-column_ihp9S",
	"trash-column": "trash-column_elplr",
	"subtitle": "subtitle_m0loV"
};
export default ___CSS_LOADER_EXPORT___;

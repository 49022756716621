import { onBeforeMount } from 'vue';
import { useStore } from 'vuex';
import analytics from '@/utils/analytics';
import { LocalStorage, LocalStorageKeys } from '@/utils/local-storage';

export default function sendUtmParamsToSegment() {
  onBeforeMount(async () => {
    const store = useStore();

    const signupParams =
      LocalStorage.getItem(LocalStorageKeys.SIGNUP_PARAMS) || {};

    if (signupParams?.utm_campaign) {
      const utmParams = {
        utmSource: signupParams.utm_source,
        utmMedium: signupParams.utm_medium,
        utmCampaign: signupParams.utm_campaign,
        utmContent: signupParams.utm_content,
      };

      analytics.identify(store.getters.userId, utmParams);

      LocalStorage.removeItem(LocalStorageKeys.SIGNUP_PARAMS);
    }
  });
}

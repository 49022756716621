<template>
  <div
    v-if="!isLoading && visibleBankAccounts?.length"
    :class="$style['account-list']"
  >
    <DataTable
      v-model:filters="filters"
      v-model:expandedRows="expandedRows"
      :value="groupedBankAccounts"
      :always-show-paginator="false"
      :rows="20"
      :sort-order="-1"
      responsive-layout="scroll"
      :global-filter-fields="['bankName', 'accounts']"
    >
      <template #empty>No Bank Accounts Found.</template>

      <template #header>
        <div :class="$style.heading">
          <DSButton label="Add Bank Account" @click="addBankAccountClick" />

          <div :class="$style.search">
            <InputText
              v-model="filters['global'].value"
              :class="$style.search__input"
              placeholder="Search"
            />
            <i class="pi pi-search" :class="$style.search__icon" />
          </div>
        </div>
      </template>

      <Column expander :style="{ width: '32px', paddingRight: '0' }" />

      <Column field="bankName" header="Bank Account">
        <template #body="slotProps">
          <div :class="$style['bank-label']">
            <BankAccountLogo
              :class="$style['bank-label__logo']"
              :image="slotProps.data.logo"
              :bank-name="slotProps.data.bankName"
            />
            <span :class="$style['bank-label__name']">
              {{ slotProps.data.bankName }}
            </span>
          </div>
        </template>
      </Column>

      <Column field="" header="Status">
        <template #body="slotProps">
          <Tag
            v-if="anyAccountIsInConnectingState(slotProps.data.accounts)"
            severity="info"
            icon="pi pi-spin pi-spinner"
            value="Importing Account"
          ></Tag>

          <Tag
            v-else-if="
              areStatmentsRequiredForManualAccount(slotProps.data.accounts)
            "
            severity="warning"
            value="Statements Required"
          ></Tag>

          <Tag
            v-else-if="institutionConnectionHasError(slotProps.data)"
            severity="danger"
            value="Not Connected"
          ></Tag>

          <Tag v-else severity="success" value="Connected"></Tag>
        </template>
      </Column>

      <Column field="" header="">
        <template #body="slotProps">
          <DSButton
            v-if="institutionConnectionHasError(slotProps.data)"
            class="p-button-outlined"
            label="Reauthorize Connection"
            @click="reauthorizePreEmp(slotProps.data.accounts)"
          />

          <DSButton
            v-else-if="
              areStatmentsRequiredForManualAccount(slotProps.data.accounts)
            "
            class="p-button-outlined"
            label="Upload Statement"
            @click="
              this.$router.push({
                name: PAYMENTS_ROUTE_NAMES.PROFILE_BANK_STATEMENTS,
                params: { id: slotProps.data.accounts[0].id },
              })
            "
          />
        </template>
      </Column>

      <template #expansion="slotProps">
        <div
          v-for="account in slotProps.data.accounts"
          :class="$style['account-row-wrapper']"
        >
          <div :class="$style['account-row']">
            <div :class="$style['account-row__item']">
              <span :class="$style['account-row__item__label']">{{
                account.label
              }}</span>
              <span :class="$style['account-row__item__number']">
                *** ***
                {{ account.accountNumberLast4 }}
              </span>
              <Tag
                v-if="account.id === intendedPrimaryOrPrimaryBankAccount?.id"
                :class="$style['account-row__item__tag']"
                :style="{ background: '#F3DFFF', color: '#820EC6' }"
                severity="success"
                value="Primary"
              ></Tag>
              <Tag
                v-if="
                  !hasSufficientPlaidData(account) &&
                  !isAccountInConnectingState(account) &&
                  !institutionConnectionHasError(slotProps.data)
                "
                :class="$style['account-row__item__tag']"
                severity="danger"
                value="Insufficient Data"
              ></Tag>
            </div>
            <div>
              <DSButton
                icon="pi pi-ellipsis-h"
                class="p-button-rounded p-button-secondary p-button-text"
                aria-haspopup="true"
                aria-controls="overlay_menu"
                data-cy="account-options-button"
                @click="toggle(account, $event)"
              />
              <DSMenu
                :id="`overlay_menu-${account.id}`"
                :ref="`menu-${account.id}`"
                :model="getAccountMenuItems(account)"
                :popup="true"
              />
            </div>

            <Message
              v-if="
                userHasEverHadActiveAdvance &&
                !isContractSigned(account) &&
                canSignContract(account) &&
                account.id === selectedAccount?.id
              "
              severity="info"
              :closable="false"
              :class="[$style.message, $style['message--small']]"
            >
              <h3>Sign ACH required</h3>
              <p>
                You are required to sign a debit authorization agreement
                allowing Clearco to debit your weekly payments from this
                account.
              </p>
              <a
                v-if="isAuthorizedSignatory"
                @click.once="signContract($event, account)"
              >
                Sign ACH Agreement
              </a>
              <p v-else-if="hasContract(account)">ACH emailed to signatory.</p>
              <a v-else @click.once="openOwnersPage()">
                You must identify an authorized signatory
              </a>
            </Message>
          </div>
        </div>
      </template>
    </DataTable>
  </div>

  <ChangePrimaryBankAccountModal
    ref="changePrimaryBankAccountModal"
    :selected-account="selectedAccount"
    :bank-account-id-to-be-primary="bankAccountIdToBePrimary"
    @close="closeChangePrimaryBankAccountModal"
  />

  <RenameBankAccountModal
    ref="renameBankAccountModal"
    :selected-account="selectedAccount"
    @close="selectedAccount = null"
  />

  <SetPrimaryBankAccountModal
    ref="setPrimaryBankAccountModal"
    :bank-accounts="bankAccountsForSetPrimaryModal"
  />

  <PrimaryNotIncludedModal
    ref="PrimaryNotIncludedModal"
    @reauthorize="reauthorize"
  />

  <ReauthorizePreEmpModal
    ref="ReauthorizePreEmpModal"
    @confirm="reconnectClick()"
  />

  <RemoveBankAccountModal ref="removeBankAccountModal" />

  <AddBankAccountModal
    ref="addBankAccountModal"
    :plaid-banks="plaidBanksAvailableForRelink"
    @connect-new="connectNew"
    @connect-existing="connectExisting"
  />
</template>

<script>
import { mapGetters } from 'vuex';
import * as Sentry from '@sentry/vue';
import { FilterMatchMode } from 'primevue/api';
import InputText from '@clearbanc/clear-components/inputtext';
import DataTable from '@clearbanc/clear-components/datatable';
import Column from '@clearbanc/clear-components/column';
import DSButton from '@clearbanc/clear-components/button';
import DSMenu from '@clearbanc/clear-components/menu';
import Tag from '@clearbanc/clear-components/tag';
import Message from '@clearbanc/clear-components/message';
import { useContextRoot } from '@/utils/context-root';
import contractUtils from '@/composables/contracts';
import {
  BANK_CONNECTION_STATUS_SUMMARY,
  BANK_ERROR_STATUSES,
  BANK_CONNECTION_ACTIONS,
} from '@/data/bank-connection';
import { CONNECTION_STATUS } from '@/data/connection-types';
import ChangePrimaryBankAccountModal from '@/components/ChangePrimaryBankAccountModal';
import BankAccountLogo from '@/components/BankAccountLogo';
import RenameBankAccountModal from '@/components/RenameBankAccountModal';
import SetPrimaryBankAccountModal from '@/components/SetPrimaryBankAccountModal';
import bankAccountUtils from '@/composables/bank-accounts';
import RemoveBankAccountModal from '@/components/RemoveBankAccountModal';
import { PAYMENTS_ROUTE_NAMES } from '@/data/payments';
import AddBankAccountModal from '@/components/AddBankAccountModal';
import ReauthorizePreEmpModal from '@/components/ReauthorizePreEmpModal';
import PrimaryNotIncludedModal from '@/components/PrimaryNotIncludedModal';

export default {
  emits: [
    'relink',
    'update:bankAccountIdToBePrimary',
    'update-primary',
    'show-action-required-modal',
  ],
  components: {
    PrimaryNotIncludedModal,
    ReauthorizePreEmpModal,
    DataTable,
    Column,
    InputText,
    DSButton,
    DSMenu,
    Tag,
    Message,
    ChangePrimaryBankAccountModal,
    BankAccountLogo,
    RenameBankAccountModal,
    SetPrimaryBankAccountModal,
    RemoveBankAccountModal,
    AddBankAccountModal,
  },
  props: {
    bankAccountIdToBePrimary: {
      required: true,
      validator: (prop) => typeof prop === 'number' || prop === null,
    },
  },
  setup(props, context) {
    const ctxRoot = useContextRoot();
    const { signAchContract } = contractUtils(context, ctxRoot);
    const { markBankAccountToBecomePrimary } = bankAccountUtils(
      useContextRoot(),
    );
    return { signAchContract, markBankAccountToBecomePrimary };
  },
  data() {
    return {
      filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      groupedBankAccounts: [],
      expandedRows: [],
      BANK_CONNECTION_STATUS_SUMMARY,
      BANK_ERROR_STATUSES,
      BANK_CONNECTION_ACTIONS,
      CONNECTION_STATUS,
      selectedAccount: null,
      PAYMENTS_ROUTE_NAMES,
      isLoading: true,
      bankAccountsForSetPrimaryModal: {},
      reconnectAccounts: [],
      reconnectingAccount: null,
    };
  },
  computed: {
    ...mapGetters([
      'primaryBankAccount',
      'currentBankAccountToBePrimary',
      'billContracts',
      'businessOnPlaidBypass',
      'allBankAccountChecks',
      'visibleBankAccounts',
      'bankAccountContracts',
      'isAuthorizedSignatory',
      'intendedPrimaryOrPrimaryBankAccount',
      'statementsRequired',
      'userHasEverHadActiveAdvance',
      'visibleBankAccountsWithPassedPreFlightChecks',
    ]),
    redirectedFromDocusign() {
      return this.$route.query.event === 'signing_complete';
    },
    bankAccountIdInUrl() {
      return Number(this.$route.query.bankAccountId);
    },
    plaidBanksAvailableForRelink() {
      const bankAccounts = this.groupedBankAccounts.filter((bank) => {
        const accountToTest = bank?.accounts[0];
        const plaidStatusAction =
          accountToTest?.connection?.plaidStatus?.action;
        const plaidConnectionStatus =
          accountToTest?.connection?.plaidStatus?.summary;

        if (!bank.isPlaid) {
          return false;
        }

        if (plaidConnectionStatus === this.CONNECTION_STATUS.CONNECTED) {
          return true;
        }

        if (plaidStatusAction === this.BANK_CONNECTION_ACTIONS.RELINK) {
          return true;
        }

        return false;
      });

      return bankAccounts;
    },
  },
  async mounted() {
    await this.updateBankAccountChecks();
    this.prepBankingData();
    if (this.intendedPrimaryOrPrimaryBankAccount) {
      this.verifyPrimaryBankAccount();
    } else {
      this.checkIfUserNeedsToSetPrimary();
    }

    this.isLoading = false;
  },
  methods: {
    toggle(account, event) {
      this.$refs[`menu-${account.id}`][0].toggle(event);
    },
    accountCanBecomePrimary(account) {
      if (this.businessOnPlaidBypass) return true;

      const accountPassedPreFlightChecks =
        this.visibleBankAccountsWithPassedPreFlightChecks.some(
          (bankAccount) => bankAccount.id === account.id,
        );
      const accountIsInInitialScrapeState =
        account?.connection?.plaidStatus?.summary ===
        this.BANK_CONNECTION_STATUS_SUMMARY.INITIAL_SCRAPE;

      return accountPassedPreFlightChecks || accountIsInInitialScrapeState;
    },
    getAccountMenuItems(account) {
      const accountIsPrimary =
        account.id === this.intendedPrimaryOrPrimaryBankAccount?.id;
      const menuItems = [
        {
          label: 'Rename account',
          command: () => {
            this.selectedAccount = account;
            this.$refs.renameBankAccountModal.openModal();
          },
        },
      ];

      if (!accountIsPrimary) {
        if (this.accountCanBecomePrimary(account)) {
          menuItems.unshift({
            label: 'Make it primary',
            command: () => {
              this.selectedAccount = account;

              const achNeedsSigning =
                this.userHasEverHadActiveAdvance &&
                !this.isContractSigned(account) &&
                this.canSignContract(account);

              if (achNeedsSigning) {
                this.$emit('show-action-required-modal', account);
              } else {
                this.$refs.changePrimaryBankAccountModal.openModal();
              }
            },
          });
        }

        menuItems.push({
          label: 'Remove account',
          command: () => {
            this.$refs.removeBankAccountModal.openModal(account);
          },
          class: ['red-menu-item'],
        });
      }

      return menuItems;
    },
    groupBankAccounts() {
      const groupedBankAccounts = this.visibleBankAccounts.reduce(
        (acc, bankAccount) => {
          const { connection } = bankAccount ?? {};
          const key = connection?.id ?? bankAccount.bankName;

          const bankGroup = acc[key] ?? {
            bankName: connection?.institutionName ?? bankAccount.bankName,
            institutionId: connection?.institutionId,
            isPlaid: !!connection?.id,
            connection,
            logo: null,
            accounts: [],
          };

          return {
            ...acc,
            [key]: {
              ...bankGroup,
              accounts: [...bankGroup.accounts, bankAccount],
            },
          };
        },
        {},
      );

      // Ordering the groups with the one having the primary account at the top of the list
      let groupedArray = Object.values(groupedBankAccounts);
      groupedArray = this.reorderArray(groupedArray, (group) =>
        group.accounts.some(
          (account) =>
            account.id === this.intendedPrimaryOrPrimaryBankAccount?.id,
        ),
      );

      // Ordering the accounts with the primary account at the top of the group
      groupedArray = groupedArray.map((group) => ({
        ...group,
        accounts: this.reorderArray(
          group.accounts,
          (account) =>
            account.id === this.intendedPrimaryOrPrimaryBankAccount?.id,
        ),
      }));

      // Adding a unique number to the bank name if there are multiple accounts with the same bank name
      const uniqueGroupedBankAccounts =
        this.getBanksWithUniqueNames(groupedArray);

      this.groupedBankAccounts = uniqueGroupedBankAccounts;
      this.expandedRows = uniqueGroupedBankAccounts;
    },
    reorderArray(array, condition) {
      const index = array.findIndex(condition);
      if (index > -1) {
        const [item] = array.splice(index, 1);
        array.unshift(item);
      }
      return array;
    },
    getBanksWithUniqueNames(originalArray) {
      const bankNameCount = {};
      return originalArray.map((bankGroup) => {
        let uniqueBankName = bankGroup.bankName;
        if (bankNameCount[uniqueBankName]) {
          uniqueBankName = `${bankGroup.bankName}(${bankNameCount[uniqueBankName]})`;
          bankNameCount[bankGroup.bankName]++;
        } else {
          bankNameCount[bankGroup.bankName] = 1;
        }
        return {
          ...bankGroup,
          bankName: uniqueBankName,
        };
      });
    },
    getAllInstitutionDetails() {
      this.groupedBankAccounts.forEach(async (bank, index) => {
        if (bank.isPlaid && bank.institutionId) {
          try {
            const response =
              (await this.$store.dispatchApiAction('POST_PLAID_PROXY', {
                method: 'institutionsGetById',
                institution_id: bank.institutionId,
                options: {
                  include_optional_metadata: true,
                },
              })) ?? {};

            const { institution } = response;

            if (institution) {
              this.groupedBankAccounts[index].logo = institution.logo;
            }
          } catch (error) {
            Sentry.captureMessage(
              `Failed to get institution details for bank ${bank}, error: ${error}`,
              'warning',
            );
          }
        }
      });
    },
    isContractSigned(account) {
      const combinedBankAccountAndBillContract = this.billContracts.find(
        (billContract) => billContract.bankAccountsId === account.id,
      );
      return (
        !!this.bankAccountContracts[account.id]?.userSignedAt ||
        !!combinedBankAccountAndBillContract?.userSignedAt
      );
    },
    canSignContract(account) {
      return !!(account.accountNumber && account.routingNumber);
    },
    openOwnersPage() {
      this.$router.push({
        name: PAYMENTS_ROUTE_NAMES.PROFILE_OWNERS_LIST,
      });
    },
    async signContract(e, account) {
      e.stopPropagation();
      await this.signAchContract(account.id);
    },
    hasContract(account) {
      return !!this.bankAccountContracts[account.id];
    },
    findBankWithActiveConnectionBasedOnName(bankName) {
      if (bankName) {
        return this.visibleBankAccounts.find((account) => {
          const isSameBank =
            account.bankConnectionId &&
            account?.connection?.institutionName === bankName;

          const action = account?.connection?.plaidStatus?.action;

          const isActive = !action;
          const isRelinkable = action === this.BANK_CONNECTION_ACTIONS.RELINK;

          return isSameBank && (isActive || isRelinkable);
        });
      }

      return null;
    },
    reauthorizePreEmp(accounts) {
      this.$refs.ReauthorizePreEmpModal.openModal();
      this.reconnectAccounts = accounts;
    },
    reconnectClick() {
      const { action, account } = this.getReauthorizationDetails(
        this.reconnectAccounts,
      );
      this.reconnectingAccount = account;
      let matchingBankWithConnectionId;
      if (!account.bankConnectionId) {
        matchingBankWithConnectionId =
          this.findBankWithActiveConnectionBasedOnName(account?.bankName);
      }

      if (
        action === this.BANK_CONNECTION_ACTIONS.RELINK ||
        matchingBankWithConnectionId
      ) {
        this.$emit('relink', matchingBankWithConnectionId || account);
      } else {
        this.$emit('connect-new', account.routingNumber);
      }
    },
    reauthorize() {
      if (this.reconnectingAccount) {
        this.$emit('relink', this.reconnectingAccount);
      }
    },
    getReauthorizationDetails(accounts) {
      let actionToPerform;
      let accountForAction;

      for (const account of accounts) {
        const plaidStatusAction = account?.connection?.plaidStatus?.action;

        if (plaidStatusAction === this.BANK_CONNECTION_ACTIONS.RELINK) {
          actionToPerform = plaidStatusAction;
          accountForAction = account;
          break;
        }
      }

      if (!actionToPerform) {
        return {
          action: this.BANK_CONNECTION_ACTIONS.CONNECT_NEW,
          account: accounts[0],
        };
      }

      return {
        action: actionToPerform,
        account: accountForAction,
      };
    },
    institutionConnectionHasError(data) {
      const isPlaidConnection = data.isPlaid;

      if (!isPlaidConnection && !this.businessOnPlaidBypass) {
        return true;
      }

      return !!data.connection?.plaidStatus?.action;
    },
    haveConnectedAccounts(accounts) {
      return accounts.some((account) => {
        const status = account?.connection?.plaidStatus?.summary;

        return (
          status === this.BANK_CONNECTION_STATUS_SUMMARY.CONNECTED &&
          this.allBankAccountChecks[account.id]?.allChecksPass
        );
      });
    },
    prepBankingData() {
      this.groupBankAccounts();
      this.getAllInstitutionDetails();
    },
    closeChangePrimaryBankAccountModal() {
      this.$emit('update:bankAccountIdToBePrimary', this.selectedAccount.id);
      this.$emit('update-primary');
      this.selectedAccount = null;
    },
    async updateBankAccountChecks() {
      const promises = [];

      Object.keys(this.visibleBankAccounts).forEach((key) => {
        const item = this.visibleBankAccounts[key];

        promises.push(
          this.$store.dispatchApiAction('GET_BANK_ACCOUNT_CHECKS', {
            businessId: item.businessId,
            bankAccountId: item.id,
          }),
        );
      });
      await Promise.all(promises);
    },
    verifyPrimaryBankAccount() {
      if (!this.userHasEverHadActiveAdvance) {
        return this.setBankAccountIdToBePrimary(
          this.currentBankAccountToBePrimary?.id || this.primaryBankAccount?.id,
        );
      }

      if (!this.visibleBankAccounts.length) {
        return undefined;
      }

      if (this.bankAccountIdInUrl) {
        this.setSelectedAccount(this.bankAccountIdInUrl);

        return (
          this.redirectedFromDocusign &&
          this.closeChangePrimaryBankAccountModal()
        );
      }

      return this.setBankAccountIdToBePrimary(this.primaryBankAccount?.id);
    },
    setBankAccountIdToBePrimary(id) {
      this.setSelectedAccount(id);
      this.$emit('update:bankAccountIdToBePrimary', id);
    },
    setSelectedAccount(id) {
      this.selectedAccount = this.visibleBankAccounts.find(
        (account) => account.id === id,
      );
    },
    isManualAccountConnection(accounts) {
      const plaidBankConnectionId = accounts[0]?.bankConnectionId;

      if (plaidBankConnectionId) {
        return false;
      }

      return true;
    },
    checkIfUserNeedsToSetPrimary() {
      // Users on plaid bypass don't need to pass pre-flight checks to select primary BA
      const bankAccounts = this.businessOnPlaidBypass
        ? this.visibleBankAccounts
        : this.visibleBankAccountsWithPassedPreFlightChecks;
      const numBankAccounts = bankAccounts.length;

      if (this.intendedPrimaryOrPrimaryBankAccount || !numBankAccounts) {
        this.bankAccountsForSetPrimaryModal = {};
        this.$refs.setPrimaryBankAccountModal.closeModal();
        return;
      }

      if (numBankAccounts === 1) {
        this.markBankAccountToBecomePrimary(bankAccounts[0].id);
      } else {
        this.bankAccountsForSetPrimaryModal = bankAccounts;
        this.$refs.setPrimaryBankAccountModal.openModal();
      }
    },
    checkIfUserNeedsToIncludePrimary() {
      const metadataAccounts = this.$store.getters.plaidMetadata?.accounts;
      const primaryIsOnReauthorizingConnection = this.reconnectAccounts.some(
        (account) =>
          account.id === this.intendedPrimaryOrPrimaryBankAccount?.id,
      );
      const primaryIsOnPlaidMetadata = metadataAccounts?.some(
        (account) =>
          account.mask ===
          this.intendedPrimaryOrPrimaryBankAccount.accountNumberLast4,
      );

      if (
        metadataAccounts.length > 0 &&
        primaryIsOnReauthorizingConnection &&
        !primaryIsOnPlaidMetadata
      ) {
        this.$refs.PrimaryNotIncludedModal.openModal();
      }
    },
    areStatmentsRequiredForManualAccount(accounts) {
      return (
        this.intendedPrimaryOrPrimaryBankAccount?.id === accounts[0]?.id &&
        this.statementsRequired &&
        this.isManualAccountConnection(accounts)
      );
    },
    connectNew() {
      this.$emit('connect-new');
    },
    connectExisting(account) {
      this.reconnectingAccount = account;
      this.reconnectAccounts = [account];
      this.$emit('relink', account);
    },
    addBankAccountClick() {
      if (this.businessOnPlaidBypass) {
        return this.connectNew();
      }
      if (this.plaidBanksAvailableForRelink.length) {
        return this.$refs.addBankAccountModal.openModal();
      }

      return this.connectNew();
    },
    hasSufficientPlaidData(account) {
      const isPlaidAccount = account.bankConnectionId;

      if (isPlaidAccount && !this.businessOnPlaidBypass) {
        return this.allBankAccountChecks[account.id]?.allChecksPass;
      }

      return true;
    },
    isAccountInConnectingState(account) {
      return [
        this.BANK_CONNECTION_STATUS_SUMMARY.CONNECTING,
        this.BANK_CONNECTION_STATUS_SUMMARY.INITIAL_SCRAPE,
        this.BANK_CONNECTION_STATUS_SUMMARY.SCRAPING,
      ].includes(account?.connection?.plaidStatus?.summary);
    },
    anyAccountIsInConnectingState(accounts) {
      return accounts.some((account) =>
        this.isAccountInConnectingState(account),
      );
    },
  },
  watch: {
    async visibleBankAccounts(oldVal, newVal) {
      if (newVal !== oldVal) {
        await this.updateBankAccountChecks();
        this.prepBankingData();
        this.checkIfUserNeedsToSetPrimary();
        this.checkIfUserNeedsToIncludePrimary();
      }
    },
  },
};
</script>

<style>
body .red-menu-item .p-menuitem-link .p-menuitem-text,
body .red-menu-item .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #bd3e3e;
}
</style>

<style lang="less" module>
.account-list {
  margin-bottom: 40px;
}

.account-list [class^='p-tag-value'],
.account-list [class^='p-column-title'] {
  font-size: 14px;
  white-space: nowrap;
}

.account-list [class^='p-button'] {
  white-space: nowrap;
}

body
  [class^='p-datatable']
  [class^='p-datatable-tbody']
  [class^='p-datatable-row-expansion']
  td {
  padding: 0;
}

.account-list [class^='p-datatable-header'] {
  padding: 0 0 16px 0;
}

.heading {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.heading [class^='p-inputtext'] {
  width: auto;
}

.search {
  position: relative;
  margin-top: 16px;
}

input.search__input {
  padding-left: 44px;
}

.search__icon {
  position: absolute;
  top: 50%;
  left: 18px;
  transform: translateY(-50%);
}

.search__icon::before {
  color: #303032;
}

@media (min-width: 768px) {
  .account-list [class^='p-datatable-header'] {
    padding: 0 0 40px 0;
  }

  .heading {
    flex-direction: row;
    justify-content: space-between;
  }

  .heading [class^='p-inputtext'] {
    width: auto;
  }

  .search {
    margin-top: 0;
  }
}

.bank-label {
  display: flex;
  align-items: center;
}

.bank-label__logo {
  margin-right: 8px;
}

.bank-label__name {
  font-weight: 500;
  white-space: nowrap;
}

.account-row-wrapper {
  position: relative;
  padding-left: 52px;
  transition: background 0.2s;
  overflow: hidden;
}

.account-row-wrapper:before {
  content: '';
  position: absolute;
  top: 0;
  left: -1rem;
  width: calc(100% + 2rem);
  height: 1px;
  background: #ceced0;
}

.account-row-wrapper:first-child:before {
  display: none;
}

.account-row-wrapper > [class^='p-message'] {
  margin: 0.8rem 0 0 0;
}

.account-row-wrapper + .account-row-wrapper {
  padding-top: 1px;
}

.account-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  position: relative;
}

.account-row-wrapper:first-child .account-row {
  margin-top: 0;
}

.account-row-wrapper:last-child .account-row {
  margin-bottom: 0;
}

.account-row__item {
  font-family: 'Gerstner-Programm';
  font-size: 14px;
  font-style: normal;
  line-height: 1.5;
  white-space: nowrap;
}

.account-row__item__label {
  color: #303032;
  font-weight: 500;
  margin-right: 16px;
}

.account-row__item__number {
  color: #5f5f61;
  font-weight: 300;
  margin-right: 16px;
}

.account-row__item__tag + .account-row__item__tag {
  margin-left: 8px;
}

.message {
  cursor: default;
}

.message a {
  cursor: pointer;
}

body .message--small h3 {
  margin-top: 2px;
}

body .message--small h3,
body .message--small p,
body .message--small a {
  font-size: 14px;
}
</style>

import i18n from '@/plugins/i18n';
import { ENV } from '../../config/app-env';

export const ContactSupportMethods = {
  supportHours: {
    display: '9AM-6PM ET',
  },
  phone: {
    display: '+1 (415) 610-5166',
    href: 'tel:+14156105166',
  },
  email: {
    display: 'support@clear.co',
    href: 'mailto:support@clear.co',
  },
  helpCenter: {
    display: () => i18n.t('common.helpCenter'),
    href: ENV.HELP_CENTER_URL,
  },
};

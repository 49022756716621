import { addMinutes, isAfter } from 'date-fns';

export const LocalStorageKeys = {
  AMPLITUDE_ID: 'amplitude-id',
  BUSINESS: 'business',
  CANCELLATION_BANNER: 'cancellation-banner',
  CB_HIDE_SUCCESS_BANNER: 'cb-hide-success-banner',
  CB_USER_ID: 'cb-user-id',
  CB_VERIFICATION_HASH: 'cb-verification-hash',
  DISMISSED_CAPACITY_APPROVED_MODAL: 'dismissed-capacity-approved-modal',
  DISMISSED_REFERRAL_MODAL: 'dismissed-referral-modal',
  HIDE_PHOENIX_INTRO_BANNER: 'hide-phoenix-intro-banner',
  LANGUAGE: 'language',
  LAST_PAGE_BEFORE_OAUTH: 'last-page-before-oauth',
  MESSAGE_TAX: 'message-tax',
  NEW_USER: 'new-user',
  OAUTH_SIGNUP: 'oauth-signup',
  OAUTH_PROVIDER: 'oauth-provider',
  OAUTH_REDIRECT_ROUTE: 'oauth-redirect-route',
  PAGINATION_LIMIT: 'pagination-limit',
  PERSONAL_INFORMATION: 'personal-information',
  PLAID_ERROR_CODE: 'plaid-error-code',
  PLAID_LINK_TOKEN: 'plaid-link-token',
  PLAID_REDIRECT_URI: 'plaid-redirect-uri',
  PREVIOUS_ROUTE: 'previous-route',
  REFERRAL_MODAL_DISMISSED: 'referral-modal-dismissed',
  ROOT: 'root',
  SALES_REP_NAME: 'sales-rep-name',
  SIGNUP_PARAMS: 'signup-params',
  SKIPPED_STEP_NAME: (stepName) => `${stepName}-skipped`,
  SHOPIFY_OAUTH_PAYLOAD: 'shopify-oauth-payload',
  VIEWED_PRELIMINARY_CAPACITY_MODAL: 'viewed-preliminary-capacity-modal',
};

/**
 * LocalStorage utility for storing and retrieving data with optional expiration.
 * Example usage:
 *
 * LocalStorage.setItem('key', 'value', 60); // Expires in 60 minutes
 * LocalStorage.setItem('keyTwo', 'valueTwo'); // No expiration
 * LocalStorage.getItem('key'); // Returns 'value' if not expired
 * LocalStorage.removeItem('key'); // Removes the item from storage
 */
export const LocalStorage = {
  /**
   * Sets an item in local storage with optional expiration
   *
   * @param {string} key - The storage key
   * @param {*} value - The value to store
   * @param {number} [expiration] - Optional expiration time in minutes
   */
  setItem(key, value, expiration) {
    const data = {
      value,
      expiration: expiration ? addMinutes(new Date(), expiration) : null,
    };
    localStorage.setItem(key, JSON.stringify(data));
  },

  /**
   * Retrieves an item from local storage.
   * If expiration is set, checks if it's expired and returns null if so.
   *
   * @param {string} key - The storage key.
   * @returns {*} The stored data or null if expired/missing.
   */
  getItem(key) {
    const item = localStorage.getItem(key);
    if (!item) return null;

    const { value, expiration } = JSON.parse(item);

    // Remove the item if it's expired
    if (expiration && isAfter(new Date(), expiration)) {
      localStorage.removeItem(key);
      return null;
    }

    return value;
  },

  /**
   * Removes an item from local storage.
   *
   * @param {string} key - The storage key.
   */
  removeItem(key) {
    localStorage.removeItem(key);
  },

  clear() {
    localStorage.clear();
  },
};

<template>
  <div>
    <FormOwners
      :selected-owner="isRoutesV2Enabled ? user : selectedOwner"
      :business-corp-country="business.corpCountry"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import stateModuleAware from '@/mixins/state-module-aware';
import FormOwners from '@/components/forms/FormOwners';

export default {
  components: {
    FormOwners,
  },
  mixins: [stateModuleAware],
  props: {
    theme: { type: String, default: 'black' },
  },
  computed: {
    ...mapGetters(['business', 'user', 'selectedOwner', 'isRoutesV2Enabled']),
  },
};
</script>

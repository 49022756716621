<template>
  <div :class="$style['desktop-menu']">
    <template v-if="!userIsLoggedIn">
      <a :href="addLocaleParam(clearbancURL + '/faq')">{{
        $t('common.faq')
      }}</a>
      <a :href="addLocaleParam(clearbancURL + '/partners')">{{
        $t('components.navBar.partners')
      }}</a>
      <a href="/">{{ $t('common.buttons.logIn') }}</a>
      <!-- Anchor tag used intentionally inplace of router-link -->
      <!-- Page must reload to trigger cloudflare worker in order to obtain ip country -->
      <a :href="'payments/onboarding/signup'" aria-label="sign up link"
        ><ui-a
          :class="$style['signup-link']"
          secondary
          transparent
          :text="$t('components.navBar.signMeUp')"
      /></a>
    </template>
  </div>
</template>

<script>
import { UiA } from 'ui-components';
import { mapGetters } from 'vuex';
import addLocaleParam from '@/utils/add-locale-param';
import { ENV } from '../../config/app-env';

export default {
  components: {
    UiA,
  },
  computed: {
    ...mapGetters(['userIsLoggedIn']),
    clearbancURL() {
      return ENV.CLEARCOM_URL;
    },
  },
  methods: {
    addLocaleParam,
  },
};
</script>

<style lang="less" module>
.desktop-menu {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-end;
  padding: @gutter-40;

  a {
    color: @color-black;
    letter-spacing: 1px;
    text-decoration: none;
    margin: 0 20px;
    line-height: 20px;

    span {
      font-size: 10px;
      font-family: Montserrat;
      margin-top: -10px;
    }

    span.recommendations-nav-sub {
      position: absolute;
      bottom: 18px;
      font-size: 10px;
      color: @another-gray;
    }

    &:hover,
    &:focus {
      color: @color-black;
      text-decoration: underline;
    }

    &.signup-link {
      letter-spacing: 1px;
      line-height: 50px;

      &:hover,
      &:focus {
        color: @color-teal;
        text-decoration: none;
      }
    }
  }

  .banking-nav {
    min-width: 85px;
    text-align: center;

    .banking-nav-sub {
      color: @color-cc-purple-200;
      font-size: 10px;
    }
  }
}
</style>

/* eslint-disable no-param-reassign */
import Axios from 'axios';

import camelizeKeysDeep from 'camelcase-keys-deep';
import { auth0 } from '@/utils/auth0';
import { ENV } from '../../config/app-env';

const api = Axios.create({
  timeout: ENV.API_TIMEOUT,
  headers: {
    'Content-Type': 'application/json',
  },
});
window.api = api; // useful for dev

api.interceptors.request.use(async (config) => {
  config.url = `${ENV.NODE_API_URL}${config.url}`;
  const token = await auth0.getAccessTokenSilently();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});
api.interceptors.response.use((response) => {
  if (
    response.config.url.match(/.*\/v[12]\/.*/) &&
    response.headers['content-type'].indexOf('application/json') === 0
  ) {
    response.data = camelizeKeysDeep(response.data);
  }
  return response;
});

export default api;

export function convertBase64ToFileURL(
  base64String,
  mimeType = 'application/pdf',
) {
  const binaryString = atob(base64String.split(',')[1]);
  const blob = new Blob(
    [
      new Uint8Array(
        Array.from(binaryString).map((char) => char.charCodeAt(0)),
      ),
    ],
    {
      type: mimeType,
    },
  );
  return URL.createObjectURL(blob);
}

<template>
  <Modal
    v-model:visible="showModal"
    :header="$t('components.connectAmazonsPapi.connectAmazonSellerAccount')"
    :modal="true"
    :draggable="false"
    data-cy="modal-amazon"
    append-to="self"
    @show="$emit('modalAction', SALES_PLATFORMS.AMAZON, 'open')"
    @hide="$emit('modalAction', SALES_PLATFORMS.AMAZON, 'close')"
  >
    <div :class="$style['modal-content-container']">
      <template v-if="!showRegionSelection">
        <p>
          {{
            $t('components.connectAmazonsPapi.marketplaceChange', {
              regionLabel: region.label(),
            })
          }}
          <DSButton
            :label="$t('components.connectAmazonsPapi.change')"
            class="p-button-link"
            :class="$style['button-link']"
            @click="showRegionSelectionScreen"
          />
        </p>
        <p>
          {{ $t('components.connectAmazonsPapi.loginToAuthorize') }}
        </p>
        <button type="button" @click="handleSubmit">
          <img
            :src="handleAmazonIcon"
            :alt="$t('components.connectAmazonsPapi.loginWithAmazon')"
          />
        </button>
        <Message
          v-if="!!errorMessage"
          severity="error"
          :closable="true"
          @close="errorMessage = ''"
        >
          <p>{{ errorMessage }}</p>
          <p>
            {{ $t('components.connectAmazonsPapi.ifIssuePersists') }}
            <a href="mailto:support@clear.co" target="_blank">
              support@clear.co
            </a>
          </p>
        </Message>
      </template>
      <template v-else>
        <p :class="$style['region-selection-heading']">
          {{ $t('components.connectAmazonsPapi.changeYourMarketplace') }}
        </p>
        <dropdown-with-eyebrow
          v-model="amazonAuth.market"
          :options="amazonCountryOptions"
          :placeholder="$t('components.connectAmazonsPapi.amazonMarketplace')"
          name="market"
          :class="$style.dropdown"
          required
          @input="trackMarketSelected"
        />
        <DSButton
          :label="$t('common.select')"
          class="p-button-secondary"
          :class="$style['region-button']"
          @click="verifyInput"
        />
      </template>
    </div>
  </Modal>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  amazonCountryOptions,
  getAmazonCountryMapping,
  getAmazonSPAPIConsentUrl,
} from '@/utils/external-accounts';
import { mapRequestStatuses } from '@/utils/vuex-api-utils';
import {
  validateAmazonSellerMixin,
  copyTextMixin,
  isMobileMixin,
} from '@/utils/vue-mixins';
import { validateChildren } from '@/composables/validation';
import stateModuleAware from '@/mixins/state-module-aware';
import analytics from '@/utils/analytics';
import { COUNTRY_CODES } from '@/data/supported-country-codes';
import { SALES_PLATFORMS } from '@/data/platforms';
import DropdownWithEyebrow from '@/components/DropdownWithEyebrow';
import DSButton from '@clearbanc/clear-components/button';
import Modal from '@clearbanc/clear-components/dialog';
import Message from '@clearbanc/clear-components/message';

export default {
  components: {
    DSButton,
    Message,
    Modal,
    DropdownWithEyebrow,
  },
  mixins: [
    validateAmazonSellerMixin,
    copyTextMixin,
    isMobileMixin,
    stateModuleAware,
  ],
  props: {
    corpCountry: String,
  },
  setup() {
    const { hasError } = validateChildren();
    return {
      hasError,
    };
  },
  data() {
    return {
      errorMessage: '',
      amazonAuth: {
        market: '',
      },
      showModal: false,
      showRegionSelection: false,
      validateOnBlur: false,
    };
  },
  computed: {
    ...mapRequestStatuses({
      addExternalAccountsRequest: 'ADD_USER_EXTERNAL_ACCOUNT',
    }),
    ...mapGetters(['productSegmentLabel']),
    handleAmazonIcon() {
      return require('@/assets/icons/amazon-login-en-us.png');
    },
    amazonCountryOptions: () => amazonCountryOptions,
    SALES_PLATFORMS: () => SALES_PLATFORMS,
    region() {
      return getAmazonCountryMapping(this.amazonAuth.market);
    },
    consentUrl() {
      return getAmazonSPAPIConsentUrl(this.amazonAuth.market);
    },
  },
  mounted() {
    this.setDefaultMarket();
  },
  methods: {
    open() {
      this.errorMessage = '';
      this.showRegionSelection = false;
      this.showModal = true;
    },
    showRegionSelectionScreen() {
      analytics.track('fe_amazon_spapi_marketplace_change_click', {
        productSegment: this.productSegmentLabel,
        exeriment: this.defaultConfig?.experiments?.modals,
      });
      this.amazonAuth.market = '';
      this.errorMessage = '';
      this.showRegionSelection = true;
    },
    async verifyInput() {
      const error = await this.hasError();
      if (error) {
        return;
      }
      analytics.track('fe_amazon_spapi_region_picker_confirmed', {
        productSegment: this.productSegmentLabel,
        experiment: this.defaultConfig?.experiments?.modals,
        marketplaceRegion: this.amazonAuth.market,
      });
      this.showRegionSelection = false;
    },
    trackMarketSelected(val) {
      analytics.track('fe_amazon_spapi_region_picker_selected', {
        productSegment: this.productSegmentLabel,
        experiment: this.defaultConfig?.experiments?.modals,
        marketplaceRegion: val,
      });
    },
    trackTextEntered(inputFieldObject) {
      analytics.track('fe_amazon_spapi_text_entered', {
        textField: inputFieldObject.name,
        productSegment: this.productSegmentLabel,
        experiment: this.defaultConfig?.experiments?.modals,
      });
    },
    setDefaultMarket() {
      let market = 'NA';
      if (this.corpCountry === COUNTRY_CODES.GB) {
        market = 'GB';
      } else if (this.corpCountry === COUNTRY_CODES.AU) {
        market = 'AU';
      }
      this.amazonAuth.market = market;
    },
    handleSubmit() {
      this.$emit('submit', this.consentUrl);
      analytics.track('fe_amazon_spapi_seller_link_click', {
        productSegment: this.productSegmentLabel,
        experiment: this.defaultConfig?.experiments?.modals,
      });
      this.showModal = false;
    },
  },
};
</script>

<style lang="less" module>
.modal-content-container {
  text-align: left;
  max-width: 560px;
  margin: 0 auto;
  color: @medium-gray;

  p {
    margin-top: 0;
    margin-bottom: 1rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  button.button-link {
    padding: 0 4px;
  }

  button.region-button {
    display: block;
    margin: 0 auto;
  }

  .dropdown {
    margin: 1rem auto 2rem;
  }

  @media only screen and (min-width: 501px) {
    .region-selection-heading {
      text-align: center;
    }

    .dropdown {
      max-width: 335px;
    }
  }
}
</style>

<template>
  <div>
    <FormOwners
      :selected-owner="selectedOwner"
      :business-corp-country="business.corpCountry"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import stateModuleAware from '@/mixins/state-module-aware';
import FormOwners from '@/components/forms/FormOwners';

export default {
  components: {
    FormOwners,
  },
  mixins: [stateModuleAware],
  computed: {
    ...mapGetters(['business', 'selectedOwner']),
  },
};
</script>

<template>
  <ui-modal ref="wooCommercePopup" :title="title" :class="$style['modal']">
    <oauth-connect
      ref="oauthConnect"
      :provider-config="OAUTH_PROVIDER_CONFIG"
      @code="oauthSuccess"
    />
    <p :class="$style['paragraph']">
      {{ paragraph }}
    </p>
    <div :class="$style['logos-list']">
      <ui-list-item
        v-for="platform in paymentPlatforms"
        :key="platform.id"
        :class="platform.class"
        :logo="platform.logo"
        :success="getExtAccStatus(platform.id) === CONNECTION_STATUS.CONNECTED"
        :custom-msg="connectedMessage(platform.id)"
        :error="errors[platform.id]"
        @click="$refs.oauthConnect.launch(platform.id)"
      />
    </div>
    <ui-button
      :text="$t('common.buttons.continue')"
      :class="$style.button"
      @click="close"
    />
  </ui-modal>
</template>

<script>
import { UiModal, UiListItem } from 'ui-components';
import { mapGetters } from 'vuex';
import _ from 'lodash';
import { mapRequestStatuses } from '@/utils/vuex-api-utils';
import { OAUTH_PROVIDER_CONFIG } from '@/data/oauth-provider-config';
import { CONNECTION_STATUS } from '@/data/connection-types';
import analytics from '@/utils/analytics';
import { validateChildren } from '@/composables/validation';
import UiButton from '@/components/mca/ui-button-ss';

export default {
  components: {
    UiModal,
    UiListItem,
    UiButton,
    'oauth-connect': require('@/components/OauthConnect').default,
  },
  setup() {
    const { hasError } = validateChildren();
    return {
      hasError,
    };
  },
  data() {
    return {
      errors: {},
      success: -1,
      userId: null,
    };
  },
  computed: {
    ...mapGetters(['externalAccounts']),
    ...mapRequestStatuses({
      fetchUserExternalAccountsRequest: 'FETCH_USER_EXTERNAL_ACCOUNTS',
      oauthConnectionRequest: 'OAUTH_CONNECT',
    }),
    authorizenet: () => require('@/assets/logos/authorize@2x.png'),
    braintree: () => require('@/assets/logos/braintree@2x.png'),
    paypal: () => require('@/assets/logos/pay-pal@2x.png'),
    square: () => require('@/assets/logos/square@2x.png'),
    stripe: () => require('@/assets/logos/stripe@2x.png'),
    OAUTH_PROVIDER_CONFIG: () => OAUTH_PROVIDER_CONFIG,
    allPaymentPlatforms() {
      return [
        { id: 'stripe', logo: this.stripe, class: 'first-connector' },
        { id: 'square', logo: this.square },
        { id: 'authorizenet', logo: this.authorizenet },
        { id: 'paypal', logo: this.paypal },
        { id: 'braintree', logo: this.braintree },
      ];
    },
    paymentPlatforms() {
      const wooAccount = this.externalAccounts.filter(
        (account) => account.id === `woocommerce+${this.userId}`,
      )[0];
      if (!wooAccount) return [];
      const enabledGateways = wooAccount.supportedPaymentMethods;
      if (enabledGateways) {
        return this.allPaymentPlatforms.filter((gateway) =>
          enabledGateways.includes(gateway.id),
        );
      }
      return [];
    },
    title() {
      if (this.success === -1) {
        return this.$t('common.buttons.loading');
      } else if (this.paymentPlatforms.length) {
        return this.$t('common.buttons.next');
      } else if (this.success === 0) {
        return this.$t(
          'components.connectPaymentWooCommerce.authenticationFailed',
        );
      }
      return this.$t(
        'components.connectPaymentWooCommerce.paymentGatewaysUnsupported',
      );
    },
    paragraph() {
      if (this.success === -1) {
        return '';
      } else if (this.paymentPlatforms.length) {
        const paymentConnectionHint = this.$t(
          'components.connectPaymentWooCommerce.basedOnYourConfig',
        );
        if (this.success) {
          return this.$t(
            'components.connectPaymentWooCommerce.thankYouForConnecting',
            { paymentConnectionHint },
          );
        }
        // If user choose to Deny when connect a connected account
        return paymentConnectionHint;
      } else if (this.success === 0) {
        return this.$t('components.connectPaymentWooCommerce.unableToConnect');
      }
      return this.$t(
        'components.connectPaymentWooCommerce.unableToSupportPaymentGateway',
      );
    },
    CONNECTION_STATUS: () => CONNECTION_STATUS,
  },
  async mounted() {
    if (this.userId) {
      await this.$store.dispatchApiAction('FETCH_USER_EXTERNAL_ACCOUNTS');
    }
  },
  methods: {
    async oauthSuccess(payload) {
      await this.$store.dispatch('oauthConnect', payload);
      if (this.oauthConnectionRequest.isError) return;
      analytics.track(`fe_sales_account_linked`, {
        fb: true,
        platform: payload.provider,
      });
    },
    /**
     * https://woocommerce.github.io/woocommerce-rest-api-docs/#rest-api-keys
     * @param payload: parameters that WooCommerce Authentication end points send to our return_url
     *  success: sends 0 if the user denied, or 1 if authenticated successfully.
     *  user_id: the user_id we send to authentication end point. It is to identify the user when redirected back to the (return_url)
     * @returns Open popup to let user connect to payment platforms accounts that supported by us and enabled in WooCommerce store
     */
    async open(payload) {
      this.success = Number(payload.success);
      const storeUrl = payload.user_id.split(' ')[1];
      if (storeUrl.indexOf('https://') === 0) {
        this.userId = `${storeUrl.split('https://')[1]}`;
      } else {
        this.userId = `${storeUrl.split('http://')[1]}`;
      }
      await this.$store.dispatchApiAction('FETCH_USER_EXTERNAL_ACCOUNTS');
      this.$refs.wooCommercePopup.open();
    },
    close() {
      this.$refs.wooCommercePopup.close();
    },
    getExtAccStatus(acc) {
      const account = this.externalAccounts.find((ext) => ext.id.includes(acc));
      return account && account.state;
    },
    connectedMessage(platform) {
      const numAccounts = _.filter(this.externalAccounts, {
        state: CONNECTION_STATUS.CONNECTED,
        platform,
      }).length;
      if (numAccounts) {
        return numAccounts > 1
          ? this.$t(
              'components.connectPaymentWooCommerce.connectedNumAccounts',
              { numAccounts },
            )
          : this.$t('components.connectPaymentWooCommerce.connected');
      }
      return '';
    },
  },
};
</script>
<style lang="less" module>
.first-connector {
  border-top: 1px solid #dadada;
}
.logos-list {
  text-align: left;
  padding: 5px @gutter-10;
  margin: @gutter-40 @gutter-20;
}
.modal {
  font-family: Montserrat, sans-serif;
  div {
    font-family: Montserrat, sans-serif;
  }
  div div {
    text-transform: unset;
  }
  .paragraph {
    font-weight: 500;
    font-size: @font-size-15;
    line-height: 20px;
    margin: 0 @gutter-30;
    white-space: pre-line;
  }
  .button {
    color: @color-white;
    background-color: @color-black;
  }
}
</style>

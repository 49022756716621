<template>
  <popup ref="deployNotificationRef" title="Important">
    <div class="deploy-notification">
      <p>The version of the app has been updated.</p>
      <p>Please refresh your browser to get the latest changes.</p>
      <v-button class="full" @click="refreshBrowser()">
        click here to refresh
      </v-button>
    </div>
  </popup>
</template>

<script>
import axios from 'axios';
import { isProduction } from '../../config/app-env';

export default {
  props: {
    fileName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      intervalId: null,
      currentHash: null,
    };
  },
  mounted() {
    // do nothing if not running a deployed version
    if (!isProduction) return;
    this.fetchNew();
    this.intervalId = window.setInterval(() => this.fetchNew(), 2 * 60 * 1000);
  },
  beforeUnmount() {
    window.clearInterval(this.invervalId);
  },
  methods: {
    fetchNew() {
      const { intervalId, fileName } = this;
      const browserTabFocused = window.document.visibilityState === 'visible';
      const url = `${
        window.location.origin
      }/manifest.json?timestamp=${Date.now()}`;

      return (
        browserTabFocused &&
        axios(url, {
          headers: { 'Cache-Control': 'no-cache' },
        })
          .then((res) => {
            if (res.status !== 200) throw new Error('server offline');
            if (!this.currentHash) {
              return (this.currentHash = res.data[fileName]);
            }
            if (this.currentHash !== res.data[fileName]) {
              return this.$refs.deployNotificationRef.open();
            }

            return false;
          })
          .catch((err) => {
            window.clearInterval(intervalId);
          })
      );
    },
    refreshBrowser() {
      window.location.reload(true);
    },
  },
};
</script>

<style lang="less">
.deploy-notification {
  padding: 15px;
  text-align: center;
}
</style>

import { ENVIRONMENTS } from '@/data/environments';

export const ENV = {
  // Environment Config
  AUTHORIZE_NET_ENV: process.env.AUTHORIZE_NET_ENV,
  CLEARBANC_ENV: process.env.CLEARBANC_ENV,
  NODE_ENV: process.env.NODE_ENV,
  PLAID_ENV: process.env.PLAID_ENV,
  SENTRY_ENVIRONMENT: process.env.SENTRY_ENVIRONMENT,

  // Service URLs
  BAUS_API_URL: process.env.BAUS_API_URL,
  BENEFICIARY_API_URL: process.env.BENEFICIARY_API_URL,
  BILLS_API_URL: process.env.BILLS_API_URL,
  BRAINTREE_AUTHORIZEURI: process.env.BRAINTREE_AUTHORIZEURI,
  CLEARCOM_URL: process.env.CLEARCOM_URL,
  CONNECTED_ACCOUNTS_API_URL: process.env.CONNECTED_ACCOUNTS_API_URL,
  DILIGENCE_SERVICE_URL: process.env.DILIGENCE_SERVICE_URL,
  FARO_COLLECTOR_URL: process.env.FARO_COLLECTOR_URL,
  HELP_CENTER_URL: process.env.HELP_CENTER_URL,
  HERON_API_URL: process.env.HERON_API_URL,
  NGROK_DOMAIN: process.env.NGROK_DOMAIN,
  NODE_API_URL: process.env.NODE_API_URL,
  TYPEFORM_BANK_INPUT_URL: process.env.TYPEFORM_BANK_INPUT_URL,
  TYPEFORM_CREDIT_INPUT_URL: process.env.TYPEFORM_CREDIT_INPUT_URL,
  VC_SERVICE_URL: process.env.VC_SERVICE_URL,

  // Integration IDs
  AMAZON_CLIENT_ID: process.env.AMAZON_CLIENT_ID,
  AMAZON_SPAPI_APP_ID: process.env.AMAZON_SPAPI_APP_ID,
  AMAZON_SPAPI_CLIENT_ID: process.env.AMAZON_SPAPI_CLIENT_ID,
  AUTH0_CLIENT_ID: process.env.AUTH0_CLIENT_ID,
  AUTHORIZE_NET_CLIENT_ID: process.env.AUTHORIZE_NET_CLIENT_ID,
  BIGCOMMERCE_CLIENT_ID: process.env.BIGCOMMERCE_CLIENT_ID,
  BRAINTREE_CLIENT_ID: process.env.BRAINTREE_CLIENT_ID,
  FACEBOOK_APP_ID: process.env.FACEBOOK_APP_ID,
  FARO_APP_ID: process.env.FARO_APP_ID,
  GOOGLE_CLIENT_ID_FOR_FEDERATED_LOGIN:
    process.env.GOOGLE_CLIENT_ID_FOR_FEDERATED_LOGIN,
  GOOGLE_OAUTH_CLIENT_ID: process.env.GOOGLE_OAUTH_CLIENT_ID,
  GOOGLE_PEOPLE_API_KEY: process.env.GOOGLE_PEOPLE_API_KEY,
  GOOGLE_PLACES_API_KEY: process.env.GOOGLE_PLACES_API_KEY,
  HOMEAWAY_CLIENT_ID: process.env.HOMEAWAY_CLIENT_ID,
  PAYPAL_CLIENT_ID: process.env.PAYPAL_CLIENT_ID,
  SEGMENT_WRITE_KEY: process.env.SEGMENT_WRITE_KEY,
  SHOPIFY_API_KEY: process.env.SHOPIFY_API_KEY,
  SQUARE_APP_ID: process.env.SQUARE_APP_ID,
  STRIPE_CLIENT_ID: process.env.STRIPE_CLIENT_ID,

  // Feature Flags
  ENABLE_ROUTES_V2: process.env.ENABLE_ROUTES_V2 === 'true',
  PROXY_TO_STAGING: process.env.PROXY_TO_STAGING === 'true',
  TEST_SEGMENT: process.env.TEST_SEGMENT === 'true',

  // General Config
  API_TIMEOUT: process.env.API_TIMEOUT,
  AUTH0_AUDIENCE: process.env.AUTH0_AUDIENCE,
  AUTH0_DOMAIN: process.env.AUTH0_DOMAIN,
  BETA_PROFILE_REDIRECT: process.env.BETA_PROFILE_REDIRECT,
  FARO_APP_NAME: process.env.FARO_APP_NAME,
  GIT_COMMIT_SHA: process.env.GIT_COMMIT_SHA,
  LEXIS_NEXIS_ORG_ID: process.env.LEXIS_NEXIS_ORG_ID,
  LEXIS_NEXIS_PROFILING_DOMAIN: process.env.LEXIS_NEXIS_PROFILING_DOMAIN,
  SENTRY_DSN: process.env.SENTRY_DSN,
  SHOPIFY_SCOPE: process.env.SHOPIFY_SCOPE,
};

export const isProduction = ENV.NODE_ENV === ENVIRONMENTS.PRODUCTION;
export const isStaging = ENV.NODE_ENV === ENVIRONMENTS.STAGING;
export const isDevelopment = ENV.NODE_ENV === ENVIRONMENTS.DEVELOPMENT;

export const isBackendProduction =
  ENV.CLEARBANC_ENV === ENVIRONMENTS.PRODUCTION;
export const isBackendStaging = ENV.CLEARBANC_ENV === ENVIRONMENTS.STAGING;
export const isBackendDevelopment =
  ENV.CLEARBANC_ENV === ENVIRONMENTS.DEVELOPMENT;

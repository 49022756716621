<template>
  <SpendTransactionsTable :transactions="filteredTransactions" />
</template>

<script>
import { mapGetters } from 'vuex';

import { mapRequestStatuses } from '@/utils/vuex-api-utils';
import trackFiltersMixin from '@/mixins/track-filters-in-query-params';
import SpendTransactionsTable from '@/components/SpendTransactionsTable';
import { ENV } from '../../config/app-env';

export default {
  components: {
    SpendTransactionsTable,
  },
  mixins: [trackFiltersMixin],
  trackFilters: {
    // passed to a mixin which sets `filters` in data
    keys: ['status', 'advanceId', 'type', 'page', 'limit', 'createdAt'],
  },
  data() {
    return {
      reportsPreferredEmail: '',
      areFiltersVisible: true,
    };
  },
  computed: {
    ...mapGetters(['advances', 'transactions', 'business', 'user']),
    ...mapRequestStatuses({
      fetchTransactionsRequest: 'FETCH_TRANSACTIONS',
    }),
    filteredTransactions() {
      const txns = this.transactions.reduce((acc, txn) => {
        // when type is refund, the "incoming" info of the transaction is not useful
        // as it is just a signal if it affects the user's balance or not
        if (txn.type !== 'refund') {
          acc.push(txn);
        }

        // now split out the nested refund info into another row of info
        return !txn.refund
          ? acc
          : acc.concat({
              ...txn.refund,
              repaysAdvanceId: txn.repaysAdvanceId,
              // show user negative amount to signify refund
              amountCents: -1 * txn.refund.amountCents,
              description: `[REFUND] ${txn.refund.description || ''}`,
            });
      }, []);

      const filteredAdvance = this.advances
        .filter((adv) =>
          this.filters.advanceId
            ? adv.id === this.filters.advanceId
            : adv.activatedAt || adv.settledAt,
        )
        .map((adv) => adv.id);

      // If we're not on page 1 we don't want to add the first cashback line item
      // because it wouldn't represent the first transaction of that advance properly
      let skipLineItem = this.filters.page > 1;

      // Insert cashback line item in to the txns
      filteredAdvance.forEach((advId) => {
        const cashbackIndex = txns.findIndex((txn) => {
          return txn.repaysAdvanceId === parseInt(advId);
        });
        const relevantAdvance = this.advances.find(
          (advance) => advance.id === parseInt(advId),
        );
        if (cashbackIndex >= 0 && !skipLineItem) {
          txns.splice(cashbackIndex, 0, {
            repaysAdvanceId: advId,
            amountCents: relevantAdvance?.cashbackTotalCents,
            description: this.$t('spendPage.transactions.description', {
              advanceId: advId,
              settled: this.relevantAdvance?.settledAt ? '' : ' to date',
            }),
          });
        }
        skipLineItem = false;
      });
      return txns;
    },
    clearbancURL() {
      return ENV.CLEARCOM_URL;
    },
  },
  watch: {
    filters: {
      deep: true,
      handler() {
        this.refreshData();
      },
    },
  },
  beforeMount() {
    if (!this.filters.page) {
      this.filters.page = 1;
      this.filters.limit = 10;
    }
  },
  mounted() {
    this.refreshData();
  },
  methods: {
    refreshData() {
      if (!this.fetchTransactionsRequest.isPending) {
        this.$store.dispatchApiAction('FETCH_TRANSACTIONS', {
          ...this.filters,
          ...(!this.filters.type && {
            type: ['spend', 'vendor_invoice'],
          }),
          offset: (this.filters.page - 1) * this.filters.limit || 0,
          businessId: this.business.id,
        });
      }
    },
  },
};
</script>

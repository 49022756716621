import { createRouter, createWebHistory } from 'vue-router';
import { auth0 } from '@/utils/auth0';
import { AUTH } from '@/data/routes';
import i18n from '@/plugins/i18n';
import {
  initializePageStorage,
  fetchGlobalFeatureFlags,
  isAuthenticatedPartner,
  fetchUserFeatureFlags,
  syncDevEnvironmentUser,
  processUTMParams,
  removeUTMParams,
  trackPageNavigation,
  fetchUser,
  checkForIncompleteOnboardingStep,
  checkForRedirect,
} from '@/utils/router-helper';
import userInvitesRoutes from '@/routes/user-invites.routes';
import authRoutes from '@/routes/auth.routes';
import oauthRoutes from '@/routes/oauth.routes';
import accountRoutes from '@/routes/account.routes';
import maintenanceRoutes from '@/routes/maintenance.routes';
import onboardingRoutes from '@/routes/onboarding.routes';
import profileRoutes from '@/routes/profile.routes';
import connectedAccountsRoutes from '@/routes/connected-accounts.routes';
import documentsRoutes from '@/routes/documents.routes';
import emailVerificationRoutes from '@/routes/email-verification.routes';
import embedRoutes from '@/routes/embed.routes';
import homeRoutes from '@/routes/home.routes';
import paymentsRoutes from '@/routes/payments.routes';
import mcaRoutes from '@/routes/mca.routes';
import notFoundRoutes from '@/routes/not-found.routes';
import howItWorksRoutes from '@/routes/how-it-works.routes';

const router = createRouter({
  history: createWebHistory(),
  routes: [
    ...userInvitesRoutes,
    ...authRoutes,
    ...oauthRoutes,
    ...accountRoutes,
    ...maintenanceRoutes,
    ...onboardingRoutes,
    ...emailVerificationRoutes,
    ...embedRoutes,
    ...profileRoutes,
    ...documentsRoutes,
    ...connectedAccountsRoutes,
    ...mcaRoutes,
    ...paymentsRoutes,
    ...notFoundRoutes,
    ...howItWorksRoutes,
    ...homeRoutes,
  ],
});

router.beforeEach(async (to, from, next) => {
  initializePageStorage(to, from);
  await fetchGlobalFeatureFlags();

  const pageRedirect = await checkForRedirect(to);
  if (pageRedirect) {
    return next({ name: pageRedirect, query: to.query });
  }

  const isAuthRoute = Object.values(AUTH).includes(to.name);
  if (isAuthRoute || !auth0.isAuthenticated.value) {
    return next();
  }

  // This call is purely for local developement.
  // It's used to sync your local db with the auth-service dev db and Auth0 dev tenant
  await syncDevEnvironmentUser(to);

  const utmParams = await processUTMParams(to, from);
  if (utmParams) {
    router.replace({ query: {} });
  }

  // If !isAuthRoute && !redirect && ClearcoId exists in Auth0 user
  if (auth0.user?.value?.userId) {
    const user = await fetchUser(to);
    if (Object.keys(user ?? {}).length === 0) {
      return next({
        name: AUTH.ERROR,
        query: {
          error_description: i18n.t('applicationPage.userCanceled'),
        },
      });
    }

    const incompleteOnboardingRedirect = checkForIncompleteOnboardingStep(to);
    if (incompleteOnboardingRedirect) {
      return next({ name: incompleteOnboardingRedirect });
    }

    await fetchUserFeatureFlags();

    // Remove stored UTM params from URL
    const modifiedTo = removeUTMParams(to);
    if (modifiedTo) {
      return next(modifiedTo);
    }
  }

  return next();
});

router.beforeResolve(async (to, from, next) => {
  if (isAuthenticatedPartner()) {
    await auth0.logout();
  }

  return next();
});

router.afterEach((to, from) => {
  trackPageNavigation(to, from);
});

export default router;

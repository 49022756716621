<template>
  <div :class="$style.wrapper">
    <OnboardingUiMsg v-if="showErrorMessage" type="error">
      {{ CONTACT_SUPPORT_MSG }}
    </OnboardingUiMsg>

    <VeeForm
      v-slot="{ values, setFieldValue }"
      class="c-form"
      :class="$style.form"
      :validation-schema="schema"
      :initial-values="initialValues"
      data-cy="form"
      @submit="onSubmit"
    >
      <div class="c-form__row">
        <DropdownGroup
          name="corpCountry"
          label="Incorporation Country"
          :options="countryOptions"
          :reset-filter-on-hide="true"
          :filter="true"
          option-label="nameString"
          @change="
            (e) => {
              updateBusinessParams(e.value.value, 'corpCountry');
              setFieldValue('corpState', null);
            }
          "
        />
      </div>
      <div
        v-if="jurisdictionsOptionsForCountry(values.corpCountry?.value)"
        class="c-form__row"
      >
        <DropdownGroup
          name="corpState"
          :label="`Incorporation ${jurisdictionLabel(
            values.corpCountry.value,
          )}`"
          :options="stateOptions(values.corpCountry.value)"
          :reset-filter-on-hide="true"
          :filter="true"
          option-label="nameString"
          @change="(e) => updateBusinessParams(e.value.value, 'corpState')"
        />
      </div>
      <div class="c-form__row">
        <InputTextGroup
          name="name"
          label="Registered Business Name"
          @blur="(e) => updateBusinessParams(e.target.value, 'name')"
        />
      </div>
      <div class="c-form__row">
        <DropdownGroup
          name="monthlyRevenue"
          label="Average Monthly Revenue"
          :options="revenueOptions"
          option-label="nameString"
          @change="(e) => updateMonthlyRevenue(e.value.value)"
        />
      </div>
      <div class="c-form__row">
        <InputTextGroup
          name="website"
          label="Website"
          @blur="(e) => updateBusinessParams(e.target.value, 'website')"
        />
      </div>
      <div class="c-form__controls">
        <DSButton
          label="Back"
          class="mr-auto p-button-link"
          :class="[$style['button-back']]"
          @click="$router.push({ name: this.previousRoute })"
        />
        <DSButton
          label="Next"
          class="p-button-secondary"
          :class="$style.button"
          type="submit"
          :disabled="isSubmitting"
        />
      </div>
    </VeeForm>
  </div>
</template>

<script>
import { CONTACT_SUPPORT_MSG } from '@/data/error-messages';
import OnboardingUiMsg from '@/components/UiMsg';
import { mapGetters } from 'vuex';
import { mapRequestStatuses } from '@/utils/vuex-api-utils';
import { countryOptionsWithPriority } from '@/data/country-code-with-names';
import analytics from '@/utils/analytics';
import {
  jurisdictionLabel,
  jurisdictionsOptionsForCountry,
} from '@/utils/local';
import {
  CLEARPAY_SEGMENT_EVENTS,
  getPaymentsRevenueBands,
  PAYMENTS_ROUTE_NAMES,
} from '@/data/payments';
import { useProfiling } from '@/composables/useProfiling';
import { EVENT_TYPES } from '@/data/lexisnexis-types';

import { Form as VeeForm } from 'vee-validate';
import { yup } from '@clearbanc/validation';
import DSButton from '@clearbanc/clear-components/button';
import InputTextGroup from '@clearbanc/clear-components/inputtextgroup';
import DropdownGroup from '@clearbanc/clear-components/dropdowngroup';

export default {
  components: {
    OnboardingUiMsg,
    VeeForm,
    DSButton,
    InputTextGroup,
    DropdownGroup,
  },
  data() {
    return {
      showErrorMessage: false,
      isSubmitting: false,
      initialValues: {},
      schema: yup.object({
        corpCountry: yup
          .country()
          .required(this.$t('common.pleaseSelectADropDown')),
        corpState: yup
          .state()
          .requiredWhen(
            'corpCountry',
            (corpCountry) =>
              corpCountry && jurisdictionsOptionsForCountry(corpCountry),
            this.$t('common.pleaseSelectADropDown'),
          ),
        name: yup.businessName(),
        monthlyRevenue: yup
          .object()
          .nullable()
          .required(this.$t('common.pleaseSelectADropDown')),
        website: yup
          .website('Invalid url')
          .required(this.$t('common.invalidWebsite')),
      }),
    };
  },
  setup() {
    const profiling = useProfiling(PAYMENTS_ROUTE_NAMES.BUSINESS_DETAILS, {
      type: EVENT_TYPES.ACCOUNT_CREATION,
    });

    return {
      profiling,
    };
  },
  computed: {
    CONTACT_SUPPORT_MSG() {
      return CONTACT_SUPPORT_MSG(this.contactDetails.number);
    },
    ...mapGetters(['business', 'contactDetails', 'paymentsOnboardingSteps']),
    ...mapRequestStatuses({
      updateBusinessRequest: 'UPDATE_BUSINESS',
    }),
    currentRoute() {
      return this.$route.name;
    },
    previousRoute() {
      return this.paymentsOnboardingSteps[this.currentRoute].previousRoute.name;
    },
    revenueOptions() {
      const optionsWithStringName = getPaymentsRevenueBands().map((value) => {
        return {
          ...value,
          nameString: value.name(),
        };
      });

      return optionsWithStringName;
    },
    countryOptions() {
      // https://github.com/clearbanc/code/pull/17133
      // Our dropdowns are unable to invoke the name methods, we require a string
      const optionsWithStringName = this.countryOptionsWithPriority().map(
        (value) => {
          return {
            ...value,
            nameString: value.name(),
          };
        },
      );

      return optionsWithStringName;
    },
  },
  beforeMount() {
    this.updateInitialValues();
  },
  methods: {
    countryOptionsWithPriority,
    jurisdictionLabel,
    jurisdictionsOptionsForCountry,
    updateBusinessParams(val, name) {
      this.$store.dispatch('UPDATE_BUSINESS_PARAMS', { [name]: val });
    },
    updateMonthlyRevenue(revenue) {
      this.$store.dispatch('UPDATE_BUSINESS_PARAMS', {
        qualifyingQuestions: {
          ...this.business?.qualifyingQuestions,
          monthly_revenue: revenue,
        },
      });
    },
    async onSubmit(values) {
      this.isSubmitting = true;

      await this.$store.dispatchApiAction('UPDATE_BUSINESS', {
        corpCountry: values.corpCountry.value,
        corpState: values.corpState ? values.corpState.value : null,
        name: values.name,
        qualifyingQuestions: {
          ...this.business.qualifyingQuestions,
          monthly_revenue: values.monthlyRevenue.value,
        },
        website: values.website,
      });

      if (this.updateBusinessRequest.isError) {
        this.isSubmitting = false;
        this.showErrorMessage = true;
        const errorMessage = this.updateBusinessRequest.errorMessage;
        analytics.track('clear_pay_error', {
          errorMessage,
        });
      } else {
        analytics.track(CLEARPAY_SEGMENT_EVENTS.SUBMITTED_BUSINESS_PROFILE);
        if (values.monthlyRevenue.value !== '0-10k') {
          analytics.track(CLEARPAY_SEGMENT_EVENTS.SELECTED_REVENUE_ABOVE_10K);
        } else {
          analytics.track(CLEARPAY_SEGMENT_EVENTS.SELECTED_REVENUE_BELOW_10K);
        }

        this.$store.dispatch(
          'UPDATE_PAYMENTS_ONBOARDING_STEP',
          this.currentRoute,
        );

        this.profiling.finish();

        this.$router.push({
          name: this.paymentsOnboardingSteps[this.currentRoute].nextRoute.name,
        });
      }
    },
    updateInitialValues() {
      if (this.business?.corpCountry) {
        this.initialValues.corpCountry = this.countryOptions.find(
          (country) => country.value === this.business.corpCountry,
        );

        const states = this.stateOptions(this.business?.corpCountry);
        let presetState;
        if (states) {
          presetState = states.find(
            (state) => state.value === this.business?.corpState,
          );
        }

        if (presetState) {
          this.initialValues.corpState = presetState;
        }
      }

      if (this.business?.name) {
        this.initialValues.name = this.business.name;
      }

      if (this.business?.qualifyingQuestions?.monthly_revenue) {
        let presetRevenue;
        if (this.revenueOptions) {
          presetRevenue = this.revenueOptions.find(
            (option) =>
              option.value ===
              this.business.qualifyingQuestions.monthly_revenue,
          );
        }

        if (presetRevenue) {
          this.initialValues.monthlyRevenue = presetRevenue;
        }
      }

      if (this.business?.website) {
        this.initialValues.website = this.business.website;
      }
    },
    stateOptions(country) {
      const states = this.jurisdictionsOptionsForCountry(country);

      if (!states) return null;

      // https://github.com/clearbanc/code/pull/17133
      // Our dropdowns are unable to invoke the name methods, we require a string
      const optionsWithStringName = states.map((value) => {
        return {
          ...value,
          nameString: value.name(),
        };
      });

      return optionsWithStringName;
    },
  },
};
</script>

<style lang="less" module>
.wrapper {
  max-width: 320px;
  margin: 0 auto;
}

.form {
  width: 320px;
}

.button {
  min-width: 186px;
}

button.button-back {
  padding-left: 0;
}

@media (max-width: 500px) {
  .form {
    width: auto;
  }
}
</style>

// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".button_O0S7F{border:none;color:#fff;cursor:pointer;display:inline-block;font-family:Montserrat,sans-serif;font-size:18px;font-weight:500;min-width:175px;text-align:center}.button_O0S7F .button-text_ZHuIb{display:inline-block;line-height:.8;margin:auto 0;position:relative;top:1px}.button_O0S7F .button-text-2-lines_sKeTU{line-height:.75;margin:0 50px;position:relative}.button_O0S7F.disabled_oF3Ie{background-color:#aeaeae;cursor:default}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "button_O0S7F",
	"button-text": "button-text_ZHuIb",
	"button-text-2-lines": "button-text-2-lines_sKeTU",
	"disabled": "disabled_oF3Ie"
};
export default ___CSS_LOADER_EXPORT___;

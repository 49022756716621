import { computed, ref } from 'vue';
import analytics from '@/utils/analytics';
import { INTEGRATION_TYPES } from '@/data/onboarding';
import { SALES_PLATFORMS, MARKETING_PLATFORMS } from '@/data/platforms';
import { CONNECTION_STATUS } from '@/data/connection-types';
import {
  getExtAccountStatus,
  getShopifyAccStatus,
} from '@/utils/external-accounts';

const ALL_PLATFORMS = { ...SALES_PLATFORMS, ...MARKETING_PLATFORMS };

export default function (context) {
  const ctx = context.root;
  const { getters } = ctx.$store;

  const initialErrors = Object.values(ALL_PLATFORMS).reduce((acc, value) => {
    acc[value] = false;
    return acc;
  }, {});

  const userId = computed(() => getters.userId);
  const businessId = computed(() => getters.businessId);
  const externalAccounts = computed(() => getters.externalAccounts);
  const showShopifyFivetranTile = computed(
    () => getters.showShopifyFivetranTile,
  );
  const showNextStepsError = ref(false);
  const errors = ref(initialErrors);

  const getIntegrationType = (platformName) => {
    if (Object.values(SALES_PLATFORMS).includes(platformName)) {
      return INTEGRATION_TYPES.SALES;
    } else if (Object.values(MARKETING_PLATFORMS).includes(platformName)) {
      return INTEGRATION_TYPES.MARKETING;
    }
    return INTEGRATION_TYPES.ACCOUNTING;
  };

  const trackLinkClick = (linkName, props) => {
    analytics.track(`fe_${linkName}_clicked`, props);
  };

  const resetError = () => {
    ctx.$store.commit('CLEAR_REQUEST_STATUS', 'ADD_CONNECTED_ACCOUNT');
    ctx.$store.commit('CLEAR_REQUEST_STATUS', 'OAUTH_CONNECT');
    showNextStepsError.value = false;
    errors.value = initialErrors;
  };

  const trackSeeMoreClick = (props) => {
    analytics.track('fe_see_more_click', props);
  };

  const trackAccountConnected = (platform, props) => {
    analytics.track(`fe_${getIntegrationType(platform)}_account_connected`, {
      category: 'Connect',
      platform,
      userId: userId.value,
      businessId: businessId.value,
      integrationType: getIntegrationType(platform),
      ...props,
    });

    analytics.track('if_marketing_account_connected_success', {
      platform,
      businessId: businessId.value,
    });
  };

  const trackRedirect = (platformName, props) => {
    resetError();
    analytics.track(
      `fe_${getIntegrationType(platformName)}_external_conn_attempted`,
      {
        platform: platformName,
        ...props,
      },
    );
  };

  const updateTileToErrorStatus = (platformName) => {
    showNextStepsError.value = true;
    errors.value[platformName] = true;
  };
  const handleTileClick = (platformName, props) => {
    const integrationType = getIntegrationType(platformName);
    resetError();
    analytics.track('fe_integration_click', {
      platform: platformName,
      integrationType,
      ...props,
    });
    if (integrationType === INTEGRATION_TYPES.ACCOUNTING) {
      trackRedirect(platformName, props);
    }
  };
  const handleOauthError = (payload, props) => {
    updateTileToErrorStatus(payload.provider);
    analytics.track(
      `fe_${getIntegrationType(payload.provider)}_account_failed_connect`,
      {
        ...props,
        platform: payload.provider,
        errorMsg: payload.error,
      },
    );
  };

  const trackErrorBanner = (action, props, errorBannerMessage) => {
    if (action === 'visible') {
      analytics.track('fe_error_banner_displayed', {
        bannerCopy: errorBannerMessage,
        ...props,
      });
    } else if (action === 'email_click') {
      trackLinkClick('error_banner_email', ...props);
    } else if (action === 'phone_click') {
      trackLinkClick('error_banner_phone', ...props);
    }
  };

  const trackNewPlatformModal = (
    props,
    action,
    integrationType,
    requestedPlatform,
  ) => {
    if (['submitted', 'entered'].includes(action)) {
      const eventName =
        action === 'submitted'
          ? 'fe_selfserve_platform_requested'
          : 'fe_platform_requested_name_entered';
      analytics.track(eventName, {
        ...props,
        requestedPlatform,
        integrationType,
      });
    } else {
      analytics.track('fe_no_platform_click', {
        ...props,
        integrationType,
      });
    }
  };

  const trackModal = (platformName, modalAction, props, isMobile) => {
    let modalType = 'popup'; // always popups on desktop
    // always redirect on mobile
    if (isMobile) {
      modalType = 'redirect';
    }
    // have to click tile to open modal so track that event too
    if (modalAction === 'open') {
      handleTileClick(platformName, props);
    }
    analytics.track(
      `fe_${getIntegrationType(platformName)}_account_modal_${modalAction}`,
      {
        platform: platformName,
        ...props,
        modalType,
      },
    );
  };

  const getExtAccStatus = (acc) => {
    const account = externalAccounts.value?.find(
      (ext) => ext.id.includes(acc) && !ext.ignoreRevenueAt,
    );
    return account && account.state;
  };

  const getActiveExtAccountsByPlatform = (acc) => {
    return (
      externalAccounts.value?.filter(
        (ext) => ext.id.includes(acc) && !ext.ignoreRevenueAt,
      ) ?? []
    );
  };

  const isFullyConnected = (acc) => {
    const extAccounts = getActiveExtAccountsByPlatform(acc);
    return (
      !!extAccounts.length &&
      extAccounts.every(
        (ext) =>
          getExtAccountStatus(ext, showShopifyFivetranTile.value) ===
          CONNECTION_STATUS.CONNECTED,
      )
    );
  };

  const hasSomeByStatus = (acc, status) => {
    const extAccounts = getActiveExtAccountsByPlatform(acc);
    return extAccounts.some(
      (ext) =>
        getExtAccountStatus(ext, showShopifyFivetranTile.value) === status,
    );
  };

  const hasPendingConnection = (acc) => {
    return hasSomeByStatus(acc, CONNECTION_STATUS.DISCONNECTED);
  };

  const hasProcessingConnection = (acc) => {
    return hasSomeByStatus(acc, CONNECTION_STATUS.PROCESSING);
  };

  const hasWarningConnection = (acc) => {
    return hasSomeByStatus(acc, CONNECTION_STATUS.WARNING);
  };

  return {
    showNextStepsError,
    errors,
    getIntegrationType,
    trackLinkClick,
    trackSeeMoreClick,
    trackAccountConnected,
    trackRedirect,
    updateTileToErrorStatus,
    handleTileClick,
    handleOauthError,
    trackErrorBanner,
    trackNewPlatformModal,
    trackModal,
    getExtAccStatus,
    hasPendingConnection,
    isFullyConnected,
    hasProcessingConnection,
    hasWarningConnection,
    getShopifyAccStatus,
  };
}

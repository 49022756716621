import { unref } from 'vue';
import { createAuth0 } from '@auth0/auth0-vue';
import { ENV } from '../../config/app-env';

const search = window.location.search.substring(1);

const params = new URLSearchParams(search);

const screen = params.get('initialScreen');

export const auth0 = createAuth0({
  domain: `${ENV.AUTH0_DOMAIN}`,
  client_id: `${ENV.AUTH0_CLIENT_ID}`,
  redirect_uri: `${window.location.origin}/login${window.location.search}`,
  audience: `${ENV.AUTH0_AUDIENCE}`,
  ...(screen ? { initialScreen: screen } : {}),
});

export function isTokenExpiredError(auth0ErrorRef) {
  const auth0Error = unref(auth0ErrorRef);

  return auth0Error?.message?.includes('Expiration Time (exp) claim error');
}

/* eslint-disable import/no-cycle */
import { createI18n } from 'vue-i18n';
import { USER_SETTINGS } from '@/configs/i18n-constants';
import { ENVIRONMENTS } from '@/data/environments';
import messages from '@/lang/';
import { ENV } from '../../config/app-env';

const i18n = createI18n({
  locale: USER_SETTINGS.LANG ?? USER_SETTINGS.FALLBACK_LANG,
  fallbackLocale: USER_SETTINGS.FALLBACK_LANG || 'en-us',
  messages,
  silentTranslationWarn: [ENVIRONMENTS.PRODUCTION, ENVIRONMENTS.TEST].includes(
    ENV.CLEARBANC_ENV,
  ),
});

// Aliasing for compatibility
i18n.t = function (...args) {
  return i18n.global.t(...args);
};

export default i18n;

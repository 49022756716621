<template>
  <div>
    <FormError v-if="showError" :error-message="errorMessage" />
    <FormBusinessAddress
      @validationSuccess="handleValidationSuccess"
      @validationFailure="handleError"
      @goBack="goBack"
    />
  </div>
</template>

<script>
import { mapGetters, useStore } from 'vuex';
import { mapStateModuleGetters } from '@/utils/state-modules';
import { PAYMENTS_ROUTE_NAMES } from '@/data/payments';
import stateModuleAware from '@/mixins/state-module-aware';
import FormBusinessAddress from '@/components/forms/FormBusinessAddress';
import FormError from '@/components/forms/shared/FormError';
import { useProfiling } from '@/composables/useProfiling';
import { LocalStorage, LocalStorageKeys } from '@/utils/local-storage';

export default {
  components: {
    FormBusinessAddress,
    FormError,
  },
  mixins: [stateModuleAware],
  data() {
    return {
      errorMessage: null,
      showError: false,
    };
  },
  setup() {
    const store = useStore();
    const profiling = useProfiling(
      PAYMENTS_ROUTE_NAMES.PROFILE_BUSINESS_REGISTERED_ADDRESS,
      {
        type: store.getters.eventTypeBasedOnLoginsCount,
      },
    );

    return {
      profiling,
    };
  },
  computed: {
    ...mapGetters(['business', 'user']),
    ...mapStateModuleGetters(['steps', 'previousStepName']),
    currentRoute() {
      return this.$route.name;
    },
  },
  methods: {
    async handleValidationSuccess() {
      this.dispatchToStateModule('UPDATE_STEP', {
        step: this.currentRoute,
      });

      this.profiling.finish();

      this.$router.push({
        name: this.steps[this.currentRoute].nextRoute.name,
        params: { id: this.user.id },
      });
    },
    goBack() {
      // using local storage here because if the user goes to the previous step and clicks the Next button
      // the backend will update the business and the state will be overwritten in the store
      LocalStorage.setItem(LocalStorageKeys.BUSINESS, this.business);
      this.$router.push({ name: this.previousStepName(this.currentRoute) });
    },
    handleError(error) {
      this.errorMessage = error;
      this.showError = true;
    },
  },
  mounted() {
    this.$store.dispatch('MODIFY_PAYMENT_STEPS');
  },
};
</script>

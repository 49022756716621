// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".description_ZvEDP[data-v-8b7e104e]{width:100%;max-width:665px;text-align:center;margin:0 0 40px 0}.signatories_bRlc8[data-v-8b7e104e]{display:flex;flex-direction:column;max-width:400px;margin:0 auto 20px}.signatories__item_Xkh8j[data-v-8b7e104e]{border:1px solid var(--neutral-grey-100,#e8e8ea);padding:16px;width:100%;text-align:left;display:flex;align-items:center;border-radius:2px}.signatories__item_Xkh8j+.signatories__item_Xkh8j[data-v-8b7e104e]{margin-top:24px}.signatories__item_Xkh8j[data-v-8b7e104e]:before{content:\"\";width:20px;height:20px;border:1px solid var(--neutral-black,#000);display:inline-block;border-radius:50%;margin-right:16px}.signatories__item--active_RmIIV[data-v-8b7e104e]{border-color:var(--primary-brand-primary-400,#7f30ff);cursor:default}.signatories__item--active_RmIIV[data-v-8b7e104e]:before{border:4px solid var(--primary-brand-primary-400,#7f30ff)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"description": "description_ZvEDP",
	"signatories": "signatories_bRlc8",
	"signatories__item": "signatories__item_Xkh8j",
	"signatories__item--active": "signatories__item--active_RmIIV"
};
export default ___CSS_LOADER_EXPORT___;

import store from '@/store';
import analytics from '@/utils/analytics';
import { auth0, isTokenExpiredError } from '@/utils/auth0';
import Cookies from 'js-cookie';
import { fetchUserData } from '@/store/actions';
import { bausRequest } from '@/utils/baus-request';

import {
  OAUTH,
  PAYMENTS,
  HOME,
  ONBOARDING,
  MAINTENANCE,
  AUTH,
  EMAIL_VERIFICATION,
} from '@/data/routes';
import { PAYMENTS_TRACKING_PARAMS } from '@/data/payments';
import { LocalStorage, LocalStorageKeys } from '@/utils/local-storage';
import { isBackendDevelopment, ENV } from '../../config/app-env';

function getAttributionParams(path) {
  const params = new URLSearchParams(path.split('?')[1]);
  const filteredParams = {};

  for (const key of params.keys()) {
    if (PAYMENTS_TRACKING_PARAMS.includes(key)) {
      filteredParams[key] = params.get(key);
    }
  }

  return filteredParams;
}

export function initializePageStorage(to, from) {
  // store tracking params
  const { query } = to;
  if (
    Object.keys(query).some((param) => PAYMENTS_TRACKING_PARAMS.includes(param))
  ) {
    LocalStorage.setItem(LocalStorageKeys.SIGNUP_PARAMS, query);

    if (query.amplitude_id) {
      LocalStorage.setItem(LocalStorageKeys.AMPLITUDE_ID, query.amplitude_id);
    }
  }

  const isShowLegacyModal =
    (to.meta.isLegacy && !from.meta.isLegacy) ||
    (to.path === from.path && store.getters.showLegacyModal);

  store.dispatch('setShowLegacyModal', isShowLegacyModal);
}

export function isAuthenticatedPartner() {
  return auth0.isAuthenticated.value && auth0.user.value.userType === 'partner';
}

export async function fetchGlobalFeatureFlags() {
  try {
    if (!store.getters.globalFeatureFlags) {
      await store.dispatchApiAction('FETCH_GLOBAL_FEATURE_FLAGS');
      if (!store.getters.globalFeatureFlags) {
        await store.commit('DEFAULT_GLOBAL_FEATURE_FLAGS');
      }
    }
    // eslint-disable-next-line no-empty
  } catch (error) {}
}

export async function fetchUserFeatureFlags() {
  const { featureFlagsToFetch, getAllEnabledExperiments, user } = store.getters;
  if (featureFlagsToFetch.length) {
    await store.dispatchApiAction('FETCH_USER_FEATURE_FLAGS');

    const { id, email } = user;
    // Should add global feature flags?
    analytics.identify(id, {
      enabledExperiments: getAllEnabledExperiments,
      email,
    });
  }
}

export async function syncDevEnvironmentUser(to) {
  if (
    isBackendDevelopment &&
    !!auth0.user?.value.userId &&
    !ENV.PROXY_TO_STAGING &&
    [HOME.INITIAL, OAUTH.SIGNUP].includes(to.name) // Users are redirected to `oauth-signup` when installing the Shopify Native App
  ) {
    await bausRequest.post('/users/local-sync');
  }
}

export async function processUTMParams(to, from) {
  const filteredToParams = getAttributionParams(to.fullPath);
  const filteredFromParams = getAttributionParams(from.fullPath);

  let paramsToSend = {};
  const toHasParams = Object.keys(filteredToParams).length > 0;
  const fromHasParams = Object.keys(filteredFromParams).length > 0;

  if (toHasParams) {
    paramsToSend = filteredToParams;
  } else if (fromHasParams) {
    paramsToSend = filteredFromParams;
  }

  const { userId, loginsCount } = auth0.user?.value ?? {};

  if (Object.keys(paramsToSend).length) {
    const authType = loginsCount === 1 ? 'signup' : 'login';

    if (userId) {
      // Stores UTM data in DB
      store.dispatchApiAction('STORE_USER_ATTRIBUTION', {
        urlParams: paramsToSend,
        userId,
        authType,
      });
    } else {
      // Sends UTM data to Auth0 via BAUS
      store.dispatchApiAction('SEND_UTM_DATA', {
        urlParams: paramsToSend,
        authType,
      });
    }
  }
  return undefined;
}

export function removeUTMParams(to) {
  const hasParamsToRemove = PAYMENTS_TRACKING_PARAMS.some(
    (param) => to.query[param],
  );
  if (hasParamsToRemove) {
    const modifiedTo = {
      ...to,
      query: { ...to.query },
    };

    PAYMENTS_TRACKING_PARAMS.forEach((param) => {
      delete modifiedTo.query[param];
    });
    return modifiedTo;
  }
  return undefined;
}

async function getRedirectName(to) {
  if (store.getters.isMaintenancePageEnabled) {
    return MAINTENANCE.INDEX;
  }

  if (to.query.error) {
    return AUTH.ERROR;
  }

  try {
    await auth0.getAccessTokenSilently();
  } catch (_error) {
    if (isTokenExpiredError(auth0.error)) {
      return AUTH.ERROR;
    }
  }

  const isUserPendingEmailVerification =
    auth0.isAuthenticated.value && !auth0.user?.value?.userId;
  if (to.name !== AUTH.LOGOUT && isUserPendingEmailVerification) {
    return EMAIL_VERIFICATION.INDEX;
  }
  return undefined;
}

export async function checkForRedirect(to) {
  const redirectName = await getRedirectName(to);
  if (redirectName && redirectName !== to.name) {
    return redirectName;
  }
  return undefined;
}

export function trackPageNavigation(to, from) {
  const pageProps = {};
  if (to.redirectedFrom) pageProps.redirected_from = to.redirectedFrom;

  LocalStorage.setItem(LocalStorageKeys.PREVIOUS_ROUTE, from.path);

  // we must do this on nextTick so we get the actual URL
  setTimeout(() => {
    pageProps.productSegment = store.getters.productSegmentLabel;
    pageProps.fullPath = to.fullPath;
    pageProps.path = to.path;

    const isPageRefreshed = from.path === to.path;
    if (!isPageRefreshed) {
      analytics.page(to.name, pageProps);
    }
  });
}

export async function fetchUser() {
  const { userId, email } = auth0.user?.value ?? {};
  if (userId?.toString() !== store.getters.userId) {
    const user = await fetchUserData(store, userId);
    Cookies.set('cb-user-email', email, { path: '/' });
    return user;
  }
  return store.getters.user;
}

export function checkForIncompleteOnboardingStep(to) {
  const isOnboardingPage = Object.values(ONBOARDING).includes(to.name);
  const { name } = store.getters.firstIncompletePaymentsOnboardingStep ?? {};
  return isOnboardingPage || name === PAYMENTS.BILL || name === to.name
    ? undefined
    : name;
}

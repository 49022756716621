// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".modalContext_jMXYT{color:#000;padding-bottom:30px;text-align:center;font-weight:700}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalContext": "modalContext_jMXYT"
};
export default ___CSS_LOADER_EXPORT___;
